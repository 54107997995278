
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {AuthenticatedTemplate} from "@azure/msal-react";
import {useLocation, useParams} from "react-router-dom";
import {cleanSpecialMedicationStore} from "pages/Wizard/wizardSlice";

import {
  Autocomplete,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
  CardHeader,
  IconButton,
  Avatar,
  Collapse,
  Button,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import {UseAppSelector, UseAppDispatch} from "store/hooks";
import {fetchAdditionalDrugInfo, fetchBagData} from "store/middleware/masterdataThunk";
import {selectDiagnosisValues} from "../Diagnosis/diagnosisSlice";
import {
  onChangeMedicineValue,
  selectMedicineValues,
  onClearMedicineState,
  onChangeMedicationValues,
  onChangeKVVLegalState,
} from "./medicineSlice";


import {
  onReceivedBagData,
  state_masterdata_autocompleteOptions_requiredDrug}
  from "store/features/masterdataSlice";
import {
  selectPatientDiary,
} from "store/features/newRequestSlice";
import {onChangeTreatmentValue, selectTreatmentValues} from "../Treatment/treatmentPlanSlice";
import {useIsInViewport} from "pages/Wizard/containers/utils";
import {selectLoadingState} from "store/features/loadingSlice";
import {
  onViewChange,
  isFormVisible,
  fetchFirstSearchResultFromBackend,
} from "pages/Wizard/wizardSlice";
import {ExpandLess, ExpandMore, MonitorWeight} from "@mui/icons-material";
import {SaxendaTable} from "./SaxendaTable/SaxendaTable";
import {checkIsBrukinsaCll} from "../AdditionalInformation/isSpecialMed";

export const MedForm = (props) => {
  if (props === undefined) {
    props = {
      standalone: true,
    };
  }

  const {t, i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const diagnosisValues=UseAppSelector(selectDiagnosisValues);
  const loading=UseAppSelector(selectLoadingState);
  const medicineValues=UseAppSelector(selectMedicineValues);
  const isVisibleValues = UseAppSelector(isFormVisible);
  const patientDiary=UseAppSelector(selectPatientDiary);
  const treatmentValues=UseAppSelector(selectTreatmentValues);
  const masterdata_autocompleteOptions_requiredDrug= UseAppSelector(state_masterdata_autocompleteOptions_requiredDrug) || [];
  const dispatch = UseAppDispatch();
  const handleChangeTreatmentValue=(field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const {medicineName} = useParams<{medicineName:string}>();
  const isVisible=useIsInViewport(props.refProps);
  const setSwitchValues=(option)=>{
    if (!checkIsBrukinsaCll(medicineValues.required_drug, diagnosisValues.diagnosis)) {
      if ( !option.limitatioInSLavailable) {
        handleChangeTreatmentValue("limitatio_approval", true);
      }
      if ( !option.limitatioInSLavailable) {
        handleChangeTreatmentValue("specialist_info", true);
      }
    }
  };
  const location = useLocation();
  const medicheck = location.pathname.indexOf("medicheck") > -1;


  useEffect(() => {
    console.debug("[MedicineForm] [Event on change of redux store: required drug or selected diagnosis] drug is:", medicineValues.required_drug, " diag: ", diagnosisValues.diagnosis);
    const timer = setTimeout(() => {
      if ( medicineValues.required_drug?.length > 2) {
        Promise.all([
          dispatch(fetchBagData( medicineValues.required_drug)),
        ]);
      } else {
        console.debug("[MedicineForm] [Event on change of redux store: required drug or selected diagnosis] drug is not defined or only 2 chars in length.  We dont do anything.");
      }
      if (diagnosisValues?.diagnosis?.length > 2) {
        Promise.all([
          dispatch(fetchAdditionalDrugInfo(
              medicineValues.required_drug,
              diagnosisValues?.diagnosis,
              currentLang)),
        ]);
      } else {
        console.debug("[MedicineForm] [Event on change of redux store: required drug or selected diagnosis] diagnosis is not defined or only 2 chars in length. We dont do anything.");
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [
    medicineValues.required_drug,
  ]);


  useEffect(() => {
    try {
      console.debug("[MedicineForm] [EVENT FOR Medicine Name as Parameter]", medicineName, " editRequestLoad: ", props.loading.editRequestLoad);
      if (props.loading.editRequestLoad === true) {
        console.debug("[MedicineForm] [EVENT FOR Medicine Name as Parameter] We dont do anything as we are loading a request for Edit");
        return;
      } else if (medicineName === undefined) {
        console.debug("[MedicineForm] [EVENT FOR Medicine Name as Parameter] We dont do anything as the parameter is empty");
        return;
      } else {
        console.debug("[MedicineForm] [EVENT FOR Medicine Name as Parameter], we got one and now loading the data for this request", medicineName);
        let decodedmedicineName = medicineName;
        // in case of iOS App link
        if (window.location.href.indexOf("/app/") > -1) {
          decodedmedicineName = atob(medicineName);
        } else {
          // dont decode
        }
        console.debug("[MedicineForm] [EVENT FOR Medicine Name as Parameter]", decodedmedicineName);
        Promise.all(
            [
              dispatch(fetchFirstSearchResultFromBackend({
                searchstring: decodedmedicineName,
                currentlang: currentLang,
              })),
            ],
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [medicineName]);


  useEffect(()=>{
    if (props.loading.editRequestLoad === true) {
      return;
    } else {
      isVisibleValues.medicine!==isVisible&&dispatch(onViewChange({field: "medicine", value: isVisible}));
    }
  }, [isVisible]);

  useEffect(()=>{
    if (props.loading.editRequestLoad === true) {
      return;
    } else {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: treatmentValues?.limitatio_approval,
            specialist_info: treatmentValues?.specialist_info,
          },
      ));
    }
  }, [
    medicineValues.substances,
    treatmentValues.limitatio_approval,
    medicineValues.required_drug,
    treatmentValues.specialist_info,
    medicineValues.substances,
  ]);


  const [expandedPatient, setExpandedPatient]=useState(false);
  const [userchangedthemedication, setUserchangedthemedication]=useState(false);

  const handleExpandPatient=() => {
    setExpandedPatient(!expandedPatient);
  };
  const handleDeleteForm =async () => {
    Promise.all(
        [
          dispatch(cleanSpecialMedicationStore({})),
        ],
    ).then(() => {
      [
        setUserchangedthemedication(false),
      ];
    },
    );
  };
  return (
    <Grid ref={props.refProps} container direction="column" spacing={3} id="medicine">
      {!props.standalone&&<Grid item>
        <Typography variant="h5" >
          {t(props.label)}
        </Typography>
      </Grid>}
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={12} sm={12}>
          {console.debug("[MedicineForm] [MedForm] masterdata_autocompleteOptions_requiredDrug", masterdata_autocompleteOptions_requiredDrug?.length)}
          <Autocomplete
            data-cy= "required_drug"
            autoComplete
            fullWidth
            readOnly = {loading?.bagData}
            options={
              Object.values(
                Array.isArray(masterdata_autocompleteOptions_requiredDrug) ?
                  masterdata_autocompleteOptions_requiredDrug.reduce((acc, e) => {
                    console.log("Processing:", e);
                    acc[e.article] = e;
                    return acc;
                  }, {}) :
                  {},
              ) ?? []
            }
            value={
              // eslint-disable-next-line max-len
              // https://stackoverflow.com/questions/63295924/a-component-is-changing-an-uncontrolled-autocomplete-to-be-controlled
              medicineValues.required_drug || null
            }
            isOptionEqualToValue={
              (option:any, value) =>
                option?.article === value?.article}
            getOptionLabel={(option:any) => {
              let label = "";
              if (option?.article) {
                if (option.substances && Array.isArray(option.substances)) {
                  let substances = "";
                  option.substances.forEach((element) => {
                    if (substances.length > 0) substances = substances + " ";

                    if (currentLang == "FR") {
                      substances = substances + element.NAMF;
                    } else {
                      substances = substances + element.NAMD;
                    }
                  });
                  if (substances?.length > 30 ) {
                    substances = substances + " ...";
                  }
                  label = option.article + "\u200C (" + substances + ")";
                } else {
                  label = option.article;
                }
              } else if (option.NAME) {
                label = option.NAME;
              } else {
                label= option; // edit & custom med flow
              }
              return label;
            }}
            onInputChange={(event, newInputValue) => {
              console.debug("[MedicineForm] onInputChange, value is: ", newInputValue, " for event: ", event?.type );
              if (event?.type === "change" ) {
                Promise.all([
                  dispatch(onClearMedicineState()),
                  dispatch(onReceivedBagData({data: []})),
                ]);
                dispatch(onChangeMedicineValue({field: "required_drug", value: newInputValue}));
              } else {
                // do nothing

              }
            }
            }
            freeSolo
            onChange={
              (event, value, autoCompleteChangeReason, selectedDrug) => {
                if (props.loading.editRequestLoad === true) {
                  // dont do anything here.
                } else {
                  dispatch(cleanSpecialMedicationStore({}));

                  if (autoCompleteChangeReason === "clear") {
                    Promise.all([
                      dispatch(onClearMedicineState()),
                      dispatch(onReceivedBagData({data: []})),
                    ]);
                    setUserchangedthemedication(true);
                  } else {
                    setUserchangedthemedication(true);

                    if (selectedDrug && selectedDrug.option) {
                      console.debug("[MedicineForm] onChange medication", value);
                      if (selectedDrug.option.article === undefined) {
                        console.debug("[MedicineForm][MedForm] selectedDrug.option.article === undefined", selectedDrug);
                        const pos = selectedDrug?.option?.indexOf("\u200C"); // remove substance
                        if (pos > 0) {
                          dispatch(onChangeMedicineValue({field: "required_drug", value: selectedDrug.option.substring(0, pos)}));
                        }
                      // nothing to do, no article selected
                      } else {
                        console.debug("[MedicineForm] selectedDrug.option.substance", selectedDrug.option.substance);
                        Promise.all([
                          dispatch(onChangeMedicationValues({value: selectedDrug.option})),
                          dispatch(fetchAdditionalDrugInfo(selectedDrug.option.article, diagnosisValues?.diagnosis, currentLang)),
                        ]);
                        setSwitchValues(selectedDrug.option);
                      }
                    } else {
                      console.debug("[MedicineForm] autocomplete empty");
                      Promise.all([
                        dispatch(onClearMedicineState()),
                        dispatch(onReceivedBagData({data: []})),
                      ]);
                    }
                    console.debug("[MedicineForm] [MedForm] autoSelectState", value);
                  }
                }
              }
            }
            renderInput={
              (params) => {
                return <TextField
                  {...params}
                  variant="outlined"
                  label={t("indication.details.required.article")}
                  placeholder={t("indication.details.required.article")}
                  InputProps={{...params.InputProps,
                    "readOnly": loading.bagData ||
              props.isLoadingAdditionalInfo,
                    "endAdornment": (
                      <React.Fragment>
                        {(loading?.bagData) ?
                      <CircularProgress color="inherit" size={20} /> :
                      null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    "startAdornment": ( <InputAdornment position="start"> <SearchIcon />
                    </InputAdornment> )}}
                ></TextField>;
              }
            }
          />
        </Grid>
        <Grid container spacing={1} alignItems="center" hidden={!userchangedthemedication || medicheck}>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12} className="mx-5">
            <Typography align="center">{t("wizard.delete.form")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="text"
              style={{textTransform: "uppercase"}}
              color="info"
              onClick={handleDeleteForm}
              fullWidth
            >
              {t("wizard.delete")}
            </Button>
          </Grid>
        </Grid>

        <AuthenticatedTemplate>
          {patientDiary?.length>0?
        <>
          <CardHeader
            className="p-0 pb-4 pt-4"
            action={
              <IconButton onClick={handleExpandPatient} size="large" >
                {expandedPatient? <ExpandLess />:<ExpandMore data-cy="more_saxenda"/>}
              </IconButton>
            }
            onClick={handleExpandPatient}
            avatar={
              <Avatar aria-label="recipe" style={{
                color: "#63c2de",
                backgroundColor: "#f0f3f5",
              }}>
                <MonitorWeight data-toggle="popover" data-content="Disabled popover" />
              </Avatar>
            }
            title={t("saxenda.patient.weight.tab.title")}
          />
          <Collapse in={expandedPatient} timeout="auto" unmountOnExit>
            <SaxendaTable patientDiary={patientDiary}/>
          </Collapse>
        </>:null
          }
        </AuthenticatedTemplate>
      </Grid>
      {
        medicineValues.required_drug &&
        medicineValues?.substances &&
        medicineValues?.substances?.length > 0 &&
        <Grid item xs={12}>
          <Typography cy-data="substance">
            {t("indication.details.substance")}:
            {medicineValues.substances.map((substance, index) => (" " + substance.NAMD + ","))}
          </Typography>
        </Grid>
      }

      {
        medicineValues?.substances?.length <= 0 &&
          <Grid item xs={12}>
            <Typography>
              {t("indication.details.unknown.med.selected")}
            </Typography>
          </Grid>
      }

      {
        medicineValues.required_drug&&<Grid item xs={12}>
          <Typography>{textForMed(medicineValues, t)}</Typography>
        </Grid>
      }


    </Grid>
  );
};

const textForMed=(medicineValues, t)=>{
  let textInformationForKvvType="";
  if (!medicineValues.pharmacode ) {
    medicineValues.pharmacode = t("no.pharmacode");
  }

  if (medicineValues?.inSL === true) {
    textInformationForKvvType = medicineValues.required_drug + " " + t("finder.flag.medIsInSlList");
  } else {
    textInformationForKvvType = medicineValues.required_drug + " " + t("finder.flag.sl.warning");
  }
  if (medicineValues.medicationHasLimitations === true) {
    textInformationForKvvType = textInformationForKvvType + t("newrequest.medication.LimmitatioAvailable");
  } else {
    textInformationForKvvType = textInformationForKvvType + t("newrequest.medication.LimmitatioNotAvailable");
  }
  if (medicineValues?.chr?.length > 0) {
    textInformationForKvvType = textInformationForKvvType + ", " +
    medicineValues?.chr;
  }
  if (medicineValues.lastupdated) {
    textInformationForKvvType = textInformationForKvvType + ", "+t("newrequest.lastUpdated") + ": " +
    new Date(medicineValues.lastupdated ).toLocaleDateString();
  }
  return textInformationForKvvType;
};


const mapStateToProps = (state) => {
  return {
    loading: state.wizard.wizardModel.loading,
  };
};


const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MedForm);



import {createSlice} from "@reduxjs/toolkit";


const initialState = {
  openMessage: false,
  feedbackType: "",
  feedbackMessage: "",
  messages: 0,
};

const ErrorReducerSlice = createSlice({
  name: "ErrorReducer",
  initialState: initialState,
  reducers: {
    studiesRequested(state, action) {
      return {
        ...state,
        openMessage: true,
        feedbackType: action.payload.feedbackType,
        feedbackMessage: action.payload.studiesRequested,
      };
    },
    displayToastError: (state, {payload}) => {
      console.error("displayToastError", payload);
      state.messages += 1;
      state.openMessage = false;

      state.openMessage = true;
      state.feedbackType = "error";
      state.feedbackMessage = payload;
    },
    displayToastSuccess: (state, {payload}) => {
      state.messages += 1;
      state.openMessage = false;
      state.openMessage = true;
      state.feedbackType = "success";
      state.feedbackMessage = payload;
    },
    setSnackbarStatus(state, action) {
      return {
        ...state,
        openMessage: action.payload,
      };
    },
  },
});

export const {
  studiesRequested,
  receiveUpdatedRequest,
  setSnackbarStatus,
  displayToastSuccess,
  displayToastError,

} = ErrorReducerSlice.actions;

export default ErrorReducerSlice.reducer;


/*
  this component is not used by the new wizard
*/

import React, {
  useState, ChangeEvent,
} from "react";
import {connect, ConnectedProps} from "react-redux";
import {
  TextField,
  TablePagination,
  CircularProgress,
  Grid,
  Button,
  Typography,
  InputAdornment,
} from "@mui/material";
import {onAddOrRemoveStudy} from "store/features/newRequestSlice";
import {
  fetchPubMedData,
  fetchPubmedAbstract,
} from "store/middleware/masterdataThunk";
import {UseAppDispatch} from "store/hooks";
import {studyRowType} from "../store/rootstate";
import {RootState} from "store/rootstate";
import {useTranslation} from "react-i18next";
import {PubMedCard} from "./PubMedCard";
import SearchIcon from "@mui/icons-material/Search";

const translationKey="upload.pubmed.";

const PubMedComponent = (
    props: Props,
)=> {
  const dispatch = UseAppDispatch();
  const [page, setPage] = useState(0);
  const {t}=useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    addedStudyId,
    pubMedList,
    isLoading,
    abstractContent,
    isLoadingAbstract,
    JWTToken,
  }=props;
  const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    setPage(newPage);
  };
  const handlePubMedSearch = () => dispatch(fetchPubMedData(searchQuery, JWTToken));
  const handleFetchAbstract = (id:number) => {
    dispatch(fetchPubmedAbstract(id, JWTToken));
  };
  const handleAddStudy = (studyData:studyRowType) => dispatch(onAddOrRemoveStudy(studyData));

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid container direction="column" spacing={1} >
      <Grid item container direction="row" justifyContent="space-between" spacing={3}>
        <Grid item xs={10}>
          <TextField
            inputProps={{"data-cy": "pubmedSearchInput"}}
            placeholder={t("pubmed.search.placeholder")}
            label={t("pubmed.search.placeholder")}
            fullWidth
            onChange={({target}) => setSearchQuery(target.value)}
            variant="outlined"
            onKeyPress={(e) => e.key === "Enter"&&handlePubMedSearch()}
            InputProps={{
              readOnly: isLoading,
              endAdornment: (
                <React.Fragment>
                  {isLoading === true ? <CircularProgress
                    color="inherit" size={20} /> : null}

                </React.Fragment>
              ),
              startAdornment: ( <InputAdornment position="start"> <SearchIcon />
              </InputAdornment> )}}
          />
        </Grid>


        <Grid item xs={2} sx={{textAlign: "end"}}>
          <Button
            disabled={ isLoading }
            data-cy="pubmedSearchButton"
            onClick={handlePubMedSearch}
            variant="contained">{t(`${translationKey}search`)}
          </Button>
        </Grid>
      </Grid>
      {!isLoading && pubMedList?.length>0?
      <Grid item container xs={12} spacing={3}>
        {pubMedList
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((e: studyRowType)=><Grid item xs={12} key={e?.pmid}>
              <PubMedCard
                rowData={e}
                onAddOrRemoveStudy={handleAddStudy}
                addedStudyId={addedStudyId}
                handleFetchAbstract={handleFetchAbstract}
                abstractContent={abstractContent?.[e?.pmid]}
                isLoading={isLoadingAbstract}
              />
            </Grid>)
        }
        <TablePagination
          component= {Grid}
          sx={{width: "100vw"}}
          rowsPerPageOptions={[10, 25, 100]}
          count={pubMedList?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid> :
      <Grid item alignSelf="center">
        <Typography>{t(`${translationKey}notice`)}</Typography>
      </Grid>
      }
    </Grid>
  );
};

const mapStateToProps = (state:RootState) => ({
  isLoadingAbstract: Boolean(state.loading.pubmedAbstract),
  isLoading: Boolean(state.loading.pubMed),
  pubMedList: state.masterdata.pubMedData,
  addedStudyId: state.singleRequest?.pubmedStudy?.pmid,
  abstractContent: state.masterdata.pubMedAbstracts,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  isLoading: boolean;
  pubMedList: studyRowType[];
  JWTToken:string
}

// @ts-ignore
export default connector(PubMedComponent);


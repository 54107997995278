import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import {
  onChangeKVVLegalState,
} from "../../../medicine/medicineSlice";
import {getPreselectedStudy, removeStudy} from "pages/Wizard/wizardSlice";


import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";


import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";

import {
  onChangeCalquenceValue,
  selectCalquenceValues,
} from "./CalquenceSlice";

const CalquenceForm = (props) => {
  const {t}=useTranslation();
  const dispatch = UseAppDispatch();
  const CalquenceValues=UseAppSelector(selectCalquenceValues);
  const handleTreatmentValue = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));

  useEffect(() => {
    switch (CalquenceValues.variant) {
      case 1:
        dispatch(removeStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: true,
            },
        ));
        break;
      case 2:
        dispatch(removeStudy({}));
        handleTreatmentValue("limitatio_approval", true);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: true,
              specialist_info: true,
            },
        ));
        break;
      case 3:
        dispatch(getPreselectedStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: true,
            },
        ));
        break;
      case 4:
        dispatch(getPreselectedStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: true,
            },
        ));
        break;
      case 5:
        dispatch(getPreselectedStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: true,
            },
        ));
        break;
      case 6:
        dispatch(getPreselectedStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: true,
            },
        ));
        break;
      case 8:
        dispatch(removeStudy({}));
        handleTreatmentValue("limitatio_approval", true);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: true,
              specialist_info: true,
            },
        ));
        break;
      case 7:
        dispatch(getPreselectedStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: true,
            },
        ));
        break;
      default:
        break;
    }
  }, [CalquenceValues.variant]);


  return <>
    <Grid item xs={12}>
      {
        (CalquenceValues.variant !== 0)?
          <div>
            <FormLabel> {t("calquence.variant")}&nbsp;{CalquenceValues.variant}</FormLabel>
          </div> :
          <></>
      }

      <FormControl fullWidth>
        <RadioGroup
          onChange = {(event, value) =>
            dispatch(onChangeCalquenceValue({ccl: (value === "true")}))
          }
          aria-labelledby="radio-buttons-group-ccl"
          name="radio-buttons-group-ccl"
        >
          <FormControlLabel checked={CalquenceValues.ccl === false} value="false" control={<Radio data-cy="calquencecclnot" />} label={t("calquence.ccl.not")} />
          <FormControlLabel checked={CalquenceValues.ccl === true} value="true" control={<Radio data-cy="calquenceccl" />} label={t("calquence.ccl")} />

        </RadioGroup>
      </FormControl>


      <FormControl fullWidth>
        <FormControlLabel
          hidden={CalquenceValues.ccl !== true}
          value="ibrutinib"
          data-cy="ibrutinib"
          control=
            {
              <Checkbox
                checked={CalquenceValues.ibrutinib === true}
                onChange = {(event, checked) =>
                  dispatch(onChangeCalquenceValue(
                      {
                        ibrutinib: checked,
                      }),
                  )
                } />
            }
          label={t("calquence.ibrutinib")} />

        <FormControlLabel
          hidden={CalquenceValues.ccl === true}
          value="older_65"
          data-cy="older_65"
          control=
            {
              <Checkbox
                checked={CalquenceValues.older_65 === true}
                onChange = {(event, checked) =>
                  dispatch(onChangeCalquenceValue(
                      {
                        older_65: checked,
                      }),
                  )
                } />
            }
          label={t("calquence.older.65")} />

        <FormControlLabel
          hidden={
            CalquenceValues.ccl === true ||
          (
            CalquenceValues.ccl !== false &&
              CalquenceValues.older_65 !== false
          ) ||
            (
              CalquenceValues.ibrutinib === true
            )
          }
          value="komorbid"
          control=
            {
              <Checkbox
                checked={CalquenceValues.komorbid === true}
                onChange = {(event, checked) =>
                  dispatch(onChangeCalquenceValue(
                      {
                        komorbid: checked,
                      }),
                  )
                } />
            }
          data-cy="calquence_komorbid"
          label={t("calquence.komorbid")} />
        <FormControlLabel
          hidden={
            (
              CalquenceValues.ccl === true
            ) ||
            (
              CalquenceValues.komorbid === false &&
              CalquenceValues.older_65 === false
            )


          }
          value="mutations"
          data-cy="mutations"
          control=
            {
              <Checkbox
                checked={CalquenceValues.mutations === true}
                onChange = {(event, checked) =>
                  dispatch(onChangeCalquenceValue(
                      {
                        mutations: checked,
                      }),
                  )
                } />
            }

          label={t("calquence.mutations")} />

        <FormControlLabel
          hidden={
            (
              CalquenceValues.ccl === true
            ) ||
            (
              CalquenceValues.komorbid === false &&
              CalquenceValues.older_65 === false
            )
          }
          value="kombinationtherapie"
          data-cy="kombinationtherapie"
          control=
            {
              <Checkbox
                checked={CalquenceValues.kombinationtherapie === true}
                onChange = {(event, checked) =>
                  dispatch(onChangeCalquenceValue(
                      {
                        kombinationtherapie: checked,
                      }),
                  )
                } />
            }
          label={t("calquence.combinationtherapy")} />

        <FormControlLabel
          hidden={
            (
              CalquenceValues.ccl === true
            ) ||
            (
              CalquenceValues.komorbid === false &&
              CalquenceValues.older_65 === false
            )
          }
          value="monotherapie"
          control=
            {
              <Checkbox
                checked={CalquenceValues.monotherapie === true}
                onChange = {(event, checked) =>
                  dispatch(onChangeCalquenceValue(
                      {
                        monotherapie: checked,
                      }),
                  )
                } />
            }
          label={t("calquence.monotherapie")} />
      </FormControl>

      <FormControl fullWidth>
        <FormLabel
          hidden={CalquenceValues.variant !== 2}
        >{t("calquence.kvg.inlabel")}&nbsp;</FormLabel>
        <Box sx={{m: 1}} />

        <TextField
          hidden={CalquenceValues.variant !== 2}
          InputLabelProps={{shrink: true}}
          data-cy="calquence_bonelli_dosis"
          label={t("calquence.bonelli.dosis")}
          value={CalquenceValues.bonelliform?.dosis}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeCalquenceValue(
                {
                  dosis: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          hidden={CalquenceValues.variant !== 2}
          label={t("calquence.bonelli.start")}
          InputLabelProps={{shrink: true}}
          value={CalquenceValues.bonelliform?.start }
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeCalquenceValue(
                {
                  start: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          hidden={CalquenceValues.variant !== 2}
          label={t("calquence.bonelli.end")}
          InputLabelProps={{shrink: true}}
          value={CalquenceValues.bonelliform?.end }
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeCalquenceValue(
                {
                  end: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          hidden={CalquenceValues.variant !== 2}
          label={t("calquence.bonelli.effect")}
          InputLabelProps={{shrink: true}}
          value={CalquenceValues.bonelliform?.effect }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeCalquenceValue(
                {
                  effect: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
      </FormControl>

      <FormLabel
        hidden={
          !(
            CalquenceValues.variant === 6 ||
            CalquenceValues.variant === 4
          )
        }
      >{t("calquence.kvg.attachment")}&nbsp;</FormLabel>

      <FormLabel
        hidden={
          CalquenceValues.variant === 6 || CalquenceValues.variant === 2 || CalquenceValues.variant === 4
        }
      >{t("calquence.kvg.noattachment")}&nbsp;</FormLabel>
    </Grid>


    <Box sx={{p: 1}}/>


  </>;
};

const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CalquenceForm);




import {createSlice} from "@reduxjs/toolkit";

const StatisticReducerSlices = createSlice({
  name: " StatisticReducer",
  initialState: {
    statistics: {},
  },
  reducers: {
    receiveglobalstatistics(state, action) {
      return {
        ...state,
        statistics: action.payload,
      };
    },
  },

});


export const {
  receiveglobalstatistics,
} = StatisticReducerSlices.actions;

export default StatisticReducerSlices.reducer;

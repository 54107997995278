/* eslint-disable no-unused-vars */
import {
  Card,
  Pagination,
  Grid,
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import RequestCard from "./RequestCard";

const RequestListBody = ({requestList, setSelectedRow, actions, verified}) => {
  const {t} = useTranslation();
  const [page, setPage] = useState(0);

  return (
    <Grid container justifyContent={"space-around"} paddingBottom='10px'>
      <Grid item xs={12}>
        {requestList
            .slice(page*10, page*10+10)
            .map((row) => <RequestCard
              actions={actions}
              verified={verified}
              key={row.request_id}
              setSelectedRow={setSelectedRow}
              row={row}/>)}
      </Grid>
      <Grid item xs={12}>
        {requestList.length===0&&(
          <Card>
            {t("no.request.created.yet")}
          </Card>
        )}
      </Grid>
      <Grid item marginTop='10px'>
        <Card>
          <Pagination
            onChange={(event, newPage)=>setPage(newPage-1)}
            count={Math.ceil(requestList.length/10)}
            showFirstButton
            showLastButton
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default RequestListBody;

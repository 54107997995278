import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  connect,
  useDispatch,
} from "react-redux";
import {
  fetchRequests,
  handlePrintRequest,
  handleDrugOrderRequest,
  requestStudiesReq,
  handleDownloadAll,
  handlePrintAll,
  handleDeleteRequest,
  sendSetReminder,
} from "store/middleware/requestListThunk";
import {
  setPrintStudiesAvailability,
  setBanner,
  onSort,
  onSearchChange,
  onCheckRequest,
  onCheckAllRequests,
  setSelectedRow,
  onFilterChange,
  onDeleteFilter,
  onClearFilters,
} from "../../../store/features/requestListSlice";
import {useHistory} from "react-router-dom";
import {
  Grid,
} from "@mui/material";
import Overlay from "react-overlay-component";
import PDFViewer from "components/PDFViewer";
import HeaderCard from "../components/HeaderCard";
import {communicationQueryChange} from "store/features/communicationSlice";
import {useSnackbar} from "notistack";

import Backdrop from "components/Backdrop";
import RequestListBody from "../components/RequestListBody";
import PendingInsuranceDialog from "components/dialogs/PendingInsuranceDialog";
import StateDropdown from "../components/StateDropdown";
import DeletionDialog from "components/dialogs/DeletionDialog";
import ReminderDialog from "components/dialogs/ReminderDialog";
import DropdownAction from "../components/DropdownAction";
import FiltersCard from "../components/FiltersCard";

const RequestList = (props) => {
  const {
    showBanner,
    studiesFiles,
    studiesStatus,
    row,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();


  const [sortedBy] = useState("");

  const closeOverlay = () => dispatch(setPrintStudiesAvailability(false));
  const configs = {
    animate: true,
  };

  useEffect(async () => {
    dispatch(fetchRequests());
    dispatch(onClearFilters());
    dispatch(setPrintStudiesAvailability(false));
  }, []);

  useEffect(()=>{
    if (showBanner) {
      enqueueSnackbar(t("successful.request"), {variant: "success", anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      }, autoHideDuration: 3000});
      setBanner(false);
    }
  }, [showBanner]);

  const handleEditRequest = (id, kogu_type) => {
    dispatch(setBanner(false));
    if (kogu_type==="med") {
      history.push(`/wizard/${id}`);
    } else {
      history.push(`/edit_request/${id}`);
    }
  };

  const handleShowDocuments=(id, requestType)=>{
    dispatch(setBanner(false));
    if (requestType==="med") {
      history.push(`/wizard/${id}/1`);
    } else {
      history.push(`/edit_request/${id}/3`);
    }
  };

  const handleCopyRequest = (id, kogu_type) => {
    dispatch(setBanner(false));

    if (kogu_type === "med") {
      history.push(`/wizard/copy_request/${id}`);
    } else {
      history.push(`/copy_request/${id}`);
    }
  };
  const handleCopyRequestPraluent = (id) => {
    dispatch(setBanner(false));
    history.push(`/copy_request_praluent/${id}`);
  };

  const handleCloseReminder = (id, date) => {
    dispatch(sendSetReminder(id, date));
    dispatch(setSelectedRow({row: {}, isOpenReminderDialog: false}));
  };

  const handleNavToCommunication=(id)=>{
    history.push("/communication/");
    dispatch(communicationQueryChange(id.toString()));
  };
  const {t, i18n} = useTranslation();
  const currentLang = i18n.language.toLowerCase().substring(0, 2);


  return (
    <div className="fade-in">
      <Backdrop open={props.isLoading}/>

      <Overlay configs={configs} isOpen={studiesStatus} closeOverlay={closeOverlay} className="overlay-pdf">
        {studiesFiles.length > 0 ? studiesFiles.map((file, idx) => {
          return (
            <PDFViewer
              key={idx}
              src={file.file}
            />
          );
        }) : ""}
      </Overlay>
      <PendingInsuranceDialog
        status={row?.row.request_status}
        requestId={row?.row.request_id}
        requestData={row?.row}
        isOpen={row?.isOpenInsuranceDialog ? row?.isOpenInsuranceDialog : false}
        handleClose={()=>
          dispatch(setSelectedRow({row: {}, isOpenInsuranceDialog: false}))}
      />
      <StateDropdown
        requestId={row?.row.request_id}
        state={row?.row.request_status}
        kogu_type={row?.row.kogu_type}
        anchorEl={row?.isOpenStateDropdownDialog}
        handleClose={()=>
          dispatch(setSelectedRow({row: {}, isOpenStateDropdownDialog: null}))}
      />
      <DeletionDialog
        open={row?.isOpenDeletionDialog ? row?.isOpenDeletionDialog : false}
        handleClose={()=> {
          dispatch(handleDeleteRequest(row?.row.request_id));
          dispatch(setSelectedRow({row: {}, isOpenDeletionDialog: false}));
        }}
        handleCancel={()=>
          dispatch(setSelectedRow({row: {}, isOpenDeletionDialog: false}))}
        title={t("delete.request.title")}
        description={t("delete.request.text")}
      />
      <ReminderDialog
        reminders={row?.row.reminder_dates}
        open={row?.isOpenReminderDialog ? row?.isOpenReminderDialog : false}
        handleClose ={(date)=> handleCloseReminder(row?.row.request_id, date)}
        handleCancel={()=>
          dispatch(setSelectedRow({row: {}, isOpenReminderDialog: false}))}
        title={t("set.reminder.title")}
        description={t("set.reminder.text")}
      />
      <DropdownAction
        verified ={props.verified}
        requestKoGuType = {row?.row.kogu_type}
        notPraluent={row?.row.med_data?.special_med_type?.toString()!=="Praluent"}
        noPharmaSet = { (row?.row.pharma_company_name) === "" || !(row?.row.med_data?.pharma_email)|| (row?.row.pharma_company_name) === null }
        noInsuranceSet = {row?.row.patient_insurance_company === "" || row?.row.patient_insurance_company === null||
                          row?.row.patient_insurance_company_gln === "" ||row?.patient_insurance_company_gln=== null}
        numberofAttachments = {row?.row.attachments_external +
           row?.row.attachments_internal + row?.row.studies_attached}
        noDiagnosis = {row?.row.diagnosis === "" || row?.row.diagnosis === null}
        data-cy={"menuiconbutton_"+row?.row.request_id}
        requestid = {row?.row.request_id}
        value={row?.row.action}
        anchorEl={row?.isOpenMenuButton}
        closeAnchorEl={()=>
          dispatch(setSelectedRow({...row, row: {}, isOpenMenuButton: null}))}
        statusValue={row?.row.request_status}
        drugIsNotSet= {row?.row.required_drug === "" || row?.row.required_drug === null || row?.row.med_data?.required_drug === null}
        actions={{
          deleteRequest: () =>
            dispatch(setSelectedRow({row: row?.row, isOpenDeletionDialog: true})),
          print: () =>
            dispatch(handlePrintRequest(row?.row.request_id, currentLang)),
          edit: () => handleEditRequest(row?.row.request_id, row?.kogu_type),
          orderDrug: () =>
            dispatch(handleDrugOrderRequest(row?.row.request_id, currentLang)),
          requestStudy: () =>
            dispatch(requestStudiesReq(row?.row.request_id, currentLang)).then(() =>
              dispatch(fetchRequests())),
          copyRequest: () => handleCopyRequest(row?.row.request_id, row?.row.kogu_type),
          copyRequestPraluent: ()=>handleCopyRequestPraluent(row?.row.request_id),
          insuranceDialog: () =>
            dispatch(setSelectedRow({row: row?.row, isOpenInsuranceDialog: true})),
          downloadAll: () => dispatch(handleDownloadAll(row?.row.request_id,
              currentLang,
              (row?.row.attachments_external || row?.row.attachments_internal))),
          printAll: () => dispatch(handlePrintAll(row?.row.request_id,
              currentLang,
              (row?.row.attachments_external || row?.row.attachments_internal))),
          navCommunication: () => handleNavToCommunication(row?.row.request_id),
        }}
      />
      <Grid className="">
        <FiltersCard
          title={t("routes.label.myrequests")}
          length={props.requestList.length}
          searchQuery={props.searchQuery}
          onSearchChange={props.onSearchChange}
          onFilterChange={props.onFilterChange}
          onDeleteFilter={props.onDeleteFilter}
          sortedBy={sortedBy}
          filters={props.filters}
          label_search={t("routes.label.search")}
        />
        <HeaderCard/>
        <RequestListBody
          requestList={props.requestList}
          setSelectedRow = {(obj) => dispatch(setSelectedRow(obj))}
          currentlanguage={currentLang}
          verified ={props.verified}
          actions={{
            edit: (id, kogu_type)=>handleEditRequest(id, kogu_type),
            navCommunication: (id)=>handleNavToCommunication(id),
            insuranceDialog: (row) =>
              dispatch(setSelectedRow({row: row, isOpenInsuranceDialog: true})),
            printStudy: (id, kogu_type) => handleShowDocuments(id, kogu_type),
          }}
        />
      </Grid>
    </div>
  );
};


const mapStateToProps = (state) => {
  if (!state?.requestList?.filters) {
    return {};
  }

  const {
    requestList: {
      studiesFiles,
      studiesStatus,
      statusMessage,
      showBanner,
      selectedRow,
      searchQuery,
      isError,
      pharmaCompanies,
      itemList,
      filters,
    },
    loading: {requestList: isLoading},
    userinformation: {user},
  } = state;

  const verified = user?.groups?.includes("verified");

  const requestList = Array.isArray(itemList) ?
    itemList
        .filter((item) => {
          const isInFilters = Object.keys(filters)
              .reduce((acc, key) => acc && item[key] === filters[key], true);
          const isInSearchQuery = searchQuery.length > 0 ?
            Object.keys(item).reduce((acc, key) =>
              acc || String(item[key])?.toLowerCase().includes(searchQuery?.toLowerCase()), false) :
            true;
          return isInFilters && isInSearchQuery;
        })
        ?.map((e) => ({...e, kogu_type: e.kogu_type ?? "med"})) :
    [];

  return {
    studiesFiles,
    studiesStatus,
    statusMessage,
    showBanner,
    row: selectedRow,
    searchQuery,
    isLoading,
    isError,
    pharmaCompanies,
    verified,
    filters,
    requestList,
  };
};


const mapDispatchToProps = {
  fetchRequests,
  onSort: (sortBy, sortAsc) => onSort({sortBy, sortAsc}),
  onSearchChange: (value) => onSearchChange({value: value}),
  onCheckboxSelected: (id, isChecked) => onCheckRequest({id: id, isChecked: isChecked}),
  onFilterChange: (value)=>onFilterChange(value),
  onDeleteFilter: (filter)=>onDeleteFilter({filter: filter}),
  onAllCheckboxSelected: (isChecked) => onCheckAllRequests({isChecked: isChecked}),
};

export default (connect(mapStateToProps, mapDispatchToProps)(RequestList));

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface IDosisPlanState {
  formData:{
    patient_treatment_startdate:Date
    dosis:string,
    combined_drug:any,
  };
}
const initialState:IDosisPlanState = {
  formData: {
    patient_treatment_startdate: new Date(),
    dosis: "",
    combined_drug: [],
  },
};

const dosisPlanSlice = createSlice({
  name: "dosisPlan",
  initialState,
  reducers: {
    onCombinedMedication: (state, {payload}: PayloadAction<any>) => {
      if (undefined !== payload) {
        state.formData.combined_drug = payload;
      } else {
        state.formData.combined_drug = [];
      }
    },
    onReceiveDosisPlanEditData: (state, {payload}:PayloadAction<any>)=>{
      state.formData ={...state.formData, ...payload};
    },
    onChangeDosisValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      state.formData[payload.field] = payload.value;
    },
    onClearDosisState: (state)=>{
      state.formData=initialState.formData;
    },
  },
});


export const {
  onClearDosisState,
  onChangeDosisValue,
  onReceiveDosisPlanEditData,
  onCombinedMedication,
} = dosisPlanSlice.actions;

export default dosisPlanSlice.reducer;
export const selectDosisValues = (state:RootState) => state.wizard.medicine.dosisPlanForm.formData;

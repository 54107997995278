import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {RadioButtonChecked, RadioButtonUnchecked} from "@mui/icons-material";
import {onChangeBrukinsaCllValue, selectBrukinsaCllValues} from "./BrukinsaCllSlice";
import {
  getPreselectedStudy,
} from "pages/Wizard/wizardSlice";


const BrukinsaFormCll=(props) => {
  const {t}=useTranslation();

  const brukinsaValues=UseAppSelector(selectBrukinsaCllValues);
  const dispatch=UseAppDispatch();
  const handleValueChange = (field:string, value:any)=>dispatch(onChangeBrukinsaCllValue({field, value}));

  useEffect(()=>{
    dispatch(getPreselectedStudy({}));
  }, [brukinsaValues]);


  return <>
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            name="brukinsa_untreated"
            icon={<RadioButtonUnchecked />}
            data-cy="chk_brukinsa_untreated"
            checkedIcon={<RadioButtonChecked />}
            checked={brukinsaValues.previously_treated === false}
            onChange={(event, newValue)=> {
              handleValueChange("previously_treated", false);
            }}/>
        }
        label={t("indication.details.brukinsa_untreated")}
      />
    </Grid>
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            name="brukinsa_previously_treated"
            icon={<RadioButtonUnchecked />}
            data-cy="chk_brukinsa_previously_treated"

            checkedIcon={<RadioButtonChecked />}
            checked={brukinsaValues.previously_treated === true}
            onChange={(event, newValue)=> {
              handleValueChange("previously_treated", true);
            }}/>
        }
        label={t("indication.details.brukinsa_previously_treated")}
      />
    </Grid>
  </>;
};

const mapStateToProps=(state) => {
  return {

  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(BrukinsaFormCll);



import * as React from "react";
import {styled} from "@mui/material/styles";
import {TextareaAutosize} from "@mui/material";
import {TextareaAutosizeProps} from "react-textarea-autosize";

export const TextArea = styled(React.forwardRef((props: TextareaAutosizeProps, ref:any)=> (
  <TextareaAutosize ref={ref} {...props} />
)))(({theme}) => ({
  "borderRadius": "4px",
  "borderColor": theme.palette.grey[400],
  "padding": "12px",
  "&::placeholder": {
    "color": theme.palette.grey[400],
  },
  "&::-webkit-resizer": {
    display: "none",
  },
  "&:focus-visible": {
    outlineColor: theme.palette.primary.main,
  },
}));

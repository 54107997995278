import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import MedicationIcon from "@mui/icons-material/Medication";
import {fetchFirstSearchResultFromBackend} from "pages/Wizard/wizardSlice";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import {setCombinedMedication} from "pages/Wizard/wizardSlice";

import Stack from "@mui/material/Stack";
import WarningIcon from "@mui/icons-material/Warning";
import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";
import {onChangeSpecialMedValue, SpecialMedAdditionalInformation} from "../../specialMedSlice";
import {setStudyById, removeStudy} from "pages/Wizard/wizardSlice";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
} from "../../../medicine/medicineSlice";
import {onChangeTreatmentValue, selectTreatmentValues} from "../../../Treatment/treatmentPlanSlice";

import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";

import {
  onChangeImfinziValue,
  selectImfinziValues,
} from "./ImfinziSlice";

import {
  onChangeImjudoValue,
} from "./../Imjudo/ImjudoSlice";


const ImfinziForm = (props) => {
  const {t, i18n} = useTranslation();
  const currentLang=i18n.language.toUpperCase();

  const dispatch = UseAppDispatch();
  const ImfinziValues = UseAppSelector(selectImfinziValues);
  const treatmentValues = UseAppSelector(selectTreatmentValues);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const handleDosisValueChange = (field:string, value:any)=>dispatch(onChangeDosisValue({field, value}));
  const handleSpecialMedValueChange = (field:string, value:any)=>dispatch(onChangeSpecialMedValue({field, value}));

  return <>
    <Grid item xs={12}>
      <FormControl>
        <RadioGroup
          aria-labelledby="imfinzi-radio-buttons-group-form-type"
          name="imfinzi-radio-buttons-group-form-type"

          onChange={(event, value) => {
            dispatch(setCombinedMedication({medication: ""}));

            dispatch(onChangeImfinziValue({choice: value}));
            if (value === "topaz") {
              dispatch(onChangeKVVLegalState(
                  {
                    limitatio_approval: false,
                    specialist_info: true,
                  },
              ));
              dispatch(setCombinedMedication({medication: ""}));
              handleValueChangeKVV("limitatio_approval", false);
              handleValueChangeKVV("specialist_info", true);
              handleDosisValueChange("dosis", t("imfinzi.dose"));
              handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.IMFINZI_TOPAZ);
              dispatch(setStudyById({studyId: "38319896"}));
            } else if (value === "default") {
              dispatch(removeStudy({}));
              handleDosisValueChange("dosis", "");
              handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);
              dispatch(setCombinedMedication({medication: ""}));
            } else if (value === "himalaya" ) {
              dispatch(onChangeKVVLegalState(
                  {
                    limitatio_approval: false,
                    specialist_info: false,
                  },
              ));
              handleValueChangeKVV("limitatio_approval", false);
              handleValueChangeKVV("specialist_info", false);
              dispatch(setStudyById({studyId: "38319892"}));
              dispatch(onChangeImjudoValue({choice: "himalaya"}));
              dispatch(onChangeImjudoValue({formData: {inCombinationWithImfinzi: true}}));
              dispatch(setCombinedMedication({medication: t("imjudo.inCombinationWithImfinzi.medication")}));
              handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);
            } else if (value === "lungcarcinoma") {
              dispatch(removeStudy({}));
              handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);
            }
          }}
        >
          <FormControlLabel
            value="topaz"
            control={
              <Radio
                data-cy="topaz"
                checked={ ImfinziValues.topaz}
              />

            } label={t("imfinzi.topaz")} />
          <FormControlLabel
            value="himalaya"
            data-cy="himalaya"
            control={<Radio checked={ImfinziValues.himalaya} />} label={t("imfinzi.himalaya")} />

          <FormControlLabel
            value="lungcarcinoma"
            hidden={false}
            control={
              <Radio
                data-cy="lungcarcinoma"
                checked={ImfinziValues.lungcarcinoma}
              />
            } label={t("imfinzi.lungcarcinoma")} />
          <FormControlLabel
            value="default"
            control={<Radio checked={ ImfinziValues.default}/>} label={t("imfinzi.default")} />
        </RadioGroup>
      </FormControl>


      <Box sx={{m: 1}}
        hidden= {(ImfinziValues.topaz === false ) }
      />
      <Stack
        direction="row"
        alignItems="left"
        gap={1}
        hidden= {(ImfinziValues.topaz === false ) }
      >
        <WarningIcon color={"primary"}></WarningIcon>
        <Typography>{t("imfinzi.helptext")}</Typography>
      </Stack>
      {
        (ImfinziValues.lungcarcinoma) ?
          <div>
            <FormControl>
              <FormLabel><Typography sx={{fontWeight: "bold"}}>{t("imfinzi.lungcarcinoma.details")}</Typography></FormLabel>


              <RadioGroup
                aria-labelledby="imfinzi-radio-buttons-group-form-lungcarcinoma"
                onChange={(event, value) => {
                  dispatch(onChangeImfinziValue({lungcarcinoma: value}));
                  dispatch(setCombinedMedication({medication: ""}));
                  if (value === "aegean") {
                    handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.IMFINZI_AEGEN);
                    dispatch(onChangeKVVLegalState(
                        {
                          limitatio_approval: false,
                          specialist_info: false,
                        },
                    ));
                    handleDosisValueChange("dosis", "");
                    handleValueChangeKVV("limitatio_approval", false);
                    handleValueChangeKVV("specialist_info", false);
                    dispatch(setStudyById({studyId: "37870974"}));
                    dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
                  }

                  if (value === "poseidon") {
                    // nothing to do
                    handleDosisValueChange("dosis", "");
                    dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
                    dispatch(onChangeKVVLegalState(
                        {
                          limitatio_approval: false,
                          specialist_info: false,
                        },
                    ));
                    handleValueChangeKVV("limitatio_approval", false);
                    handleValueChangeKVV("specialist_info", false);
                    dispatch(onChangeImjudoValue({choice: "poseidon"}));
                    dispatch(onChangeImjudoValue({formData: {inCombinationWithImfinzi: true}}));
                    dispatch(setCombinedMedication({medication: t("imfinzi.inCombinationWithImjudo")}));
                    dispatch(setStudyById({studyId: "36327426"}));
                    handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.IMJUDO_POSEIDON);
                  }
                  if (value === "pacific") {
                    console.log("pacific");
                    dispatch(removeStudy({}));
                    dispatch(onChangeMedicineValue({field: "kn_id", value: "20791.01"}));
                    dispatch(onChangeKVVLegalState(
                        {
                          limitatio_approval: true,
                          specialist_info: true,
                        },
                    ));
                    handleValueChangeKVV("limitatio_approval", true);
                    handleValueChangeKVV("specialist_info", true);
                    handleDosisValueChange("dosis", "");
                  }
                  if (value === "caspian") {
                    dispatch(removeStudy({}));
                    dispatch(onChangeMedicineValue({field: "kn_id", value: "20791.02"}));
                    dispatch(onChangeKVVLegalState(
                        {
                          limitatio_approval: true,
                          specialist_info: true,
                        },
                    ));
                    handleValueChangeKVV("limitatio_approval", true);
                    handleValueChangeKVV("specialist_info", true);
                    handleDosisValueChange("dosis", "");
                  }
                  if (value === "adriatic") {
                    dispatch(setStudyById({studyId: "31948903"}));
                    handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.IMFINZI_ADREIATIC);
                    dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
                    dispatch(onChangeKVVLegalState(
                        {
                          limitatio_approval: false,
                          specialist_info: false,
                        },
                    ));
                    handleValueChangeKVV("limitatio_approval", false);
                    handleValueChangeKVV("specialist_info", false);
                    handleDosisValueChange("dosis", "");
                    dispatch(setCombinedMedication({medication: ""}));
                  }
                }}
              >
                {
                  <FormControlLabel
                    value="aegean"
                    hidden={false}
                    control={
                      <Radio
                        data-cy="aegean"
                        checked={ImfinziValues.aegean}
                      />
                    } label={t("imfinzi.aegean")} />
                }
                {
                  <FormControlLabel
                    value="pacific"
                    hidden={false}
                    control={
                      <Radio
                        data-cy="pacific"
                        checked={ImfinziValues.pacific}
                      />
                    } label={t("imfinzi.pacific")} />
                }
                {
                  <FormControlLabel
                    value="poseidon"
                    hidden={false}
                    control={
                      <Radio
                        data-cy="poseidon"
                        checked={ImfinziValues.poseidon}
                      />
                    } label={t("imfinzi.poseidon")} />
                }
                {
                  <FormControlLabel
                    value="adriatic"
                    hidden={false}
                    control={
                      <Radio
                        data-cy="adriatic"
                        checked={ImfinziValues.adriatic}
                      />
                    } label={t("imfinzi.adriatic")} />
                }
                {
                  <FormControlLabel
                    value="caspian"
                    hidden={false}
                    control={
                      <Radio
                        data-cy="caspian"
                        checked={ImfinziValues.caspian}
                      />
                    } label={t("imfinzi.caspian")} />
                }
              </RadioGroup>
            </FormControl>
          </div>:
          null
      }


      <div
        hidden={
          !(ImfinziValues.default === true)}>

        <Box sx={{m: 1}}/>
        <Typography sx={{fontWeight: "bold"}} >{t("lynparza.details.text")}</Typography>


        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          InputLabelProps={{shrink: true}}
          data-cy="preTreatment"
          label={t("lynparza.defaultForm.preTreatment")}
          value={ImfinziValues.defaultForm?.preTreatment}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeImfinziValue(
                {
                  preTreatment: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          multiline
          style={{width: "100%"}}
          label={t("lynparza.defaultForm.patientCondition")}
          InputLabelProps={{shrink: true}}
          value={ImfinziValues.defaultForm?.patientCondition }
          variant="outlined"
          maxRows={4}
          minRows={4}
          onChange = {(event ) =>
            dispatch(onChangeImfinziValue(
                {
                  patientCondition: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("lynparza.defaultForm.reasonsAlternativeTherapiesFailed")}
          InputLabelProps={{shrink: true}}
          value={ImfinziValues.defaultForm?.reasonsAlternativeTherapiesFailed }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeImfinziValue(
                {
                  reasonsAlternativeTherapiesFailed: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("lynparza.defaultForm.prognosis")}
          InputLabelProps={{shrink: true}}
          value={ImfinziValues.defaultForm?.prognosis }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeImfinziValue(
                {
                  prognosis: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

      </div>


      {
        (ImfinziValues.aegean) ?
          <div>
            <Box sx={{m: 1}}/>
            <Grid item><Typography>
              {t("imfinzi.aegean.helptext")}&nbsp;
              {t("imfinzi.lungcarcinoma.details")}
            </Typography></Grid>
            <FormControl>
              <RadioGroup
                aria-labelledby="imfinzi-radio-buttons-group-form-lungcarcinoma"
                onChange={(event, value) => {
                  dispatch(onChangeImfinziValue({lungcarcinoma: value}));
                  if (value === "iIIAN2") {
                    dispatch(onChangeImfinziValue({
                      formAegean: {
                        iIIAN2: true,
                      }}));
                    dispatch(onChangeImfinziValue({
                      formAegean: {
                        iIIAN3: false,
                      }}));
                  } else {
                    dispatch(onChangeImfinziValue({
                      formAegean: {
                        iIIAN2: false,
                      }}));
                    dispatch(onChangeImfinziValue({
                      formAegean: {
                        iIIAN3: true,
                      }}));
                  }
                }}
              >
                {
                  <FormControlLabel
                    value="iIIAN2"
                    hidden={false}
                    control={
                      <Radio
                        data-cy="iIIAN2"
                        checked={ImfinziValues.formAegean.iIIAN2}
                      />
                    } label={t("imfinzi.aegean.iIIAN2")} />
                }
                {
                  <FormControlLabel
                    value="iIIAN3"
                    hidden={false}
                    control={
                      <Radio
                        data-cy="iIIAN3"
                        checked={ImfinziValues.formAegean.iIIAN3}
                      />
                    } label={t("imfinzi.aegean.iIIAN3")} />
                }
              </RadioGroup>
            </FormControl>

            <div
              hidden={
                !ImfinziValues.formAegean.iIIAN2 &&
                !ImfinziValues.formAegean.iIIAN3
              }
            >
              <FormControl
                fullWidth
              >
                <FormControlLabel
                  hidden={false}
                  control=
                    {
                      <Checkbox
                        data-cy="pdl1_0"
                        checked={ImfinziValues.formAegean.pdl1_0}
                        onChange={(event, value) => {
                          if (value === true) {
                            dispatch(onChangeKVVLegalState(
                                {limitatio_approval: false, specialist_info: true},
                            ));
                            handleValueChangeKVV("limitatio_approval", false);
                            handleValueChangeKVV("specialist_info", true);
                          } else {
                            dispatch(onChangeKVVLegalState(
                                {limitatio_approval: false, specialist_info: false},
                            ));
                            handleValueChangeKVV("limitatio_approval", false);
                            handleValueChangeKVV("specialist_info", false);
                          }

                          dispatch(onChangeImfinziValue({
                            formAegean: {
                              pdl1_0: value,
                            }}));
                        }}
                      />
                    }
                  label={t("imfinzi.aegean.pdl1_0")} />
              </FormControl>
            </div>
            <Box sx={{m: 1}}/>
            <TextField
              data-cy="recidivism"
              style={{width: "100%"}}
              label={t("imfinzi.formAegean.recidivism")}
              InputLabelProps={{shrink: true}}
              value={ImfinziValues.formAegean?.recidivism }
              variant="outlined"
              maxRows={4}
              minRows={4}
              multiline
              onChange = {(event ) =>
                dispatch(onChangeImfinziValue(
                    {
                      formAegean: {
                        recidivism: (event.target as HTMLInputElement).value,
                      },
                    }),
                )
              }
            />
          </div> :
          null
      }
    </Grid>


    <Grid item xs={12}>
      <Stack
        direction="row"
        alignItems="left"
        gap={1}
        hidden= {
          (treatmentValues.limitatio_approval === false ) ||
          ImfinziValues.default === true ||
          ImfinziValues.caspian === true

        }
      >
        <WarningIcon color={"primary"}></WarningIcon>
        <Typography>{t("imfinzi.warning.limitatio")}</Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="left"
        gap={1}
        hidden= {!ImfinziValues.caspian }
      >
        <WarningIcon color={"warning"}></WarningIcon>
        <Typography>{t("imfinzi.warning.caspian")}</Typography>
      </Stack>
      <Grid item xs={12}
        hidden={
          !(ImfinziValues.himalaya === true) &&
          !(ImfinziValues.poseidon === true)
        }
      >
        <Stack
          direction="row"
          alignItems="left"
          gap={1}
        >
          <WarningIcon color={"primary"}></WarningIcon>
          <Typography>{t("imfinzi.warning.himalaya")}</Typography>
        </Stack>
        <Button
          onClick={() => {
            dispatch(fetchFirstSearchResultFromBackend({
              searchstring: t("imjudo.warning.himalaya.switch.imjudomed"),
              currentlang: currentLang,
            }));
          }}
          style={{marginLeft: "30px"}} variant="contained" endIcon={<MedicationIcon />}>
          {t("imfinzi.warning.himalaya.switch")}
        </Button>
      </Grid>


    </Grid>
    <Box sx={{p: 1}} />
  </>;
};

const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ImfinziForm);



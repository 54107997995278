import i18n from "../i18n";

export const alphabeticalSort =(a, b)=> {
  if (a.value.toLowerCase() < b.value.toLowerCase()) {
    return -1;
  }
  if (a.value.toLowerCase() > b.value.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const options = {
  reha: {
    reasons: [
      "reha.reasons.akutrehabilitation",
      "reha.reasons.berufliche",
      "reha.reasons.medizinische",
      "reha.reasons.rehabiliation",
    ],
  },
};


export const screenshot = async (dispatch, sendScreenshotForOcr) => {
  const mediaDevices = navigator.mediaDevices;
  const displayMediaOptions = {
    video: {
      mediaSource: "screen",
    },
  };

  const stream = await mediaDevices.getDisplayMedia(displayMediaOptions);

  const track = stream.getVideoTracks()[0];
  const imageCapture = new ImageCapture(track);

  const bitmap = await imageCapture.grabFrame();
  track.stop();

  const canvas = document.getElementById("myCanvas");

  canvas.width = bitmap.width;
  canvas.height = bitmap.height;
  const context = canvas.getContext("2d");
  context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
  const image = canvas.toDataURL();
  // this turns the base 64 string to a [File] object
  const res = await fetch(image);
  const buff = await res.arrayBuffer();
  dispatch(sendScreenshotForOcr(buff));
};
export function inputCheckValidity(value, rules) {
  let isValid = false;
  const noSpaceValue = value.replace(/\s/g, "");

  if (rules && rules.required) {
    isValid = value.trim() !== "";
  }

  if (rules && rules.minLength && rules.maxLength &&
    (rules.minLength !== rules.maxLength)) {
    isValid = noSpaceValue.length >= rules.minLength &&
    noSpaceValue.length <= rules.maxLength;
  } else if (rules && (rules.minLength === rules.maxLength)) {
    isValid = noSpaceValue.length === rules.minLength;
  } else if (rules && rules.minLength) {
    isValid = noSpaceValue.length >= rules.minLength;
  } else if (rules && rules.maxLength) {
    isValid = noSpaceValue.length <= rules.maxLength;
  }

  return isValid;
}

export const normalizePatientNo = (value) => {
  const numericValue = value.replaceAll(".", "").replaceAll(" ", "");
  return `${numericValue.slice(0, 3)}${numericValue.length > 2 ? "." : ""}` +
    `${numericValue.slice(3, 7)}${numericValue.length > 6 ? "." : ""}` +
    `${numericValue.slice(7, 11)}${numericValue.length > 10 ? "." : ""}` +
    `${numericValue.slice(11, 13)}`;
};

export const delay = (() => {
  let timeout = null;
  return (callback, ms) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, ms);
  };
})();
export const formInitialValues = {
  doctor: {
    doctor_specialty: "",
    doctor_phone_no: "",
    doctor_country: "CH",
  },
  patient: {
    patient_country: "CH",
  },
  transport: {
    clinic_address_custom: false,
    patient_address_custom: false,
  },
  med: {
    limitatio_approval: "false",
    specialist_details: "false",
  },
  reha: {},
  breathing_device: {
    home_ventilation: true,
    home_ventilation_duration: "nights",
    request_reason: "first",
    maskType: "nose",
    mask_size: "M",
    humidifierType: "integrated",
    activated: "activated",
    praxis: "present",
    supplier: "SOS Oxygène SA",
  },
};


export const checkIfMinDateNeeded=(isCopy, requestId, date)=>{
  if (!Boolean(requestId)) {
    return date;
  } else {
    if (isCopy) {
      return date;
    } else {
      return undefined;
    }
  }
};
export const datepickerLimitations = {
  med: {
    patient_treatment_startdate: ({
      minDate: (new Date()).setMonth((new Date()).getMonth() - 3),
      maxDate: (new Date()).setMonth((new Date()).getMonth() + 12),
    }),
  },
  breathing_device: {
    start: ({
      minDate: (new Date()).setMonth((new Date()).getMonth() - 3),
      maxDate: (new Date()).setMonth((new Date()).getMonth() + 12),
    }),
  },
  patient: {
    patient_date_of_birth: ({
      maxDate: new Date(),
    }),
  },
  reha: {
    entry_date: ({
      minDate: new Date(),
    }),
  },
  transport: {
    transport_date: ({
      minDate: (new Date()).setMonth((new Date()).getMonth() - 3),
      maxDate: (new Date()).setMonth((new Date()).getMonth() + 12),
    }),
  },

};
export const fieldNames = require("./fieldNames.json");

export const doctorProfileComplete = (doctorData) => [
  "city",
  "country",
  "email",
  "family_name",
  "givenName",
  "family_name",
  "postalCode",
  "streetAddress",
].reduce((a, e) => a && doctorData[e], true);


export const correctDateFormat = (date) => {
  if (date && date!=="Invalid Date") {
    return new Date(Date.UTC(date?.getFullYear(), date?.getMonth(), date?.getDate()));
  }
};
export const error = {
  textfield: "textfield.error",
  postalcode: "textfield.postalcode",
  insurance: "textfield.insurance",
  date: "textfield.date",
  futureDate: "textfield.date.future",
  required: "field.required",
  hour: "field.hour",
  phone_no: "field.phone_no",
  mail: "textfield.mail",
};

// Return date in mm/dd/yyyy format
export const getTodayString = () => String((new Date()).getMonth() + 1).padStart(2, "0") + "/" + String((new Date()).getDate()).padStart(2, "0") + "/" + (new Date()).getFullYear();

export const genderOptionsLabels = [
  i18n.t("patient.gender.male"),
  i18n.t("patient.gender.female"),
  i18n.t("patient.gender.other"),
];


function isValidDate(dateValue) {
  if (dateValue==="Invalid Date") {
    return false;
  } else if (dateValue instanceof Date) {
    return !isNaN(dateValue.getMonth());
  } else if (typeof dateValue !== "string") {
    return true;
  } else if (dateValue === "") {
    return true;
  } else if (typeof dateValue === "undefined") {
    return false;
  } else if (dateValue == null) {
    return true;
  }

  return true;
}

export const validateEmail=(value)=>{
  const regEx = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/); // NOSONAR
  if (value?.length>0) {
    return regEx.test(value?.toString(0));
  }
  return true;
};

export const fieldLevelValidator = {
  validHour: (value) => (!value || (value >= 0 && value <= 24)) ? undefined : error.hour,
  validDate: (value) => (!value||isValidDate(value) ? undefined : error.date),
  lengthLessThanTwo: (value)=>(value&&value.length<2)?error.textfield:undefined,
  required: (value) => (value ? undefined : error.required),
  requiredCheckbox: (value) => (value === 1 ? undefined : error.required),
  requiredPatientForm: (value) => (value || typeof value === "number" ?
   undefined :
   error.required),
  validEmail: (value)=> !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
      .test(value)||value===undefined)?error.mail:undefined,
  validPostalCode: (value)=>(value&&value.length<4)?error.postalcode:undefined,
  validPhoneNo: (value)=>{
    return (value&&value.length<11)?error.phone_no:undefined;
  },
  validInsuranceNo: (value)=>(value&&value.length<16)?error.insurance:undefined,
  validGender: (value)=>([0, 1, 2].includes(value))?undefined:error.required,
};


export const countries = require("./countrycodes.json");
export const getCountryCode = (label) => countries.find((e) => e.label === label)?.code;


export const breathingMasterData = {
  hauptdiagnosis: [
    "10", "11", "12", "13", "14", "15", "20", "21", "22", "23", "30", "31", "32", "33", "34", "40", "41", "42", "50", "60", "61", "62", "63", "70", "71", "72",
  ].map((e) => `diagnosis.breathingDevice.${e}`),
};

export const getBreathingDiagnosisOptionLabel = (t, option) => isNaN(option.split(".")[2]) ? t(option) : option;

import React from "react";
import {
  Button,
  ButtonGroup,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

type button={
    key:string,
    label:string,
    value:string
}
type inputType={
    value:string,
    onChange:(value:string)=> void
}
type orientationType="vertical"|"horizontal"
type SegmentedButtonProps={
    buttons:Array<button>,
    input:inputType,
    orientation:orientationType
}

export const SegmentedButton=(
    {buttons, input, orientation}:SegmentedButtonProps)=> {
  const {
    value,
    onChange,
  }=input;
  const handleOnChange=(value:string)=>onChange(value);
  const matches = useMediaQuery("(max-width:550px)");
  return (
    <ButtonGroup orientation={matches?"vertical":"horizontal"} >
      {
        buttons.map((e)=><Button
          data-cy={e.key}
          onClick={()=>handleOnChange(e.value)}
          variant={value===e.value?"contained":"outlined"}
          color="primary"
          key={e.value} size="small">

          <Typography component={"span"} noWrap variant="caption"> {e.label}</Typography>
        </Button>)
      }
    </ButtonGroup>
  );
};

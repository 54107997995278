import {Autocomplete, Box, FormControl, Grid, InputLabel, OutlinedInput, TextField, Typography} from "@mui/material";
import {useIsInViewport} from "pages/Wizard/containers/utils";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {onViewChange, isFormVisible} from "pages/Wizard/wizardSlice";
import {countries, validateEmail} from "components/formUtils";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {clearSpecificDoctortState, IDoctorState, onChangeDoctorValue, onSelectDoctor, selectDoctorValues} from "./doctorSlice";
import {ICountry} from "store/rootstate";
import {PhoneNoComponent} from "pages/Wizard/components/PhoneNo";
import {EmailComponent} from "pages/Wizard/components/Email";
import {AuthenticatedTemplate} from "@azure/msal-react";
import {selectDoctorOptions} from "store/features/masterdataSlice";


export const DoctorForm = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch=UseAppDispatch();
  const formValues = UseAppSelector(selectDoctorValues);
  const isVisibleValues = UseAppSelector(isFormVisible);
  const substitutes=UseAppSelector(selectDoctorOptions);

  const handleValueChange = (field: keyof IDoctorState["formData"], value: any) => dispatch(onChangeDoctorValue({field, value}));
  const isVisible=useIsInViewport(props.refProps);

  useEffect(()=>{
    isVisibleValues.doctor!==isVisible&&dispatch(onViewChange({field: "doctor", value: isVisible}));
  }, [isVisible]);
  return (
    <Grid ref={props.refProps} container direction="column" spacing={3} id="doctor">
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5" >
            {t(props.label)}
          </Typography>
        </Grid>
        <AuthenticatedTemplate>
          <Grid item xs={12} sm={6}>

            {Array.isArray(substitutes) && substitutes.length>1&&<Autocomplete
              options={substitutes || []}
              getOptionLabel={(item:any) => item.givenName?`${item.family_name} ${item.givenName}`:item}
              onChange={(event:any, value:any, reason:any, details:any)=>dispatch(onSelectDoctor(value))}
              id="doctorSubstitute"
              freeSolo
              autoHighlight={true}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={t("doctor.details.selectDoctor")}
                  variant="outlined"
                  data-cy="doctorSubstitute"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />}
            />}
          </Grid>
        </AuthenticatedTemplate>
        <Grid data-cy="doctor_title" item xs={12}>
          <TextField
            fullWidth
            label={t("doctor.details.title")}
            value={formValues.doctor_title||""}
            onChange={(e) => handleValueChange("doctor_title", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid data-cy="doctor_firstname" item xs={12}>
          <TextField
            fullWidth
            label={t( "doctor.details.surname")}
            value={formValues.doctor_surename||""}
            onChange={(e) => handleValueChange("doctor_surename", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid data-cy="doctor_lastname" item xs={12}>
          <TextField
            fullWidth
            value={formValues.doctor_lastname||""}
            label={t( "patient.label.lastname")}
            onChange={(e) => handleValueChange("doctor_lastname", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth data-cy="doctor_doctor_phone_no">
            <InputLabel htmlFor="doctor_phone_no">{t("patient.label.phone.no")}</InputLabel>
            <OutlinedInput
              label={t("patient.label.phone.no")}
              value={formValues.doctor_phone_no||""}
              onChange={(e) => handleValueChange("doctor_phone_no", e.target.value)}
              id="doctor_phone_no"
              inputComponent={PhoneNoComponent as any}
            />
          </FormControl>
        </Grid>
        <Grid data-cy="doctor_email_addr" item xs={12}>
          <FormControl variant="outlined" fullWidth data-cy="doctor_email_addr">
            <InputLabel htmlFor="doctor_email_addr">{t("doctor.details.email")}</InputLabel>
            <OutlinedInput
              label={t("doctor.details.email")}
              value={formValues.doctor_email_addr||""}
              onChange={(e) => handleValueChange("doctor_email_addr", e.target.value)}
              id="doctor_email_addr"
              error={!validateEmail(formValues.doctor_email_addr)}
              inputComponent={EmailComponent as any}
            />
          </FormControl>

        </Grid>
        <Grid item xs={12} data-cy="doctor_fax_no">
          <FormControl variant="outlined" fullWidth data-cy="doctor_fax_no">
            <InputLabel htmlFor="doctor_fax_no">{t("doctor.details.fax.no")}</InputLabel>
            <OutlinedInput
              label={t("doctor.details.fax.no")}
              value={formValues.doctor_fax_no||""}
              onChange={(e) => handleValueChange("doctor_fax_no", e.target.value)}
              id="doctor_fax_no"
              inputComponent={PhoneNoComponent as any}
            />
          </FormControl>

        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">{t("wizard.doctor.praxis.title")}</Typography>
        </Grid>
        <Grid item xs={12} data-cy="doctor_center_name">
          <TextField
            fullWidth
            label={t("doctor.details.hospital")}
            value={formValues.doctor_center_name||""}
            onChange={(e) => handleValueChange("doctor_center_name", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12} data-cy="doctor_specialty">
          <TextField
            fullWidth
            label={t("doctor.details.specialty")}
            value={formValues.doctor_specialty||""}
            onChange={(e) => handleValueChange("doctor_specialty", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12} data-cy="doctor_street">
          <TextField
            fullWidth
            label={t( "doctor.details.street")}
            value={formValues.doctor_street||""}
            onChange={(e) => handleValueChange("doctor_street", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12} data-cy="doctor_postalcode">
          <TextField
            name="doctorZip"
            fullWidth
            label={t( "patient.label.zip")}
            value={formValues.doctor_postalcode||""}
            onChange={(e) => handleValueChange("doctor_postalcode", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12} data-cy="doctor_city">
          <TextField
            fullWidth
            label={t("doctor.details.city")}
            value={formValues.doctor_city||""}
            onChange={(e) => handleValueChange("doctor_city", e.target.value)}
            variant="outlined" />
        </Grid>
        <Grid item xs={12} data-cy="doctor_country">
          <Autocomplete<ICountry>
            options={countries.filter((e)=>e.lang===i18n.language.toUpperCase())}
            renderOption={(props, option) => (
              <Box component="li" sx={{"& > img": {mr: 2, flexShrink: 0}}} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  // TODO_AnGh: Does Germany need to be D and not DE?
                  src={`https://flagcdn.com/w20/${option.code==="D"?"de":option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code==="D"?"de":option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.code} {option.label}
              </Box>
            )}
            getOptionLabel={(option:any)=>`${option.label}`}
            isOptionEqualToValue={(option:ICountry, value:ICountry)=>option.label===value.label||value.code===""}
            fullWidth
            autoComplete
            autoHighlight
            autoSelect
            value={formValues.doctor_country||""}
            onChange={(event, value, reason, option) => {
              reason==="selectOption"&&console.log(value);
              if (reason==="clear") {
                dispatch(clearSpecificDoctortState( {field: "doctor_country"}));
              } else {
                handleValueChange("doctor_country", value);
              }
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" label={t("patient.label.country")} />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorForm);

import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import {Grid} from "@mui/material";
import {customComponentInputType} from "../../store/rootstate";
import {useTranslation} from "react-i18next";

type TextAreaProps={
    input:customComponentInputType,
    title:string,
    touched:boolean,
    label:string,
    disabled:boolean,
    required:boolean,
    hidden:boolean,
    justify:boolean,
    minRows:number,
    meta: {error:string,
        visited:boolean}
}

export const TextArea=({
  input,
  title,
  touched,
  label,
  disabled,
  required,
  hidden,
  justify,
  minRows,
  meta: {error,
    visited}}:TextAreaProps) => {
  const {t}=useTranslation();
  return <div
    hidden={hidden}
    className="col-auto pl-0 pr-0 align-self-center">
    <Grid container>
      <Grid container item justifyContent="space-between">
        <Grid item>
          <p className="MuiFormLabel-root">{title}</p>
        </Grid>
      </Grid>
      <TextareaAutosize
        {...input}
        disabled={disabled}
        data-cy={input.name}
        style={{
          "textAlign": justify?"justify":"left",
          "textJustify": justify?"inter-word":undefined,
        }}
        className="form-control"
        minRows={minRows? minRows:1}
        required={required}
        placeholder={required? label+" *":label}
      />
      <label htmlFor="user-message" className="col-lg pl-0 text-danger">
        {error&&(touched||visited)? t(error):null}
      </label>
    </Grid>
  </div>;
};

import {
  post,
  get,
} from "../api";
import {
  receiveCommunicationHistory,
  setFilesAvailability,
  setFileStudies,
  setSelectedCommunication,
  setUnreadMessages,
} from "../features/communicationSlice";
import {startLoading, endLoading} from "store/features/loadingSlice";
import {displayToastError} from "../features/errorSlice";
import {trackEvent, trackError} from "AppInsights";


export const fetchCommunication=(selectedid)=>(dispatch)=> {
  dispatch(startLoading("communication"));
  get("communications").then((json) => {
    dispatch(receiveCommunicationHistory(json));
    if (selectedid && selectedid > 0) {
      json.forEach((element) => {
        if (element.conversation_id === selectedid) {
          dispatch(setSelectedCommunication(element));
        }
      });
    }
    dispatch(endLoading("communication"));
  }).catch( () => {
    console.error("COMMUNICATIONS_RETRIVE_UNSUCCESSFUL");
    dispatch(displayToastError("COMMUNICATIONS_RETRIVE_UNSUCCESSFUL"));
    dispatch(endLoading("communication"));
  });
};


export const showDocumentInsideCommunication=(referenceId) => (dispatch)=>{
  dispatch(startLoading("communication"));
  return get(`documents/availableDocuments/${referenceId}/`).then((documents) => {
    const promises = documents.map((file) =>
      get(`documents/download/${referenceId}/${encodeURIComponent(file.name)}/${file.fromExternalSource}`, "blob")
          .then((blob) =>
            ({file: window.URL.createObjectURL(blob), fileName: file.name}),
          ),
    );
    if (promises.length>0) {
      Promise.all(promises)
          .then((files) => {
            dispatch(setFilesAvailability(true));
            dispatch(setFileStudies(files));
          });
    } else {
      dispatch(displayToastError("NO_FILES_TO_PRINT"));
    }
  })
      .catch((error) => console.log(error))
      .finally(()=> dispatch(endLoading("communication")));
};

export const setCommunicationMessage=(messageValue, conversationId)=>(dispatch)=> {
  dispatch(startLoading("communication"));

  return post("communications", {conversationId: conversationId, message: messageValue})
      .then(() => {
        dispatch(fetchCommunication(conversationId));
      })
      .catch(() => dispatch(displayToastError("COMMUNICATIONS_RETRIVE_UNSUCCESSFUL")))
      .finally(()=> dispatch(endLoading("communication")));
};

export const setMessagesToStatusRead = (id) => async (dispatch) => {
  await get(`communications/${id}`).then((res) => {
  });
};

export const getUnreadMessages=()=>(dispatch)=>{
  trackEvent("communications/unread");
  get("communications/count").then((res)=>{
    dispatch(setUnreadMessages(res.unread));
  })
      .catch((error)=>{
        trackError(error);
        dispatch(displayToastError("GET_UNREAD_MESSAGES_UNSUCCESSFUL"));
      });
};

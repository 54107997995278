import React, {useState} from "react";
import {
  Container,
  Grid,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {ActionCardComponent} from "../components/ActionCardComponent";
import PendingInsuranceDialog from "../../../components/dialogs/PendingInsuranceDialog";

const ActionForm=(props)=>{
  const {
    pharmaEmail,
    onSaveAsDraft,
    patientAgreement,
    requestDataForInsurance,
    sendToInsurance,
    requestStudies,
    saveAsDraftAndinvitePatient,
    studyExists,
  }=props;
  const {t}=useTranslation();
  const hasEmail = Boolean(pharmaEmail);
  const [openInsuranceDialog, setOpenInsuranceDialog]=useState(false);

  return (
    <Container >
      <PendingInsuranceDialog
        isOpen={openInsuranceDialog}
        handleClose={()=>setOpenInsuranceDialog(false)}
        handleSend={(comment)=>sendToInsurance(comment)}
        requestData={requestDataForInsurance}
      />
      <Grid container justifyContent='center'>
        <ActionCardComponent
          title={t("newrequest.label.draft")}
          description= {t("newrequest.label.draft.description")}
          onClick={onSaveAsDraft}
          dataCy="saverequest"
          actionLabel = {t("newrequest.label.draft")}
        />
        <ActionCardComponent
          title= {t("newrequest.label.sendToInsurance")}
          description= {t("newrequest.label.sendToInsurance.descriptions")}
          onClick={()=>setOpenInsuranceDialog(true)}
          dataCy="sendRequestToInsurance"
          disableButton={
            !props.verified ||
            !requestDataForInsurance.patient_insurance_company || !patientAgreement}
          actionLabel = {t("newrequest.label.sendToInsurance.button")}
        />
        <ActionCardComponent
          title= {t("newrequest.label.studies")}
          description= {t("newrequest.label.studies.description")}
          onClick={()=>requestStudies()}
          dataCy="studyrequest"
          disableButton={!props.verified || !hasEmail || studyExists || !patientAgreement}
          actionLabel = {t("newrequest.label.studies")}
        />
        <div>
          <ActionCardComponent
            title= {t("newrequest.label.invite.patient")}
            description= {t("newrequest.label.invite.description")}
            onClick={()=>saveAsDraftAndinvitePatient()}
            disableButton={props.hideInvitePatient||!patientAgreement}
            dataCy="saveAsDraftAndinvitePatient"
            actionLabel = {t("newrequest.label.invite.patient")}
          />
        </div>
      </Grid>
      <br></br>
    </Container>);
};

const hideInvitePatient = (state) => {
  // new request & on edit, as the form is loaded in both cases
  const requiredDrugName = state.form?.med_data?.values?.required_drug;
  return !(requiredDrugName && requiredDrugName?.toLowerCase().indexOf("saxenda") >= 0);
};

function mapStateToProps(state) {
  return {
    hideInvitePatient: hideInvitePatient(state),
    pharmaEmail: state.form?.med_data?.values?.pharma_email,
    verified: state.userinformation.user?.groups?.includes("verified"),
    requestDataForInsurance: {
      patient_insurance_company:
      state.form.patientContact?.values?.patient_insurance_company,
      reha_data: {
        additional_insurance_company:
        state.form.reha_data?.values?.additional_insurance_company,
      },
    },
    form: {
      caseId: state.singleRequest.caseId,
      formValues: state.form,
    },
  };
}
const mapDispatchToProps=(dispatch) => ({});


export default connect(mapStateToProps, mapDispatchToProps)(ActionForm);

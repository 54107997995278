import {
  onFormChange,
  selectSections,
  selectVisibleSection,
} from "pages/Wizard/wizardSlice";
import {UseAppSelector, UseAppDispatch} from "store/hooks";
import {useTranslation} from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import {styled, Theme, CSSObject} from "@mui/material/styles";
import {Modal} from "@mui/material";
import {aiAdviceKoguRequestObject} from "../wizardSlice";
import {
  Box,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  CssBaseline,
  Stepper,
  Step,
  Grid,
  StepLabel,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RobotIcon from "@mui/icons-material/SmartToy";
import SupportIcon from "@mui/icons-material/Support";
import MuiDrawer from "@mui/material/Drawer";
import {LinearProgressBar} from "../forms/overview/components/LinearProgressBar";
import AIAssistent from "../components/AIAssistent";
import Stack from "@mui/material/Stack";
import WarningIcon from "@mui/icons-material/Warning";
import {AuthenticatedTemplate} from "@azure/msal-react";
import {useTheme} from "@mui/styles";
import {useParams} from "react-router-dom";
const drawerWidth = "300px";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",

});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },

});

const DrawerHeader = styled("div")(({theme}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== "open"})(
    ({theme, open}) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      position: "relative",
      boxSizing: "border-box",
      overflowX: "hidden",
      ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      }),
    }),
);

export const WizardNavigation = (props) => {
  const sections = UseAppSelector(selectSections);
  const visibleSection=UseAppSelector(selectVisibleSection);
  const {t} = useTranslation();
  const dispatch = UseAppDispatch();
  const isVerified = props.verified === true;
  const isLargeScreen = useMediaQuery( "(max-width:900px)");

  const [open, setOpen] = React.useState(false);
  const [refList, setRefList] = React.useState([]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const scrollToTargetAdjusted=(element)=>{
    console.debug("scrolling to:", element);
    const headerOffset = 30;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  const {sectionToScroll}=useParams<{sectionToScroll:string;}>();

  const handleFormClick=(formData:{section:string;form:string;})=>{
    Promise.all([
      dispatch(onFormChange(formData)),
    ]).then(()=> {
      console.debug("scrolling to:", formData.form);
      const elem = document.getElementById(formData.form);
      console.debug("scrolling to:", elem);
      scrollToTargetAdjusted(elem);
    });
  };

  useEffect(() => {
    isLargeScreen&&handleDrawerClose();
    !isLargeScreen&&handleDrawerOpen();
  }, [isLargeScreen]);

  useEffect(()=>{
    console.debug("sectionToScroll", sectionToScroll);
    const sectionToScrollInt=sectionToScroll&&parseInt(sectionToScroll);
    if (sectionToScrollInt&&refList[sections[sectionToScrollInt].forms[0].id]) {
      handleFormClick({section: sections[sectionToScrollInt].id, form: sections[sectionToScrollInt].forms[0].id});
    }
  }, [sectionToScroll, refList]);

  const theme = useTheme<any>();


  const [openAI, setOpenAI] = React.useState(false);
  const [openAIHints, setOpenAIHints] = React.useState(false);
  const handleClickOpenAI = () => {
    setOpenAI(true);
  };
  const handleCloseAI = () => {
    setOpenAI(false);
  };
  const handleClickOpenAIHints = () => {
    dispatch(aiAdviceKoguRequestObject({}));
    setOpenAIHints(true);
  };
  const handleCloseAIHints = () => {
    setOpenAIHints(false);
  };


  return (
    <Box sx={{display: "flex"}}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{sx: {backgroundColor: "#e8f4ec", height: "calc(100% - 49px)", top: 49}}}>
        {isLargeScreen&&<DrawerHeader>


          <IconButton onClick={open?handleDrawerClose:handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>}

        {open&&<><Grid item xs={12} padding='12px'>
          <Typography variant="h6" sx={{fontWeight: "bold", paddingBottom: "0.5em"}}>
            {t("wizard.functions")}
          </Typography>
          {<LinearProgressBar/>}
        </Grid>
        {open&&isVerified&&<>
          <Grid item xs={12} padding="12px" paddingLeft="20px">
            <Box display="flex" width="100%" gap="12px" flexDirection="column" alignItems="flex-start">
              <Link
                fontWeight='bold'
                fontSize='large'
                component="button"
                onClick={handleClickOpenAI}
                style={{display: "flex", alignItems: "center", minWidth: "220px", textDecoration: "none", color: "inherit"}}
                underline="none"
                variant="body1"
                onMouseOver={(e) => e.target.style.color = "#1976d2"}
                onMouseOut={(e) => e.target.style.color = "inherit"}
              >
                <Box display="flex" alignItems="center">
                  <RobotIcon style={{marginRight: "8px", color: "#63c2de"}} />
                  {t("aiassistent.open")}
                </Box>
              </Link>
              <Link
                fontWeight='bold'
                fontSize='large'
                component="button"
                onClick={handleClickOpenAIHints}
                style={{display: "flex", alignItems: "center", minWidth: "220px", textDecoration: "none", color: "inherit"}}
                underline="none"
                variant="body1"
                onMouseOver={(e) => e.target.style.color = "#1976d2"}
                onMouseOut={(e) => e.target.style.color = "inherit"}
              >
                <Box display="flex" alignItems="center">
                  <SupportIcon style={{marginRight: "8px", color: "#63c2de"}} />
                  {t("aiassistent.hints")}
                </Box>
              </Link>
            </Box>
            <AIAssistent open={openAI} onClose={handleCloseAI} wizard={props.wizard} />
          </Grid>
          {open&&<Divider />}
        </>}

        {<Divider />}
        </>}


        <List>
          {sections.map((section, index)=>(
            <ListItem key={section.id} disablePadding sx={{display: "block"}}>
              <ListItemButton
                data-cy={`section_navigation_${section.id}`}
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  cursor: "pointer",
                }}
                onClick={()=>handleFormClick({section: section.id, form: section.forms[0].id})}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {<section.icon fontSize='large' color={visibleSection===section.id?"primary":"lightgray"}/>}
                </ListItemIcon>
                <ListItemText disableTypography primary={`${t(section.label)}`} sx={{opacity: open ? 1 : 0, fontWeight: "bold", fontSize: "1.25em"}} />
              </ListItemButton>
              {visibleSection===section.id&&<Stepper
                sx={{paddingLeft: "4em",
                  cursor: "pointer"}} orientation="vertical">
                {open&&section.forms.map((form)=>
                  form.id === "uploadDocument" ? (
                    <AuthenticatedTemplate key={form.label} >
                      <Step
                        data-cy={`step_navigation_${form.id}`}
                        onClick={()=>handleFormClick({section: section.id, form: form.id})}>
                        <StepLabel
                        // StepIconComponent={Circle || ""}
                          componentsProps={{label: {style: {cursor: "pointer"}}}}
                          StepIconProps={
                            {sx:
                            {padding: "0.25em", color: theme.palette.secondary.main}, completed: undefined, active: undefined, error: undefined}}>
                          <Typography sx={{paddingLeft: "0em",
                            textDecoration: "underline",
                            color: theme.palette.secondary.main}}>
                            {`${t(form.label)}`}
                          </Typography>
                        </StepLabel>
                      </Step>
                    </AuthenticatedTemplate>):
                  (<Step key={form.label}
                    data-cy={`step_navigation_${form.id}`}
                    onClick={()=>handleFormClick({section: section.id, form: form.id})}>
                    <StepLabel
                      // StepIconComponent={Circle || ""}
                      componentsProps={{label: {style: {cursor: "pointer"}}}}
                      StepIconProps={{sx: {padding: "0.25em", color: theme.palette.secondary.main}, completed: undefined, active: undefined, error: undefined}}>
                      <Typography sx={{paddingLeft: "0em", textDecoration: "underline", color: theme.palette.secondary.main}}>
                        {`${t(form.label)}`}
                      </Typography>
                    </StepLabel>
                  </Step>),
                )}
              </Stepper>}
            </ListItem>
          ))}
        </List>

      </Drawer>
      <Box component="main" sx={{flexGrow: 1, p: 3, padding: "0px"}}>
        {React.cloneElement(props.children, {setRefList: ((refList)=>setRefList(refList))})}
      </Box>

      <Modal
        open={openAIHints}
        onClose={handleCloseAIHints}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          borderColor: theme.palette.primary.main, // Use the primary color from the theme
          p: 4,
        }}>
          {props.loading.loadingAiHint ? (
            <CircularProgress
              sx={{
                display: "block",
                margin: "0 auto",
              }}
            />
          ) : (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("aiassistent.hint.title")}
              </Typography>

              <Typography id="modal-modal-description" sx={{mt: 2}}>
                {props.aihint || t("aiassistent.hint.default")}
              </Typography>
              <Box sx={{m: 1}}/>
              <Stack
                direction="row"
                alignItems="left"
                gap={1}
                hidden= {(props.studiesCount === 0 ) }
              >
                <WarningIcon color={"primary"}></WarningIcon>
                <Typography>{t("success.ai.studies_found")}</Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="left"
                gap={1}
                hidden= {(props.studiesCount > 0 === false ) }
              >
                <WarningIcon color={"warning"}></WarningIcon>
                <Typography>{t("aiassistent.hint.disclaimer")}</Typography>
              </Stack>


            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};


const mapStateToProps = (state) => {
  return {
    verified: state.userinformation.user?.groups?.includes("verified"),
    wizard: state.wizard,
    aihint: state.wizard.wizardModel.aiAdvice,
    loading: state.wizard.wizardModel.loading,
    studiesCount: state.masterdata.pubMedData?.length || 0,
  };
};


const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WizardNavigation);

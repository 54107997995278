import React, {
  useState, ChangeEvent, useEffect,
} from "react";
import {connect} from "react-redux";
import {
  TextField,
  TablePagination,
  CircularProgress,
  Grid,
  Button,
  Typography,
  InputAdornment,
} from "@mui/material";
import {
  onAddOrRemoveStudy,
  markStudiesForRemoval} from "store/features/newRequestSlice";
import {
  fetchPubMedData,
  fetchPubmedAbstract,
} from "store/middleware/masterdataThunk";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {studyRowType} from "../../../../store/rootstate";
import {RootState} from "store/rootstate";
import {useTranslation} from "react-i18next";
import {PubMedCard} from "components/PubMedCard";
import {onViewChange, isFormVisible} from "pages/Wizard/wizardSlice";
import {useIsInViewport} from "pages/Wizard/containers/utils";
import SearchIcon from "@mui/icons-material/Search";

const translationKey="upload.pubmed.";

const PubMedComponent = (props)=> {
  const dispatch = UseAppDispatch();
  const [page, setPage] = useState(0);
  const isVisibleValues = UseAppSelector(isFormVisible);
  const {t}=useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    addedStudyId,
    pubMedList,
    isLoading,
    abstractContent,
    isLoadingAbstract,
    JWTToken,
    currentStudy,
    abstract,
    refProps,
  }=props;
  const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
    setPage(newPage);
  };
  const handlePubMedSearch = () => dispatch(fetchPubMedData(searchQuery, JWTToken));
  const handleFetchAbstract = (id:number) => {
    dispatch(fetchPubmedAbstract(id, JWTToken));
  };
  const handleAddStudy = (studyData:studyRowType) => dispatch(onAddOrRemoveStudy(studyData));

  const handleRemoveStudy = (rowData) => {
    Boolean(rowData.study_id)&&dispatch(markStudiesForRemoval(rowData.study_id));
    dispatch(onAddOrRemoveStudy(rowData));
  };
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(()=>{
  }, [currentStudy]);

  const isVisible=useIsInViewport(refProps);
  useEffect(()=>{
    isVisibleValues.uploadStudy!==isVisible&&dispatch(onViewChange({field: "uploadStudy", value: isVisible}));
  }, [isVisible]);
  return (
    <Grid ref={refProps} container direction="column" spacing={1} marginTop='3px' id="uploadStudy">
      <Grid item container direction="row" justifyContent="space-between" spacing={2}>
        <Grid item style={{"borderLeft": "7px solid #63c2de", "paddingTop": 0}}>
          <Typography variant="h5" >
            {t(props.label)}
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={10}>


            <TextField
              inputProps={{"data-cy": "pubmedSearchInput"}}
              placeholder={t("pubmed.search.placeholder")}
              label={t("pubmed.search.placeholder")}
              fullWidth
              onChange={({target}) => setSearchQuery(target.value)}
              variant="outlined"
              onKeyPress={(e) => e.key === "Enter"&&handlePubMedSearch()}


              InputProps={{
                readOnly: isLoading,
                endAdornment: (
                  <React.Fragment>
                    {isLoading === true ? <CircularProgress
                      color="inherit" size={20} /> : null}

                  </React.Fragment>
                ),
                startAdornment: ( <InputAdornment position="start"> <SearchIcon />
                </InputAdornment> )}}
            />


          </Grid>

          <Grid item container xs={2} justifyContent='flex-end'>
            <Button
              disabled={ isLoading }
              data-cy="pubmedSearchButton"
              onClick={handlePubMedSearch}
              variant="contained">{t(`${translationKey}search`)}
            </Button>
          </Grid>

        </Grid>
        <Grid item xs={12}>
          {Boolean(currentStudy?.pmid)&&<PubMedCard
            title={t("upload.title.current")}
            rowData={currentStudy}
            onRemoveStudy={handleRemoveStudy}
            handleFetchAbstract={handleFetchAbstract}
            abstractContent={abstract.content?.[currentStudy?.pmid]}
            isLoading={abstract.isLoading}
          />}
        </Grid>
      </Grid>

      {!isLoading && pubMedList?.length>0?
        <Grid item container xs={12} spacing={3} justifyContent='center'>
          {pubMedList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((e: studyRowType)=><Grid item xs={12} key={e?.pmid}>
                <PubMedCard
                  rowData={e}
                  onAddOrRemoveStudy={handleAddStudy}
                  addedStudyId={addedStudyId}
                  handleFetchAbstract={handleFetchAbstract}
                  abstractContent={abstractContent?.[e?.pmid]}
                  isLoading={isLoadingAbstract}
                />
              </Grid>)
          }
          <TablePagination
            component= {Grid}
            rowsPerPageOptions={[10, 25, 100]}
            count={pubMedList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid> :
        <Grid item alignItems="center">
          <Typography>{t(`${translationKey}notice`)}</Typography>
        </Grid>
      }
    </Grid>
  );
};

const mapStateToProps = (state:RootState) => ({
  isLoadingAbstract: Boolean(state.loading.pubmedAbstract),
  isLoading: state.loading.pubMed === true,
  pubMedList: state.masterdata.pubMedData,
  addedStudyId: state.singleRequest?.pubmedStudy?.pmid,
  abstractContent: state.masterdata.pubMedAbstracts,
  currentStudy: state.singleRequest.pubmedStudy,
  abstract: {
    isLoading: Boolean(state.loading.pubmedAbstract),
    content: state.masterdata.pubMedAbstracts,
  },

});
const connector = connect(mapStateToProps);
//   type PropsFromRedux = ConnectedProps<typeof connector>;

//   interface Props extends PropsFromRedux {
//     isLoading: boolean;
//     pubMedList: studyRowType[];
//     JWTToken:string
//   }

// @ts-ignore
export default connector(PubMedComponent);


import React, {useEffect} from "react";
import {Modal, Box, Typography, Grid, Stack, IconButton, Button, useMediaQuery} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {saveRequestBodyToSession} from "pages/Wizard/wizardSlice";
import {login} from "./../../../../../config/msalProvider";
import {UseAppDispatch} from "store/hooks";


export const AccountCreate = ({open}: { open: boolean }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const {t}=useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch=UseAppDispatch();

  const handleCreateAccount = ()=>{
    Promise.all([
      dispatch(saveRequestBodyToSession({ }) ),
    ]).then((res)=>{
      login();
    });
  };
  useEffect(() => {
    if (open) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [open]);

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? 400 : 800,
          bgcolor: "#fff",
          border: "2px solid #c8ced3",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t("newrequest.modal.hint")}
        </Typography>
        <Typography id="modal-modal-description" sx={{mt: 2}}>
          {t("newrequest.modal.text")}
        </Typography>

        <Box sx={{padding: "8px"}}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Box sx={{flex: 1, padding: "8px"}}>
                    <Typography>{t("newrequest.modal.text1")}</Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      padding: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon color="success" sx={{fontSize: 40}} />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box sx={{flex: 1, padding: "8px"}}>
                    <Typography>{t("newrequest.modal.text2")}</Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      padding: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon color="success" sx={{fontSize: 40}} />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box sx={{flex: 1, padding: "8px"}}>
                    <Typography>{t("newrequest.modal.text3")}</Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      padding: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon color="success" sx={{fontSize: 40}} />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box sx={{flex: 1, padding: "8px"}}>
                    <Typography>{t("newrequest.modal.text4")}</Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      padding: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon color="success" sx={{fontSize: 40}} />
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Box sx={{flex: 1, padding: "8px"}}>
                    <Typography>{t("newrequest.modal.text5")}</Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      padding: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon color="success" sx={{fontSize: 40}} />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box sx={{flex: 1, padding: "8px"}}>
                    <Typography>{t("newrequest.modal.text6")}</Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      padding: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon color="success" sx={{fontSize: 40}} />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box sx={{flex: 1, padding: "8px"}}>
                    <Typography>{t("newrequest.modal.text7")}</Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      padding: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon color="success" sx={{fontSize: 40}} />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box sx={{flex: 1, padding: "8px"}}>
                    <Typography>{t("newrequest.modal.text8")}</Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      padding: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon color="success" sx={{fontSize: 40}} />
                  </Box>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Button sx={{mt: 2}} onClick={handleCreateAccount}>
          {t("newrequest.label.createAccount")}
        </Button>
      </Box>
    </Modal>
  );
};

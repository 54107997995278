
export const formatDateDisplay = (dateString) => {
  const dateParts = dateString ? dateString.split("T")[0].split("-") : null;
  return dateParts ? `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}` : "";
};
import React from "react";

export const formatToTwoDecimal = (value) =>
  value.split(".")[1]?.length>2 ?
        parseFloat(value).toFixed(2):
        value;


export const formatDate = (date) => {
  const d = new Date(date);
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const year = d.getFullYear();
  return [year, month, day].join("-");
};
export const formatDateGerman = (date) => {
  if (date && new Date(date)) {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return [day, month, year].join(".");
  } else {
    return undefined;
  }
};


export const formatTimeDifference = (t, timestamp) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = new Date() - new Date(timestamp) +
  (new Date().getTimezoneOffset() * 60 * 1000);
  const prefixString = t("notification.time.prefix");
  if (elapsed < msPerMinute) {
    return prefixString + Math.round(elapsed / 1000) + t("notification.time.seconds");
  } else if (elapsed < msPerHour) {
    return prefixString + Math.round(elapsed / msPerMinute) + t("notification.time.minutes");
  } else if (elapsed < msPerDay) {
    return prefixString + Math.round(elapsed / msPerHour) + t("notification.time.hours");
  } else if (elapsed < msPerMonth) {
    return prefixString + Math.round(elapsed / msPerDay) + t("notification.time.days");
  } else if (elapsed < msPerYear) {
    return prefixString + Math.round(elapsed / msPerMonth) + t("notification.time.months");
  } else {
    return prefixString + Math.round(elapsed / msPerYear) + t("notification.time.years");
  }
};

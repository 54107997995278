import React from "react";
import TextField from "@mui/material/TextField";
import {useTranslation} from "react-i18next";

type TextFieldComponentProps={
    input:{ name:string},
    touched:boolean,
    label:string,
    required:boolean,
    type:React.HTMLInputTypeAttribute,
    floating:boolean,
    numericProps:any,
    disabled:boolean,
    center:boolean,
    meta: {error:string,
        visited:boolean}
}

export const TextFieldComponent=({
  input,
  touched,
  label,
  required,
  type,
  floating,
  numericProps,
  disabled,
  center,
  meta: {visited,
    error},
}:TextFieldComponentProps) => {
  const {t}=useTranslation();
  return <TextField
    variant="standard"
    {...input}
    fullWidth
    // is this still relevant?
    autoFocus={input.name==="doctor_surename"}
    style={{maxWidth: center?"80px":"auto"}}
    inputProps={{
      ...numericProps,
      "data-cy": input.name,
      // autoComplete: "new-password",
      "style": {
        "color": floating?"#63c2de":"inherit",
        "textAlign": center?"center":"inherit",
      },
    }}
    type={type}
    disabled={disabled}
    required={required}
    label={label}
    error={(touched||visited)&&t(error)? true:false}
    helperText={(touched||visited)&&t(error)? t(error):null} />;
};

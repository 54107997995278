import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import {TextArea} from "pages/Wizard/components/TextAreaComponent";
import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers/";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {
  RadioButtonUnchecked,
  RadioButtonChecked,
} from "components/icons";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {onChangePraluentValue, selectPraluentValues} from "./praluentSlice";

import {calendarLanguage, calendarMask} from "pages/Wizard/containers/utils";
import {correctDateFormat} from "components/formUtils";

const PraluentForm=(props) => {
  const {t, i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();

  const praluentValues=UseAppSelector(selectPraluentValues);
  const dispatch=UseAppDispatch();
  const handleValueChange = (field:string, value:any)=>dispatch(onChangePraluentValue({field, value}));


  return <>
    <Grid item container xs={12} spacing={2}>
      <Grid item>
        <Typography fontWeight='bold'>{t("praluent_data_desctiption")}</Typography>
      </Grid>
      <Grid item xs={12} data-cy="chk_praluent_first">
        <FormControlLabel
          control={
            <Checkbox
              name="ersteverordnung"
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
              checked={praluentValues.ersteverordnung}
              onChange={(event, newValue)=> {
                handleValueChange("pcsk", !newValue);
                handleValueChange("ersteverordnung", newValue);
              }}/>
          }
          label={t("praluent.ersteverordnung")}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="pcsk"
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
              checked={praluentValues.pcsk}
              onChange={(event, newValue)=> {
                handleValueChange("pcsk", newValue);
                handleValueChange("ersteverordnung", !newValue);
              }}/>
          }
          label={t("praluent.pcsk")}/>
      </Grid>
      {praluentValues.ersteverordnung?<>
        <Grid item container xs={12} spacing={1} data-cy="txt_ldlCWert_before">
          <Grid item xs={12}>
            <Typography fontWeight='bold'>{t("anamnese")}</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label={t("ldl.c.wert.before")}
              fullWidth
              type='number'
              value={praluentValues.ldlCWert_before||""}
              onChange={(event)=>handleValueChange("ldlCWert_before", event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} data-cy="date_praluent_measurement_date_before">
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>
              <DatePicker
                label={t("praluent.measurement.date")}
                mask={calendarMask(currentLang)}
                value={praluentValues.praluent_measurement_date_before}
                onChange={(newValue) => {
                  handleValueChange("praluent_measurement_date_before", correctDateFormat(newValue));
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item container xs={12} data-cy="chk_praluent_ldlc_26">
          <Grid item xs={12}>
            <Typography>{t("secundarprevention_descritpion")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="ldlc_26"
                  checked={praluentValues.ldlc_26}
                  onChange={(event, newValue)=>{
                    handleValueChange("ldlc_45", false);
                    handleValueChange("ldlc_50", false);
                    handleValueChange("ldlc_26", event.target.checked);
                    if (newValue) {
                      handleValueChange("diabetes", false);
                      handleValueChange("increased_lipoprotein", false);
                      handleValueChange("hypertension", false);
                    }
                  }}/>
              }
              label={t("praluent.ldlc26")}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("primaryprevention_descritpion")}</Typography>
          </Grid>
          <Grid item xs={12} data-cy="chk_praluent_ldlc_50">
            <FormControlLabel
              control={
                <Checkbox
                  name="ldlc_50"
                  checked={praluentValues.ldlc_50}
                  onChange={(event, newValue)=>{
                    handleValueChange("ldlc_45", false);
                    handleValueChange("ldlc_26", false);
                    handleValueChange("ldlc_50", event.target.checked);
                    if (newValue) {
                      handleValueChange("diabetes", false);
                      handleValueChange("increased_lipoprotein", false);
                      handleValueChange("hypertension", false);
                    }
                  }}/>
              }
              label={t("praluent.ldlc50")}
            />
          </Grid>
          <Grid item xs={12} data-cy="chk_praluent_ldlc_45">
            <FormControlLabel
              control={
                <Checkbox
                  name="ldlc_45"
                  checked={praluentValues.ldlc_45}
                  onChange={(event, newValue)=>{
                    handleValueChange("ldlc_26", false);
                    handleValueChange("ldlc_50", false);
                    handleValueChange("ldlc_45", event.target.checked);
                    if (!newValue) {
                      handleValueChange("diabetes", false);
                      handleValueChange("increased_lipoprotein", false);
                      handleValueChange("hypertension", false);
                    }
                  }}/>
              }
              label={t("praluent.ldlc45")}
            />
          </Grid>
          <Grid item xs={12} paddingLeft={3} data-cy="chk_praluent_diabetes">
            <FormControlLabel
              control={
                <Checkbox
                  name="diabetes"
                  disabled={!praluentValues.ldlc_45}
                  checked={praluentValues.diabetes}
                  onChange={(event)=>{
                    handleValueChange("diabetes", event.target.checked);
                  }}/>
              }
              label={t("praluent.diabetes")}
            />
          </Grid>
          <Grid item xs={12} paddingLeft={3} data-cy="chk_praluent_increased_lipoprotein">
            <FormControlLabel
              control={
                <Checkbox
                  name="increased_lipoprotein"
                  disabled={!praluentValues.ldlc_45}
                  checked={praluentValues.increased_lipoprotein}
                  onChange={(event)=>{
                    handleValueChange("increased_lipoprotein", event.target.checked);
                  }}/>
              }
              label={t("praluent.increased.lipoprotein")}
            />
          </Grid>
          <Grid item xs={12} paddingLeft={3} data-cy="chk_praluent_hypertension">
            <FormControlLabel
              control={
                <Checkbox
                  name="hypertension"
                  disabled={!praluentValues.ldlc_45}
                  checked={praluentValues.hypertension}
                  onChange={(event)=>{
                    handleValueChange("hypertension", event.target.checked);
                  }}/>
              }
              label={t("praluent.hypertension")}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} data-cy="chk_praluent_treatment_diet">
          <Grid item xs={12}>
            <Typography fontWeight='bold'>{t("vortherapie")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="treatment_diet"
                  checked={praluentValues.treatment_diet}
                  onChange={(event)=>{
                    handleValueChange("treatment_diet", event.target.checked);
                  }}/>
              }
              label={t("praluent.treatment.diet")}
            />
          </Grid>
          <Grid item xs={12} data-cy="chk_praluent_treatment_statins">
            <FormControlLabel
              control={
                <Checkbox
                  name="treatment_statins"
                  checked={praluentValues.treatment_statins}
                  onChange={(event, newValue)=>{
                    handleValueChange("treatment_statins", event.target.checked);
                    newValue&&handleValueChange("treatment_ezetimibe", false);
                  }}/>
              }
              label={t("praluent.treatment.statins")}
            />

          </Grid>
          <Grid item xs={12} data-cy="chk_praluent_treatment_ezetimibe">
            <FormControlLabel
              control={
                <Checkbox
                  name="treatment_ezetimibe"
                  checked={praluentValues.treatment_ezetimibe}
                  onChange={(event, newValue)=>{
                    handleValueChange("treatment_ezetimibe", event.target.checked);
                    newValue&&handleValueChange("treatment_statins", false);
                  }}/>
              }
              label={t("praluent.treatment.ezetimibe")}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} >
          <Grid item xs={12} >
            <Typography>{t("at.lest.3months.description")}</Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography>{t("intolerance.description")}</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} alignContent='center' spacing={3}>
          <Grid item container xs={12}>
            <Grid item xs={12} data-cy="txt_praluent_statin1">
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="praluent_statin1">{t("praluent.statin1")} </InputLabel>
                <OutlinedInput
                  label={t("praluent.statin1")}
                  value={praluentValues.statin1||""}
                  style={{width: "100%"}}
                  inputProps={{minRows: 7}}
                  onChange={(event)=>handleValueChange("statin1", event.target.value)}
                  id="praluent_statin1"
                  inputComponent={TextArea as any}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Typography>{t("statin.tolerance")}</Typography>
            <Grid item xs={12} data-cy="chk_praluent_myalgia_statin1">
              <FormControlLabel
                control={
                  <Checkbox
                    name="myalgia_statin1"
                    checked={praluentValues.myalgia_statin1}
                    onChange={(event)=>{
                      handleValueChange("myalgia_statin1", event.target.checked);
                    }}/>
                }
                label={t("praluent.myalgia")}
              />
            </Grid>
            <Grid item xs={12} data-cy="chk_praluent_rise_ck">
              <FormControlLabel
                control={
                  <Checkbox
                    name="rise_ck"
                    checked={praluentValues.rise_ck}
                    onChange={(event)=>{
                      handleValueChange("rise_ck", event.target.checked);
                    }}/>
                }
                label={t("praluent.rise.ck")}
              />

            </Grid>
            <Grid item xs={12} data-cy="chk_praluent_severe_hepatopathy">
              <FormControlLabel
                control={
                  <Checkbox
                    name="severe_hepatopathy"
                    checked={praluentValues.severe_hepatopathy}
                    onChange={(event)=>{
                      handleValueChange("severe_hepatopathy", event.target.checked);
                    }}/>
                }
                label={t("praluent.severe.hepatopathy")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} alignContent='center' spacing={3} >
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12} data-cy="txt_praluent_statin2">
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="praluent_statin2 ">{t("praluent.statin2")} </InputLabel>
                <OutlinedInput
                  label={t("praluent.statin2")}
                  value={praluentValues.statin2||""}
                  style={{width: "100%"}}
                  inputProps={{minRows: 4}}
                  onChange={(event)=>handleValueChange("statin2", event.target.value)}
                  id="praluent_statin2"
                  inputComponent={TextArea as any}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container xs={5} data-cy="chk_praluent_myalgia_statin2">
            <Typography>{t("statin.tolerance")}</Typography>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="myalgia_statin2"
                    checked={praluentValues.myalgia_statin2}
                    onChange={(event)=>{
                      handleValueChange("myalgia_statin2", event.target.checked);
                    }}/>
                }
                label={t("praluent.myalgia")}
              />

            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} >
          <Grid item xs={12}>
            <Typography fontWeight='bold'>{t("risck.factors.description")}</Typography>
          </Grid>
          <Grid item xs={12} data-cy="chk_praluent_blood_preasure">
            <FormControlLabel
              control={
                <Checkbox
                  name="blood_preasure"
                  checked={praluentValues.blood_preasure}
                  onChange={(event)=>{
                    handleValueChange("blood_preasure", event.target.checked);
                  }}/>
              }
              label={t("praluent.blood.preasure")}
            />
          </Grid>
          <Grid item xs={12} data-cy="chk_praluent_hba1c">
            <FormControlLabel
              control={
                <Checkbox
                  name="hba1c"
                  checked={praluentValues.hba1c}
                  onChange={(event)=>{
                    handleValueChange("hba1c", event.target.checked);
                  }}/>
              }
              label={t("praluent.hba1c")}
            />
          </Grid>
          <Grid item xs={12} data-cy="chk_praluent_nicotine_abstinence">
            <FormControlLabel
              control={
                <Checkbox
                  name="nicotine_abstinence"
                  checked={praluentValues.nicotine_abstinence}
                  onChange={(event)=>{
                    handleValueChange("nicotine_abstinence", event.target.checked);
                  }}/>
              }
              label={t("praluent.nicotine.abstinence")}
            />
          </Grid>
        </Grid></>:null}
      {praluentValues.pcsk? <>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Typography fontWeight='bold'>{t("success.controll.3monts.description")}</Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography>{t("treatment.allowed.continued..description")}</Typography>
          </Grid>
          <Grid item container xs={12} data-cy="txt_praluent_ldlCWert_during">
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label={t("ldl.c.wert.during")}
                fullWidth
                value={praluentValues.ldlCWert_during||""}
                onChange={(event)=>handleValueChange("ldlCWert_during", event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} >
            <Grid item xs={4} data-cy="date_praluent_measurement_date_during">
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLanguage(currentLang)}>
                <DatePicker
                  label={t("praluent.measurement.date")}
                  value={praluentValues.praluent_measurement_date_during}
                  onChange={(newValue) => {
                    handleValueChange("praluent_measurement_date_during", correctDateFormat(newValue));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} data-cy="chk_praluent_ldlc_reduction">
            <FormControlLabel
              control={
                <Checkbox
                  name="ldlc_reduction"
                  checked={praluentValues.ldlc_reduction}
                  onChange={(event)=>{
                    handleValueChange("ldlc_reduction", event.target.checked);
                  }}/>
              }
              label={t("praluent.ldlc.reduction")}
            />
          </Grid>
          <Grid item xs={12} md={5} data-cy="chk_praluent_value_reached">
            <FormControlLabel
              control={
                <Checkbox
                  name="value_reached"
                  checked={praluentValues.value_reached}
                  onChange={(event)=>{
                    handleValueChange("value_reached", event.target.checked);
                  }}/>
              }
              label={t("praluent.value.reached")} // LDL-C Wert <1.8 mmol/L erreicht
            />
          </Grid>
        </Grid>
      </>:null}
    </Grid>
  </>;
};

const mapStateToProps=(state) => {
  return {

  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PraluentForm);



import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface ImfinziState {
  formData: {
    default: boolean,
    topaz: boolean,
    aegean: boolean,
    lungcarcinoma: boolean,
    poseidon: boolean,
    pacific: boolean,
    caspian: boolean,
    adriatic: boolean,
    formAegean: {
      pdl1_0: boolean,
      iIIAN2: boolean,
      iIIAN3: boolean,
      recidivism: string,
    },
    himalaya: boolean,
    defaultForm: {
      preTreatment: string,
      patientCondition: string,
      reasonsAlternativeTherapiesFailed: string,
      prognosis: string,
    }
  }
}

const initialState:ImfinziState = {
  formData: {
    himalaya: false,
    default: false,
    topaz: false,
    aegean: false,
    lungcarcinoma: false,
    poseidon: false,
    pacific: false,
    caspian: false,
    adriatic: false,
    formAegean: {
      pdl1_0: false,
      iIIAN2: false,
      iIIAN3: false,
      recidivism: "",
    },
    defaultForm: {
      preTreatment: "",
      patientCondition: "",
      reasonsAlternativeTherapiesFailed: "",
      prognosis: "",
    },
  },
};

const ImfinziFormSlice = createSlice({
  name: "Imfinzi",
  initialState,
  reducers: {
    onReceiveSpecialMedImfinziEditData: (state, {payload}: PayloadAction<any>) => {
      console.debug("onReceiveSpecialMedImfinziEditData", payload);

      if (payload?.formData?.topaz === true) {
        state.formData.topaz = true;
      }
      if (payload?.formData?.lungcarcinoma === true) {
        state.formData.lungcarcinoma = true;
      }
      if (payload?.formData?.default === true) {
        state.formData.default = true;
      }
      if (payload?.formData?.himalaya === true) {
        state.formData.himalaya = true;
      }
      if (payload?.formData?.poseidon === true) {
        state.formData.poseidon = true;
      }
      if (payload?.formData?.pacific === true) {
        state.formData.pacific = true;
      }
      if (payload?.formData?.caspian === true) {
        state.formData.caspian = true;
      }
      if (payload?.formData?.aegean === true) {
        state.formData.aegean = true;
      }
      if (payload?.formData?.adriatic === true) {
        state.formData.adriatic = true;
      }

      if (payload?.formData?.formAegean) {
        state.formData.formAegean.pdl1_0 = payload.formData.formAegean.pdl1_0;
        state.formData.formAegean.iIIAN2 = payload.formData.formAegean.iIIAN2;
        state.formData.formAegean.iIIAN3 = payload.formData.formAegean.iIIAN3;
        state.formData.formAegean.recidivism = payload.formData.formAegean.recidivism;
      }
      if (payload.formData?.defaultForm) {
        state.formData.defaultForm.preTreatment =
          payload.formData?.defaultForm?.preTreatment;
        state.formData.defaultForm.patientCondition =
          payload.formData?.defaultForm?.patientCondition;
        state.formData.defaultForm.reasonsAlternativeTherapiesFailed =
          payload.formData?.defaultForm?.reasonsAlternativeTherapiesFailed;
        state.formData.defaultForm.prognosis =
          payload.formData?.defaultForm?.prognosis;
      }
    },

    onClearImfinziState: (state)=>{
      console.debug("onClearImfinziState");
      state.formData=initialState.formData;
    },

    onChangeImfinziValue: (state, {payload}:PayloadAction<any>)=>{
      if (payload.preTreatment || payload.preTreatment === "") state.formData.defaultForm.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.defaultForm.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.defaultForm.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.defaultForm.prognosis = payload.prognosis;

      if (payload.lungcarcinoma === "aegean") {
        state.formData.aegean = true;
        state.formData.poseidon = false;
        state.formData.pacific = false;
        state.formData.caspian = false;
        state.formData.adriatic = false;
      } else if (payload.lungcarcinoma === "poseidon") {
        state.formData.poseidon = true;
        state.formData.aegean = false;
        state.formData.pacific = false;
        state.formData.caspian = false;
        state.formData.adriatic = false;
        state.formData.formAegean = initialState.formData.formAegean;
      } else if (payload.lungcarcinoma === "pacific") {
        state.formData.pacific = true;
        state.formData.aegean = false;
        state.formData.poseidon = false;
        state.formData.caspian = false;
        state.formData.adriatic = false;
        state.formData.formAegean = initialState.formData.formAegean;
      } else if (payload.lungcarcinoma === "caspian") {
        state.formData.caspian = true;
        state.formData.aegean = false;
        state.formData.poseidon = false;
        state.formData.pacific = false;
        state.formData.adriatic = false;
        state.formData.formAegean = initialState.formData.formAegean;
      } else if (payload.lungcarcinoma === "adriatic") {
        state.formData.caspian = false;
        state.formData.aegean = false;
        state.formData.poseidon = false;
        state.formData.pacific = false;
        state.formData.adriatic = true;
        state.formData.formAegean = initialState.formData.formAegean;
      }
      if (payload.choice === "default") {
        state.formData.default = true;
        state.formData.topaz = false;
        state.formData.himalaya = false;
        state.formData.formAegean = initialState.formData.formAegean;
        state.formData.lungcarcinoma = false;
        state.formData.caspian = false;
        state.formData.aegean = false;
        state.formData.poseidon = false;
        state.formData.pacific = false;
        state.formData.adriatic = false;
      } else if (payload.choice === "topaz") {
        state.formData.topaz = true;
        state.formData.default = false;
        state.formData.himalaya = false;
        state.formData.formAegean = initialState.formData.formAegean;
        state.formData.lungcarcinoma = false;
        state.formData.caspian = false;
        state.formData.adriatic = false;
        state.formData.aegean = false;
        state.formData.poseidon = false;
        state.formData.pacific = false;
        state.formData.defaultForm.preTreatment = "";
        state.formData.defaultForm.patientCondition = "";
        state.formData.defaultForm.reasonsAlternativeTherapiesFailed = "";
        state.formData.defaultForm.prognosis = "";
      } else if (payload.choice === "himalaya") {
        state.formData.himalaya = true;
        state.formData.topaz = false;
        state.formData.default = false;
        state.formData.lungcarcinoma = false;
        state.formData.caspian = false;
        state.formData.adriatic = false;
        state.formData.aegean = false;
        state.formData.poseidon = false;
        state.formData.pacific = false;
        state.formData.formAegean = initialState.formData.formAegean;
        state.formData.defaultForm.preTreatment = "";
        state.formData.defaultForm.patientCondition = "";
        state.formData.defaultForm.reasonsAlternativeTherapiesFailed = "";
        state.formData.defaultForm.prognosis = "";
      } else if (payload.choice === "lungcarcinoma") {
        state.formData.himalaya = false;
        state.formData.topaz = false;
        state.formData.default = false;
        state.formData.lungcarcinoma = true;
        state.formData.formAegean = initialState.formData.formAegean;
        state.formData.defaultForm.preTreatment = "";
        state.formData.defaultForm.patientCondition = "";
        state.formData.defaultForm.reasonsAlternativeTherapiesFailed = "";
        state.formData.defaultForm.prognosis = "";
      }
      if (payload.formAegean) {
        if (payload.formAegean.pdl1_0 === false || payload.formAegean.pdl1_0 === true) {
          state.formData.formAegean.pdl1_0 = payload.formAegean.pdl1_0;
        }
        if (payload.formAegean.iIIAN2 === false || payload.formAegean.iIIAN2 === true) {
          state.formData.formAegean.iIIAN2 = payload.formAegean.iIIAN2;
        }
        if (payload.formAegean.iIIAN3 === false || payload.formAegean.iIIAN3 === true) {
          state.formData.formAegean.iIIAN3 = payload.formAegean.iIIAN3;
        }
        if (payload.formAegean?.recidivism || payload.formAegean?.recidivism === "") {
          state.formData.formAegean.recidivism = payload.formAegean?.recidivism;
        }
      }
    },
  },
});

export const {
  onReceiveSpecialMedImfinziEditData,
  onClearImfinziState,
  onChangeImfinziValue,
} = ImfinziFormSlice.actions;

export default ImfinziFormSlice.reducer;
export const selectImfinziValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.imfinziForm.formData;



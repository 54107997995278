import React, {useState} from "react";
import {connect} from "react-redux";
import {useDispatch} from "react-redux";
import {textToJsonKoguRequestObject} from "../wizardSlice";
import {TextField, Button, Dialog, DialogContent, DialogActions, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";

const AIAssistent = (props) => {
  const {open, onClose} = props;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = async () => {
    await Promise.all([
      dispatch(textToJsonKoguRequestObject({message: inputValue})),
    ],
    );
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t("aiassistent.dialog.header")}</DialogTitle>
      <DialogContent dividers style={{padding: "16px", backgroundColor: "white"}}>
        <TextField
          disabled={props.loading.editRequestLoad === true}
          id="AIAssistent"
          multiline
          rows={12}
          fullWidth
          label={t("aiassistent.header")}
          placeholder={t("aiassistent.placeholder")}
          InputLabelProps={{shrink: true}}
          variant="outlined"
          value={inputValue}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("aiassistent.closeButton")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleButtonClick}
          disabled={props.loading.editRequestLoad === true}
        >
          {t("aiassistent.sendButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.wizard.wizardModel.loading,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AIAssistent);

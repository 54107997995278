import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {FaUserPlus} from "react-icons/fa";
import {IconButton,
  Card,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import {
  fetchSubstitutions,
  addDoctorToSubstitutions,
  fetchSubstitutionDoctors,
} from "../../../store/middleware/substitutionThunk";

import {StyledEngineProvider} from "@mui/material/styles";
import {hotjar} from "react-hotjar";

import Backdrop from "../../../components/Backdrop";
import SubstitutionBody from "../components/SubstitutionBody";

function TabPanel(props) {
  const {children, value, index, ...other}=props;
  return (
    <div
      role="tabpanel"
      hidden={value!==index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value===index&&(
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes={
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles=makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
  },
}));

const Substitution=(props) => {
  const classes=useStyles();
  const {t, i18n} = useTranslation();
  const [addDoctor, setaddDoctor]=useState("");
  const [value, setValue]=React.useState(0);

  const currentLang = i18n.language;

  const handleChange=(event, newValue) => {
    setValue(newValue);
  };
  const dispatch=useDispatch();

  useEffect(() => {
    dispatch(fetchSubstitutions());
    dispatch(fetchSubstitutionDoctors());
    if (location.href.includes("kostengutsprache.patient-strength.ch")) {
      hotjar.initialize(2351086, 6);
    }
    if (location.href.includes("hin.patient-strength.ch")) {
      hotjar.initialize(2351086, 6);
    }
  }, []);


  return (
    <div className="fade-in mt-3 mx-3" style={{borderRadius: 4}}>
      <div className={classes.root} style={{borderRadius: 4}} >
        <Card style={{borderRadius: 4}}>
          <Backdrop open={props.isLoading ? true: false}/>
          <StyledEngineProvider injectFirst>
            <Grid xs={12} item={true}>
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab className="noFocus" label={t("substitutions.header.substutions")} style={{textTransform: "none"}} />
                <Tab id="subFor" className="noFocus" label={t("substitutions.header.substutions.doctor")} style={{textTransform: "none"}} />
              </Tabs>
            </Grid>
          </StyledEngineProvider>
          <TabPanel value={value} index={0}>
            <div className="row mx-1 col-12">
              <h6 className="col-6  pt-3 my-0 p-0">{t("substitutions.header.substutions.description")} </h6>
              <div className="row col-6 p-3">
                <input
                  data-cy="add_substitution_input"
                  className="form-control col-9 p-2 mx-1"
                  type="search"
                  onChange={(event) => setaddDoctor(event.target.value)}
                  aria-label="Search"
                  value={addDoctor}
                />
                <IconButton variant="contained" size="small" data-cy="add_substitution_button" color="primary" className="mx-1" onClick={() => (dispatch(addDoctorToSubstitutions(addDoctor, currentLang)).then(() => {
                  setaddDoctor(""); return dispatch(fetchSubstitutionDoctors());
                }))}>
                  <FaUserPlus />
                </IconButton>
              </div>
            </div>
            <hr />
            <SubstitutionBody substitutions={props.substitutionDoctors} lang={currentLang} noPersonsAddedMessage={t("no.substitutionDoctors.added.yet")} doctors={true} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="row mx-1 col-12">
              <h6 className="col-6  my-0 p-0">{t("substitutions.header.substutions.doctor.description")} </h6>
            </div>
            <hr />
            <SubstitutionBody substitutors={true} lang={currentLang} noPersonsAddedMessage={t("no.substitutors.added.yet")} substitutions={props.substitutions} doctors={false} />
          </TabPanel>
        </Card>
      </div>
    </div>
  );
};


const mapStateToProps=(state) => {
  return {
    ...state,
    substitutions: state.substitution.substitutions,
    substitutionDoctors: state.substitution.substitutionDoctors,
    isLoading: state.loading.substitution,
  };
};

export default (connect(mapStateToProps)(Substitution));

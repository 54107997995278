
import React from "react";
import {IMaskInput} from "react-imask";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
const EmailMask = /^\S*@?\S*$/; // NOSONAR

export const EmailComponent = React.forwardRef<HTMLElement, CustomProps>(
    function EmailComponent(props, ref:any) {
      const {onChange, ...other} = props;
      return (
        <IMaskInput
          {...other}
          mask={EmailMask}
          inputRef={ref}
          onAccept={(value: any) => onChange({target: {name: props.name, value}})}
          overwrite
        />
      );
    },
);

import React, {useState, useEffect} from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  Select,
  MenuItem,
  Slide,
  FormControl,
  Checkbox,
  FormControlLabel,
  IconButton,
  DialogTitle,
  Box,
  Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {withStyles} from "@mui/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import {
  ArrowUpward,
  ArrowDownward} from "components/icons";
import {onSort} from "store/features/requestListSlice";
import {allKoguTypes} from "components/koguTypes";
import {allStates} from "components/states";
import {useDispatch} from "react-redux";
import Paper from "@mui/material/Paper";

const styles = () => ({
  notchedOutline: {
    borderStyle: "none",
  },

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const FiltersCard=({
  searchQuery,
  label_search,
  onSearchChange,
  classes,
  onDeleteFilter,
  onFilterChange,
  filters})=>{
  const {t}=useTranslation();
  const [open, setOpen]=useState(false);
  const [filter, setFilter] = React.useState("date");
  const [asc, setAsc] = React.useState(true);
  const dispatch=useDispatch();

  const handleChange = (event) => {
    setFilter(event.target.value);
    dispatch(onSort({sortBy: event.target.value, sortAsc: asc}));
  };

  useEffect(() => {
    dispatch(onSort({sortBy: filter, sortAsc: asc}));
  }, [asc]);


  const handleFilterChange=(filters, value)=>{
    if (value==="None"||value===false) {
      dispatch(onDeleteFilter(filters));
    } else {
      dispatch(onFilterChange({filter: filters, value: value}));
    }
  };

  return (
    <Paper>
      <Grid container direction={"row"} item xs={12} sm={12} sx={{marginBottom: "1px"}}>
        <Grid item xs={11} >
          <TextField
            fullWidth
            aria-label="Search"
            inputProps={{"data-cy": "searchinput"}}
            value={searchQuery}
            placeholder={label_search}
            onChange={(event) => onSearchChange(event.target.value)}
            InputProps={{
              "startAdornment": (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              "classes": {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        </Grid>
        <Grid xs={1} item justify="flex-end">
          <Box display="flex" flexDirection="column" >
            <IconButton
              onClick={() => setOpen(true)}
              data-cy='filterButton'
              size="large"
              id="filter_requests"
            >
              <FilterAltIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            TransitionComponent={Transition}
            fullWidth
          >
            <DialogTitle>
              <IconButton
                aria-label="close"
                onClick={()=>setOpen(false)}
                data-cy='closeFilters'
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography>
                {t("filters.title")}
              </Typography>
            </DialogTitle>
            <Grid container
              direction='column'
              alignContent="center"
              alignItems="center" spacing={3} padding='40px' >
              <Grid item container xs={12} >
                <FormControl fullWidth >
                  <Select
                    displayEmpty
                    renderValue={filters["kogu_type"]? undefined :()=> t("headers.koguType.filter")}
                    variant="standard"
                    value={filters["kogu_type"]?filters["kogu_type"]:""}
                    onChange={(e)=>(handleFilterChange("kogu_type", e.target.value))}
                    data-cy="sortbyKoguType"
                  >
                    <MenuItem data-cy={"koguTypeListNone"} value={"None"}>{
                      t("filter_none")}
                    </MenuItem>
                    {allKoguTypes.map((e)=>(
                      <MenuItem key={e.value} data-cy={`koguTypeList_${e.value}`} value={e.value}>{
                        t(`kogu_type_${e.value}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container xs={12}>
                <FormControl fullWidth >
                  <Select
                    displayEmpty
                    variant="standard"
                    renderValue={filters["request_status"]? undefined :()=> t("headers.requestStatus.filter")}
                    value={filters["request_status"]?filters["request_status"]:""}
                    onChange={(e)=>(handleFilterChange("request_status", e.target.value))}
                    data-cy="sortbyRequestStatus"
                  >
                    <MenuItem data-cy={"requestStatusNone"} value={"None"}>{
                      t("filter_none")}
                    </MenuItem>
                    {allStates.map((e)=>(
                      <MenuItem key={e.status} data-cy={`requestStatus_${e.status}`} value={e.status}>
                        {t(e.status)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container xs={12} justifyContent='flex-start' >
                <FormControlLabel
                  label={t("headers.urgent.filter")}
                  control={
                    <Checkbox
                      data-cy='urgent_filter'
                      checked={filters["urgent"]?filters["urgent"]:false}
                      onChange={(e)=>(handleFilterChange("urgent", e.target.checked))}
                    />
                  }
                />
              </Grid>
              <Grid container item xs={12} alignContent="center" alignItems="center" justifyContent='center'>
                <Grid item container xs={10}>
                  <FormControl fullWidth >
                    <Select
                      displayEmpty
                      renderValue={filter? undefined :()=> t("headers.sorting.filter")}
                      variant="standard"
                      value={filter}
                      onChange={handleChange}
                      name='sortbydropdown'
                      data-cy="sortbydropdown"
                    >
                      <MenuItem data-cy="sortbydropdown_name" value={"name"}>{t("requestlist.headers.name")}</MenuItem>
                      <MenuItem data-cy="sortbydropdown_diagnosis" value={"diagnosis"}>{t("requestlist.headers.diagnosis")}</MenuItem>
                      <MenuItem data-cy="sortbydropdown_therapy" value={"therapy"}>{t("requestlist.headers.therapy")}</MenuItem>
                      <MenuItem data-cy="sortbydropdown_date" value={"date"}>{t("requestlist.headers.date")}</MenuItem>
                      <MenuItem data-cy="sortbydropdown_type" value={"type"}>{t("requestlist.headers.request.type")}</MenuItem>
                      <MenuItem data-cy="sortbydropdown_status" value={"status"}>{t("requestlist.headers.status")}</MenuItem>
                      <MenuItem data-cy="sortbydropdown_dokument" value={"dokument"}>{t("requestlist.headers.dokument")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    data-cy="sortbydropdown_asc"
                    onClick={()=>setAsc(true)}
                    disabled={asc}
                    size="small">
                    <ArrowUpward />
                  </IconButton>
                  <IconButton
                    data-cy="sortbydropdown_asc_false"
                    onClick={()=>setAsc(false)}
                    disabled={!asc}
                    size="small">
                    <ArrowDownward />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Dialog>
        </Grid>


      </Grid>
    </Paper>


  );
};

export default withStyles(styles)( FiltersCard);

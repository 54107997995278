import React, {ReactEventHandler} from "react";
import {
  TextField,
  Autocomplete,
} from "@mui/material";
import {useTranslation} from "react-i18next";

type inputPropType={
    value:any,
    onChange:ReactEventHandler,
    name:string,
}

type InsuranceNoProps={
    name:string,
    input:inputPropType,
    label:string,
    required:boolean,
    type:string,
    meta: {error:string,
        visited:boolean},
    touched:boolean,
    patientData:any,
}

export const InsuranceNo=({
  name, input, label, required, type, meta: {visited, error}, touched, patientData,
}:InsuranceNoProps) => {
  const {t}=useTranslation();

  const onChangeValidation=(e:any)=>{
    e.preventDefault();
    if (!e.nativeEvent.data || /^\d+$/.test(e.nativeEvent.data)) {
      input.onChange(e);
    }
  };
  const options=patientData? patientData["patient_insurance_no"]:[];
  return (
    <Autocomplete
      autoHighlight
      freeSolo
      data-cy="patient_insurance_no"
      options={options}
      disableClearable={true}
      onSelect={input.onChange}
      renderInput={(params:any) => {
        input.value=input.value? input.value:params.inputProps.value;
        if (input && input.value && ((typeof input.value) === "string" )) {
          params.inputProps.value=input?.value?.replace(/[a-zA-z]/gi, "");
        }
        return <TextField
          {...input}
          {...params}
          variant="standard"
          onChange={onChangeValidation}
          name={name}
          inputProps={{...params.inputProps, autoComplete: "new-password"}}
          value={input.value}
          type={type}
          required={required}
          label={label}

          error={(touched||visited)&&error? true:false}
          helperText={(touched||visited)&&error? t(error):null} />;
      }} />
  );
};

import React from "react";
import {useTranslation} from "react-i18next";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Card,
  Typography,
  Divider,
} from "@mui/material";
import {
  Print,
  GetApp,
  ListAltOutlined,
  PlaylistAddCheckOutlined,
  FileCopyOutlined,
  ContactMailOutlined,
  DeleteForever,
} from "components/icons";


const DropdownAction=(props) => {
  const {actions,
    requestid,
    noPharmaSet,
    noDiagnosis,
    drugIsNotSet,
    requestKoGuType,
    notPraluent,
    verified,
  }=props;
  const open=Boolean(props.anchorEl);
  const {t}=useTranslation();

  const handleAction=(action) => {
    props.closeAnchorEl();
    return action.call();
  };

  const style={iconWidth: {minWidth: "30px"}};
  const isOldRequest = (requesttype)=>false;
  if (!props.requestid) {
    return <div></div>;
  } else {
    return (
      <Menu
        id="long-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={open}
        onClose={() => props.closeAnchorEl()}
      >
        <Card>
          {isOldRequest(requestKoGuType)&&
        (<p className="MuiMenuItem-root MuiListItem-gutters">
          {t("defaultheader.linkmigrated")}
        </p>)
          }
          <MenuItem key = "delete" data-cy={"delete"+requestid} onClick={() => handleAction(actions.deleteRequest)} hidden={isOldRequest(requestKoGuType)}>
            <ListItemIcon style={style.iconWidth}>
              <DeleteForever fontSize="small" />
            </ListItemIcon>
            {t("myrequests.buttons.delete")}
          </MenuItem>

          <MenuItem key = "print_" data-cy={"print_"+requestid} onClick={() => handleAction(actions.print)}>
            <ListItemIcon style={style.iconWidth}>
              <ContactMailOutlined fontSize="small" />
            </ListItemIcon>
            {t("myrequests.buttons.print")}
          </MenuItem>
          <MenuItem key = "drug_" data-cy={"drug_"+requestid} disabled={drugIsNotSet} onClick={() => handleAction(actions.orderDrug)}>
            <ListItemIcon style={style.iconWidth}>
              <ListAltOutlined fontSize="small" />
            </ListItemIcon>
            {t("myrequests.buttons.order.drug")}
          </MenuItem>

          <MenuItem disabled={(!verified || noPharmaSet) || noDiagnosis || requestKoGuType !== "med"} key = "studyrequest_" data-cy={"studyrequest_"+requestid} onClick={() => handleAction(actions.requestStudy)}>
            <ListItemIcon style={style.iconWidth}>
              <PlaylistAddCheckOutlined fontSize="small" />
            </ListItemIcon>{t("myrequests.buttons.request.study")}
          </MenuItem>

          <MenuItem
            key="copy_"
            data-cy={"copy_"+requestid}
            hidden={isOldRequest(requestKoGuType)}
            onClick={() => handleAction(actions.copyRequest)}
          >
            <ListItemIcon style={style.iconWidth}>
              <FileCopyOutlined fontSize="small" />
            </ListItemIcon>
            {t("myrequests.buttons.copy")}
          </MenuItem>

          <MenuItem
            disabled={notPraluent}
            key="copy_praluent_"
            data-cy={"copy_praluent_"+requestid}
            hidden={isOldRequest(requestKoGuType) || (requestKoGuType === "med")}
            onClick={() => handleAction(actions.copyRequestPraluent)
            }>
            <ListItemIcon style={style.iconWidth}>
              <FileCopyOutlined fontSize="small" />
            </ListItemIcon>
            {t("myrequests.buttons.praluent.copy")}
          </MenuItem>
          <Divider style={{width: "100%"}} />
          <MenuItem key = "download_all_" data-cy={"download_all_"+requestid} hidden={isOldRequest(requestKoGuType)} onClick={() => handleAction(actions.downloadAll)}>
            <ListItemIcon style={style.iconWidth}>
              <GetApp fontSize="small" />
            </ListItemIcon>
            <Typography component={"span"} variant="inherit" noWrap>
              {t("myrequests.buttons.download.all")}
            </Typography>
          </MenuItem>
          <MenuItem key = "print_all_" data-cy={"print_all_"+requestid} hidden={isOldRequest(requestKoGuType)} onClick={() => handleAction(actions.printAll)}>
            <ListItemIcon style={style.iconWidth}>
              <Print fontSize="small" />
            </ListItemIcon>
            <Typography component={"span"} variant="inherit" noWrap>
              {t("myrequests.buttons.print.all")}
            </Typography>
          </MenuItem>
        </Card>

      </Menu>
    );
  }
};

export default (DropdownAction);

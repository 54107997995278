
import React from "react";

import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import {Link} from "@mui/material";
import {useTranslation} from "react-i18next";
export const Unauthorized = () => {
  const {t}=useTranslation();
  const handleClick = () => {
    history.push("/");
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.box}>
        <Typography variant="h4" gutterBottom>
          {t("401")}
        </Typography>
        <Typography variant="body1">
          {t("401LongText")}
        </Typography>
        <Link href="/" onClick={handleClick}>
          {t("Home")}
        </Link>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
};

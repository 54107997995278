import React from "react";
import PhoneInput from "react-phone-input-2";
import {FormHelperText} from "@mui/material";
import {customComponentInputType} from "../../store/rootstate";
import {useTranslation} from "react-i18next";


type PhoneComponentProps={
    input:any,
    touched:boolean,
    placeholder:string,
    doctorCountry:string,
    isEdit:boolean,
    id:string,
    meta: {error:string,
        visited:boolean}
}

export const PhoneComponent=({
  input,
  touched,
  placeholder,
  doctorCountry,
  isEdit,
  id,
  meta: {error,
    visited},
  ...other}:PhoneComponentProps) => {
  const inputProp:customComponentInputType={
    required: true,
    id: id,
  };


  const countryCode = doctorCountry ?
    doctorCountry.toLowerCase() : "ch";

  if (!visited) {
    inputProp.value=input.value;
  }
  const {t}=useTranslation();

  return <div>
    <FormHelperText>
      {placeholder}
    </FormHelperText>
    <PhoneInput
      {...input}
      onBlur={() => null}
      inputProps={inputProp}
      placeholder={placeholder}
      inputStyle={{width: "100%"}}
      country={countryCode}
    />
    <label
      htmlFor="user-message"
      className="col-lg pl-0 text-danger"
    >
      {(touched||visited)&&error? t(error):null}
    </label>
  </div>;
};

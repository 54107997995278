import "./i18n";
import "App.scss";
import React, {Suspense} from "react";
import {ThemeProvider, StyledEngineProvider, createTheme} from "@mui/material/styles";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import Backdrop from "./components/Backdrop";
import store from "./store/store.tsx";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import {BrowserRouter} from "react-router-dom";

import {Button, styled} from "@mui/material";
import {logout, msalInstance} from "./config/msalProvider.js";
import {MsalProvider} from "@azure/msal-react";
import ErrorBoundary from "layout/ErrorBoundary";
import {hotjar} from "react-hotjar";

import CacheBuster from "react-cache-buster";
import {SnackbarProvider} from "notistack";

import {default as package_json} from "../package.json";
const version = package_json.version;

function loading() {
  return <Backdrop open={true} />;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#63c2de",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9da5b1",
      contrastText: "#fff",
    },
    link: {
      main: "#23282c",
      contrastText: "#fff",
    },
    alert: {
      main: "#f86c6b",
      contrastText: "#fff",
    },
    white: {
      main: "#fff",
      contrastText: "#fff",
    },
    green: {
      main: "#4dbd74",
      contrastText: "#fff",
    },
    acceptGreen: {
      main: "#56b472",
      contrastText: "#fff",
    },
    warning: {
      main: "#ffc107",
      contrastText: "#fff",
    },
    lightgray: {
      main: "#c8ced3",
      contrastText: "#fff",
    },
    buttonGray: {
      main: "#fff",
      contrastText: "#9da5b1",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#23282c",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: "#23282c !important",
        },
      },
    },

    MuiStepIcon: {
      styleOverrides: {
        "root": {
          color: "#c8ced3",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: "#23282c !important",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            background: "transparent",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          "backgroundColor": "#fff !important",
        },
      },
    },
  },

  typography: {
    root: {
      color: "#23282c",
    },
    fontFamily: "Nunito",
    allVariants: {
      color: "#23282c",
    },
    secondary: {
      color: "#23282c",
    },
  },
  brandbook: {
    body: "#23282c",
    blue: "#20a8d8",
    lightblue: "#8ad4ee",
    darkblue: "#167495",
    indigo: "#6610f2",
    purple: "#6f42c1",
    pink: "#e83e8c",
    danger: "#f86c6b",
    dangerhoover: "#f64846",
    dangerhooverborder: "#f63c3a",
    orange: "#f8cb00",
    yellow: "#ffc107",
    green: "#4dbd74",
    teal: "#20c997",
    cyan: "#17a2b8",
    white: "#fff",
    gray: "#73818f",
    disabled: "#73818f",
    graydark: "#2f353a",
    primary: "#20a8d8",
    secondary: "#c8ced3",
    success: "#4dbd74",
    info: "#63c2de",
    warning: "#ffc107",
    light: "#f0f3f5",
    dark: "#2f353a",
    btnlight: "#23282c",
    btnlightbackground: "#f0f3f5",
    btnlightbordercolor: "#f0f3f5",
  },
  variantSucess: {backgroundColor: "purple"},
  variantError: {backgroundColor: "blue"},
});

import Layout from "layout/Layout";

const StyledSnackbarProvider= styled(SnackbarProvider)`
&.SnackbarItem-variantSuccess{
  background-color:#4dbd74 ;
}
&.SnackbarItem-variantError{
  background-color:#f86c6b ;
}
`;


ReactDOM.render(
    <CacheBuster
      currentVersion={version}
      isEnabled={true} // If false, the library is disabled.
      isVerboseMode={false} // If true, the library writes verbose logs to console.
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Suspense fallback={loading()}>
            <BrowserRouter>
              <MsalProvider instance={msalInstance}>
                <Button style={{display: "none"}} id="testing_logout" onClick={() => logout()}> </Button> {/* needed for testing */}
                <React.Fragment>
                  <StyledSnackbarProvider maxSnack={4}>
                    <ErrorBoundary>
                      <Provider store={store} >
                        <Layout pca={msalInstance} />
                      </Provider>
                    </ErrorBoundary>
                  </StyledSnackbarProvider>
                </React.Fragment>
              </MsalProvider>
            </BrowserRouter>
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </CacheBuster>
    , document.getElementById("root"),
);

// Cypress Debugger
if (window.location.href.indexOf("localhost") > 1 || window.location.href.indexOf("development") > 1 || window.location.href.indexOf("quality") > 1) {
  window.store = store;
}

// Hotjar
if (location.href.includes("kostengutsprache.patient-strength.ch")) {
  hotjar.initialize(2351086, 6);
}
if (location.href.includes("hin.patient-strength.ch")) {
  hotjar.initialize(2351086, 6);
}

// Logs
if (location.href.includes("kostengutsprache.patient-strength.ch")) {
  console.log = function() {};
  console.info = function() {};
  console.debug = function() {};
  // console.error = function() {};
  // console.trace = function() {};
}
if (location.href.includes("hin.patient-strength.ch")) {
  console.log = function() {};
  console.info = function() {};
  console.debug = function() {};
  // console.error = function() {};
  // console.trace = function() {};
}
/*
if (window.location.href.indexOf("?forcelogin=true") >=0) {
  window.location.href = window.location.href.replace("?forcelogin=true", "");
}
*/

import fetch from "./fetchWithTimeout";
import {acquireAccessToken} from "../config/msalProvider.js";
import {getreCaptchaToken} from "./reCaptchaProvider.js";
import i18n from "i18next";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;


export const delay = (() => {
  let timeout = null;
  return (callback, ms) => {
    clearTimeout(timeout);
    timeout = setTimeout(callback, ms);
  };
})();


export const get = async (url, responseType, JWTtoken) => {
  const token = JWTtoken ?? await acquireAccessToken();
  const reCaptchaToken = await getreCaptchaToken();

  return fetch(REACT_APP_API_URL + url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Authorization": "Bearer " + token,
      "Content-Type": "application/json",
      "Accept": "application/json",
      "captcha": reCaptchaToken,
      "Accept-Language": i18n?.language.toLowerCase(),
    },
  })
      .then((response) => {
        switch (responseType) {
          case "blob":
            return response.blob();
          default:
            const contentType = response?.headers?.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return (response?.json() || {}).then((data) => {
                return data;
              });
            } else {
              return (response?.text() || "").then((text) => {
                return text;
              });
            }
        }
      });
};

export const post = async (url, body, JWTToken) => {
  const token = JWTToken ?? await acquireAccessToken();
  const reCaptchaToken = await getreCaptchaToken();

  return fetch(REACT_APP_API_URL + url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      "captcha": reCaptchaToken,
      "Accept-Language": i18n?.language.toLowerCase(),
    },
    body: body && JSON.stringify(body), // for deletefiles method that has no body
  })
      .then((res) => {
        if (res.ok) {
          if (res != undefined) {
            return (res?.json() || {});
          } else {
            return {};
          }
        } else {
          console.error(error);
          console.error(url);
          console.error(res);
          return {};
        }
      }).catch((error) => {
        return {};
      });
};

export const postNoBearer = async (url, body, token) => {
  const reCaptchaToken = await getreCaptchaToken();

  return fetch(REACT_APP_API_URL + url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": token,
      "captcha": reCaptchaToken,
      "Accept-Language": i18n?.language.toLowerCase(),
    },
    body: JSON.stringify(body),
  })
      .then((res) => res)
      .then((res) => {
        if (res) {
          return res;
        }
      }).catch((error) => {
        console.error(error);
        throw error;
      });
};


// to see if params needed
export const postScreenshot = async (url, body) => {
  const reCaptchaToken = await getreCaptchaToken();

  const token = await acquireAccessToken();
  return fetch(REACT_APP_API_URL + url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Accept": "*/*",
      "Content-Type": "image/jpeg",
      "Authorization": "Bearer " + token,
      "captcha": reCaptchaToken,
      "Accept-Language": i18n?.language.toLowerCase(),
    },
    body: body,
  })
      .then((res) => (res?.json() || {}))
      .then((res) => {
        if (res) {
          return res;
        } else {
          throw res;
        }
      }).catch((error) => {
        console.error(error);
        throw error;
      });
};

export const postFiles = async (url, singleRequest) => {
  const reCaptchaToken = await getreCaptchaToken();

  const token = await acquireAccessToken();
  fetch(REACT_APP_API_URL + url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Authorization": "Bearer " + token,
      "captcha": reCaptchaToken,
      "Accept-Language": i18n?.language.toLowerCase(),
    },
    body: singleRequest,
  })
      .then(async (res) => {
        if (res.ok) {
          const response = await (res?.json() || {});
          console.debug("[DEBUG] response postFiles", response);
          return {...response, "status": res.status};
        } else {
          console.error(res.statusText);
          throw Error(res.statusText);
        }
      });
};

export const postWizardOverview = async (url, body) => {
  const reCaptchaToken = await getreCaptchaToken();
  return fetch(REACT_APP_API_URL + url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Accept": "*/*",
      "Content-Type": "application/json",
      "captcha": reCaptchaToken,
      "Accept-Language": i18n?.language.toLowerCase(),
    },
    body: JSON.stringify(body),
  })
      .then( (response) =>{
        return response.blob();
      });
};


export const parseFiles = async (url, singleRequest) => {
  const token = await acquireAccessToken();
  const reCaptchaToken = await getreCaptchaToken();

  const result = await new Promise((resolve, reject) => {
    fetch(REACT_APP_API_URL + url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Authorization": "Bearer " + token,
        "captcha": reCaptchaToken,
        "Accept-Language": i18n?.language.toLowerCase(),
      },
      body: singleRequest,
    }).then(async (res) => {
      if (res.ok) {
        const response = await (res?.json() || {});
        resolve({response, "status": res.status});
      } else {
        console.error(res.statusText);
        reject(res.statusText);
      }
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
  return result;
};

export const postFilesPharmaCompany = async (url, body, token) => {
  const reCaptchaToken = await getreCaptchaToken();

  return fetch(REACT_APP_API_URL + url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Authorization": token,
      "captcha": reCaptchaToken,
      "Accept-Language": i18n?.language.toLowerCase(),
    },
    body: body,
  })
      .then((res) => res.status)
      .then((res) => {
        if (res) {
          return res;
        }
      }).catch((error) => {
        console.error("postFilesPharmaCompany", error);
        throw error;
      });
};


// above ones have file body not string body

// too risky
export const deleteMyAccount = async () => {
  const reCaptchaToken = await getreCaptchaToken();

  const token = await acquireAccessToken();
  return fetch(REACT_APP_API_URL + "user/delete", {
    method: "GET",
    mode: "cors",
    headers: {
      "Authorization": "Bearer " + token,
      "Content-Type": "application/json",
      "Accept": "application/json",
      "captcha": reCaptchaToken,
      "Accept-Language": i18n?.language.toLowerCase(),
    },
  })
      .then((res) => {
        if (res.ok) {
          return res;
        } else {
          console.error(res.statusText);
          throw Error(res.statusText);
        }
      });
};


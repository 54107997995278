import React from "react";

import RequestList from "pages/RequestList/containers/RequestList";

const RequestListView = () => {
  return (
    <div className="animated fadeIn">
      <RequestList />
    </div>
  );
};
export default RequestListView;

import {Box} from "@mui/material";
import React from "react";
import {connect} from "react-redux";
import {RootState} from "store/store";
import WizardForm from "./containers/WizardForm";
import WizardNavigation from "./containers/WizardNavigation";
import WizardRightSidebar from "./containers/WizardRightSidebar";
import ErrorBoundary from "../../layout/ErrorBoundary";

export const Wizard = (props) => {
  return (
    <ErrorBoundary >
      <Box>
        <WizardNavigation>
          <WizardForm>
          </WizardForm>
        </WizardNavigation>
        <WizardRightSidebar></WizardRightSidebar>
      </Box>
    </ErrorBoundary>
  );
};


const mapStateToProps = (state:RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);

import React, {useState, useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {FaTrash, FaUpload, FaCloudUploadAlt, FaComments} from "react-icons/fa";
import {Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  ButtonGroup,
  Grid,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  CardHeader,
} from "@mui/material";
import jwtDecode from "jwt-decode";
import {
  verifyToken,
  verifyNrOfFiles,
  addComment,
  acceptInsuranceRequest,
  getRequestStatus,
  uploadFiles,
} from "../../store/middleware/uploadThunk";
import {fetchPubmedAbstract} from "store/middleware/masterdataThunk";
import {getAvailableDocuments, getAvailableStudies} from "store/middleware/newRequestThunk";
import {useTranslation} from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import useMediaQuery from "@mui/material/useMediaQuery";


import Backdrop from "../../components/Backdrop";
import InsuranceAcceptDialog from "../../components/dialogs/InsuranceAcceptWarning";
import {PubMedCard} from "components/PubMedCard";
import {
  useParams,
} from "react-router-dom";

const InsuranceResponseJWT = (props) => {
  const {currentStudy, abstract}=props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [pageToShow, setPageToShow]=React.useState("");
  const [feedbackMessage, setFeedbackMessage]=React.useState("");
  const matches = useMediaQuery("(max-width:650px)");

  const getRequestDataFromToken=()=>{
    const {token}=useParams();
    let decodedTokenResult;
    try {
      decodedTokenResult = jwtDecode(token);
    } catch (ex) {
      console.error("[JWT] [DECODE ERROR]", ex, token);
      decodedTokenResult = {
        request_id: 0,
        requested: false,
      };
    }
    return {JWTToken: token, decodedToken: decodedTokenResult};
  };
  const {JWTToken, decodedToken}=getRequestDataFromToken();

  const [open, setOpen] = useState(false);
  const [insuranceStatus, setInsuranceStatus]=useState("");
  const [requestIsValid, setRequestIsValid] = useState(true);
  const [nrOfFile, setnrOfFile] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles]=useState([]);
  const [inputRef] = useState(React.createRef(0));
  const [studyRating, setStudyRating]=useState("not_set");
  const [requestStatus, setRequestStatus]=useState("");


  const handleAddFile=(value)=>setFiles(files.concat(value));
  const handleFileRemove = (file) => setFiles(files.filter((e) => e.name !== file));
  useEffect(() => {
    dispatch(verifyToken(JWTToken)).then((verifyTokenResult) => {
      setRequestIsValid(verifyTokenResult);
      dispatch(verifyNrOfFiles(JWTToken)).then((verifyNrOfFilesResult) => {
        setnrOfFile(verifyNrOfFilesResult);
        dispatch(getRequestStatus(JWTToken))
            .then((requestStatusResult)=>{
              setRequestStatus(requestStatusResult.request_status);
              Promise.all([
                dispatch(getAvailableStudies(decodedToken.request_id, JWTToken)),
                dispatch(getAvailableDocuments(decodedToken.request_id, JWTToken)),
              ]);
            })
            .catch((error)=>{
              console.log(error);
            });
      }).catch((error) => {
        console.error("[JWT] [verifyNrOfFiles]", error);
      });
    }).catch((error) => {
      console.error("[JWT] [verifyToken]", error);
    });
  }, []);

  useEffect(()=>{
    const file={name: "***************.pdf"};
    for (let i=0; i<(10-nrOfFile.Count); i++) {
      const newFile=uploadedFiles;
      newFile[i]=file;
      setUploadedFiles(newFile);
    }
  }, [nrOfFile]);

  const handleFetchAbstract = (pmid) => {
    dispatch(fetchPubmedAbstract(pmid, JWTToken));
  };

  const setFeedbackForComment=()=>{
    setComment("");
    setPageToShow("feedback");
    setFeedbackMessage("feedback.message.comment");
  };

  const handleCommentAdd = () => {
    setIsLoading(true);
    comment.length>0&&
      dispatch(addComment({"comment": comment, "token": JWTToken, "files": files, "rating": studyRating}))
          .then((res)=>{
            if (res>=200&& res<400) {
              setFeedbackForComment();
            }
          })
          .finally(() =>{
            setIsLoading(false);
          });
    if (comment.length===0) {
      if (files.length>0) {
        dispatch(uploadFiles(files, JWTToken)).then((res)=>{
          setFeedbackForComment();
        });
      } else {
        setFeedbackForComment();
      }
    }
    setIsLoading(false);
  };
  const handleInsuranceAcceptence = (status) => {
    setIsLoading(true);
    let rating=studyRating;
    if (status==="DECLINED") {
      rating="D";
    }
    console.debug("dispatch acceptInsuranceRequest", {
      status: status,
      comment: comment,
      files: files,
      study_id: currentStudy?.study_id || "",
      rating: rating,
    });
    dispatch(acceptInsuranceRequest({"status": status, "comment": comment, "token": JWTToken,
      "files": files, "studyId": currentStudy?.study_id, "rating": rating}))
        .then((res)=>{
          if (res.success&& res.success!==false) {
            setFeedbackMessage(`feedback.message.${status}`);
            setPageToShow("feedback");
          }
        })
        .finally(() => {
          setOpen(false);
          setIsLoading(false);
        });
  };

  const handleOpenDialog=(status)=>{
    setInsuranceStatus(status);
    setOpen(true);
  };
  const handleCancelDialog=()=>setOpen(false);
  const handleDropzoneClick = () => inputRef.current.click();
  const getButtonVariant=(currentPage, buttonType)=>currentPage===buttonType ?"contained":"outlined";
  const tableCellStyle={"overflow": "auto", "overflowWrap": "break-word"};


  const getSubmitButton=(page)=>{
    switch (page) {
      case "pending":
        return (<Button
          align="center"
          variant='contained'
          className="mt-2"
          data-cy='comment'
          color='primary'
          sx={{width: "50%"}}
          onClick={() => handleCommentAdd()}
        >
          {t("comment.buttton")}
        </Button>);
      case "accept":
        return (<Button
          align="center"
          className="mt-2"
          variant='contained'
          color='success'
          sx={{width: "50%"}}
          data-cy='accept_insuranceRequest'
          onClick={() => handleOpenDialog("APPROVED")}
        >
          {t("accept.button")}
        </Button>);
      case "decline":
        return (<Button
          align="center"
          className="mt-2"
          variant='contained'
          color='alert'
          sx={{width: "50%"}}
          data-cy='decline_insuranceRequest'
          onClick={() =>handleOpenDialog("DECLINED")}
        >
          {t("deny.button")}
        </Button>);
      default:
        return (<></>);
    }
  };
  return (
    pageToShow==="feedback"?
    <Grid container sx={{background: "white", height: "100%", padding: "20px"}} justifyContent='center'>
      <Typography >{t(feedbackMessage)}</Typography>
    </Grid>:
    <Grid container sx={{background: "white", height: "100%", padding: "20px", marginBottom: "20px"}} justifyContent='center'>
      <Backdrop open={isLoading}></Backdrop>
      <Grid item xs={12}>
        <Typography paddingBottom='15px' >{t("request.status")}: {t(requestStatus)}</Typography>
        <Typography paddingBottom='15px' >{t("request.number")}: {decodedToken.request_id}</Typography>
        <ButtonGroup orientation={matches?"vertical":"horizontal"} >
          <Button
            data-cy='pending_insurance_button'
            variant={getButtonVariant(pageToShow, "pending")}
            onClick={() => setPageToShow("pending")}
            color='primary'
          >
            {t("pending.insurance")}
          </Button>
          <Button
            data-cy='accept_insurance_button'
            variant={getButtonVariant(pageToShow, "accept")}
            onClick={() => setPageToShow("accept")}
            color='success'>
            {t("accept.insurance")}
          </Button>
          <Button
            data-cy='decline_insurance_button'
            variant={getButtonVariant(pageToShow, "decline")}
            onClick={()=>setPageToShow("decline")}
            color='alert'>
            {t("decline.insurance")}
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item container xs={12} padding='20px'>
        {pageToShow!==""&&
        <Typography>
          {t(`insurance.description.${pageToShow}`)}
        </Typography>
        }
      </Grid>
      <Grid item container xs={12} sm={9} >
        {pageToShow!==""&&
        <Grid container >
          <Grid item container xs={12}>
            <div hidden={requestIsValid} className="jwtTokenExpired">
              {t("jwtTokenExpired")}
            </div>
            <Grid item xs={12}>
              <CardHeader
                className="p-0 pb-3 pt-4"
                avatar={
                  <Avatar aria-label="recipe" style={{color: "#63c2de", backgroundColor: "#f0f3f5"}}>
                    <FaCloudUploadAlt data-toggle="popover" data-content="Disabled popover" />
                  </Avatar>
                }
                data-cy='title'
                title= {`${t("number.of.files.to.upload")} ${nrOfFile?.Count}.`}
              />
              <Grid item xs={12} paddingBottom='25px'>
                <div className="Dropzone shadow" data-cy='ins_upload_dropzone' onClick={handleDropzoneClick}>
                  <input
                    ref={inputRef}
                    hidden={true}
                    type="file"
                    id="file"
                    accept=".pdf"
                    multiple name="file"
                    onDrop={(e) =>console.log("onDrop", e)}
                    onChange={(e) => handleAddFile([...e.target.files])}
                    data-cy="dropzone_input"

                  />
                  <span>{t("upload.instruction")}</span>
                  <FaUpload />
                </div>

              </Grid>

              {/* uploaded files table */}
              <Grid item xs={12} style={{borderRadius: "5px"}}>
                <Table >
                  <TableBody>
                    {uploadedFiles.length > 0 && uploadedFiles.map((file, idx) =>(
                      <TableRow className="table table-borderless table-hover  mb-0 pb-0 d-flex align-items-center" key={idx}>
                        <TableCell style={{"verticalAlign": "middle"}} className="col-1"><PictureAsPdfIcon color="primary"/></TableCell>
                        <TableCell align="left" sx={tableCellStyle} className="col-7 animated fadeIn"><span >{file.name}</span></TableCell>
                        <TableCell align="right" sx={tableCellStyle} className="col-4 animated fadeIn">
                          <Typography style={{color: "#4dbd74"}}>{t("already.uplaoded")}</Typography>
                        </TableCell>
                      </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </Grid>
              {/* insurance uploaded files table */}
              <Grid item xs={12} style={{borderRadius: "5px"}}>
                <Table>
                  <TableBody>
                    {files.length > 0 && files.map((file, idx) =>
                      <TableRow className="table table-borderless table-hover  mb-0 pb-0 d-flex align-items-center" key={idx}>
                        <TableCell style={{"verticalAlign": "middle"}} className="col-1"><PictureAsPdfIcon color="primary"/></TableCell>
                        <TableCell align="left" sx={tableCellStyle} className="col-7"><span >{file.name}</span></TableCell>
                        <TableCell align="right" style={{"verticalAlign": "middle"}} className="col-2"><span >{((file.size) / 1000000).toFixed(2)} MB</span></TableCell>
                        <TableCell align="right" className="col-2">
                          <IconButton color="primary" onClick={()=>handleFileRemove(file.name)}><FaTrash/></IconButton>
                        </TableCell>
                      </TableRow>,
                    )}
                  </TableBody>
                </Table>
              </Grid>
              {/* studies table */}
              <Grid item xs={12}>
                {Boolean(currentStudy?.pmid)&&<PubMedCard
                  title={t("upload.title.current")}
                  rowData={currentStudy}
                  // addedStudyId={addedStudyId}
                  handleFetchAbstract={handleFetchAbstract}
                  abstractContent={abstract.content?.[currentStudy?.pmid]}
                  isLoading={abstract.isLoading}
                />}
              </Grid>
              {(currentStudy?.pmid && pageToShow==="accept") &&
              <Grid item xs={4} marginTop='20px'>
                <FormControl data-cy='study_rating_dropdown' fullWidth size="small">
                  <InputLabel>{t("study.rating")}</InputLabel>
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {vertical: "bottom", horizontal: "left"},
                      transformOrigin: {vertical: "top", horizontal: "left"},
                      sx: {maxHeight: "50%"},
                    }}
                    value={studyRating}
                    onChange={(event)=>setStudyRating(event.target.value)}
                  >
                    <MenuItem key='A' value='A'>A</MenuItem>
                    <MenuItem key='B' value='B'>B</MenuItem>
                    <MenuItem key='C' value='C'>C</MenuItem>
                    <MenuItem key='not_set' value='not_set'>{t("rating.not.set")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>}
            </Grid>
            <Grid item xs={12}>
              <CardHeader
                className="p-0 pb-3 pt-4"
                avatar={ <Avatar aria-label="recipe" style={{color: "#63c2de", backgroundColor: "#f0f3f5"}}>
                  <FaComments data-toggle="popover" data-content="Disabled popover" />
                </Avatar> }
                title={t("upload.pharma.comment")}
              />
              <TextareaAutosize
                className="form-control"
                minRows={3}
                placeholder={t("textarea.placeholder")}
                value={comment}
                type='text'
                onChange = {(event)=>setComment(event.target.value)}
                data-cy="textareaaddcomment"
              />
              <Grid item container justifyContent='center'>
                {getSubmitButton(pageToShow)}
              </Grid>
              <InsuranceAcceptDialog
                open={open}
                handleClose ={() => {
                  handleInsuranceAcceptence(insuranceStatus);
                }}
                handleCancel={()=>handleCancelDialog()}
                title={t("insurance.warning.title")}
                description={t(`insurance.${insuranceStatus==="APPROVED"?"accept":"decline"}.warning.text`)}
                requested={decodedToken.requested}
              />
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  currentStudy: state.singleRequest.pubmedStudy,
  abstract: {
    isLoading: Boolean(state.loading.pubmedAbstract),
    content: state.masterdata.pubMedAbstracts,
  },
});


export default connect(mapStateToProps)(InsuranceResponseJWT);

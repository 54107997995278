import React, {useEffect, useState} from "react";
import {
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {connect, useDispatch} from "react-redux";
import {getFormNames, reset, isValid} from "redux-form";
import {
  Container,
  Card,
  CardContent,
  CardActions,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  AiFillWarning,
  FileCopy,
} from "components/icons";
import {
  fetchDoctorData,
  fetchInsuranceCompaniesData,
} from "store/middleware/masterdataThunk";
import {
  resetMasterdataStore,
} from "store/features/masterdataSlice";
import {
  fetchPatientData,
  saveAs,
  saveAsDraft,
  fetchEditData,
  fetchCleanRequest,
  sendRequestToInsurance,
  requestStudiesForNewReq,
  invitePatient,
} from "store/middleware/newRequestThunk";
import {
  onValidate,
} from "store/features/newRequestSlice";

import {setBanner} from "store/features/requestListSlice";
import Backdrop from "components/Backdrop";
import DoctorDetailsForm from "./forms/DoctorDetailsForm";
import IndicationDetailsForm from "./forms/IndicationDetailsForm";
import PatientDetailsForm from "./forms/PatientDetailsForm";
import UploadForm from "./forms/UploadForm";
import ActionForm from "./forms/ActionForm";
import {Prompt} from "react-router";

const NewRequests=(props) => {
  const dispatch=useDispatch();
  const params=useLocation();
  const {t, i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const {
    formData,
    saveAs,
    editData,
    state,
    isLoadingNewRequest,
    koguType,
    documentSettings,
  }=props;
  const {id, formPage} = useParams();
  const isEdit=params.pathname.includes("edit_request");
  const isCopy=params.pathname.includes("copy_request");
  const isCopyPraluent=params.pathname.includes("copy_request_praluent");
  const [page, setPage]=useState(0);
  const [fieldsAreComplete, setFieldsAreComplete]=useState(false);
  const [stepError, setStepError]=useState([]);
  const [warnLossData, setWarnLossData]=useState(true);
  const [stepWarning, setStepWarning]=useState([]);
  const history=useHistory();
  const handleNav=() => {
    dispatch(setBanner(true));
    history.push("/requests");
  };

  const formNames=["doctorContact", "patientContact", `${koguType}_data`];
  const resetReduxFormsData=()=>getFormNames()(state).forEach((e)=>dispatch(reset(e)));
  const onAfterSave=()=>{
    resetReduxFormsData();
    handleNav();
  };

  // const isReduxFormsValid=getFormNames()(state)
  //     .reduce((a, e) => a&&isValid(e)(state), true);

  // breaks copy functionality
  const onSaveAsDraft=() =>
    dispatch(saveAsDraft(isEdit, id, formData, "DRAFT", currentLang, documentSettings, fieldsAreComplete))
        .then(onAfterSave);

  // const validateReduxForms=()=> dispatch(onValidate({
  //   "doctor": true,
  //   "patient": true,
  //   "form": koguType,
  // }));
  const saveAsDraftAndinvitePatient=() => {
    dispatch(saveAs(isEdit, id, formData, "DRAFT", currentLang, fieldsAreComplete))
        .then(async (response) => {
          if (response && response[0]) {
            await dispatch(
                invitePatient(isEdit?response[0].
                    request_id:
                    response[0].newRequestID,
                currentLang));
          }
        }).finally(onAfterSave);
  };

  const requestStudies=() => {
    dispatch(requestStudiesForNewReq(isEdit,
        id, formData, currentLang, documentSettings, fieldsAreComplete))
        .then(onAfterSave)
        .catch((error)=>console.log(error));
  };

  const sendToInsurance=(comment) => {
    // validateReduxForms();
    // if (isReduxFormsValid) {
    dispatch(sendRequestToInsurance(isEdit, id, formData,
        comment, currentLang, documentSettings, fieldsAreComplete))
        .then(onAfterSave)
        .catch((error)=>console.log(error));
    // } else {
    //   setOpen(true);
    //   setStepError(formNames.reduce((a, e)=>a.concat(!isValid(e)(state)), []));
    // }
  };

  const checkParams=() => {
    if (isCopy||isCopyPraluent ) {
      setPage(parseInt(1));
    }
    if (isEdit&&!isCopy) {
      setPage(2);
    }
  };
  useEffect(() => {
    checkParams();
    const searchParams = new URLSearchParams( window.location.href.split("?")[1]);
    const kogu_type_param = searchParams.get("kogu_type")??"migel";
    formPage&& setPage(parseInt(formPage));

    Promise.all([
      dispatch(fetchCleanRequest({kogu_type: kogu_type_param})),
      dispatch(resetMasterdataStore()),
    ]).then(()=>{
      dispatch(fetchInsuranceCompaniesData());
      dispatch(fetchPatientData());
      dispatch(fetchDoctorData());
      (isEdit||isCopy||isCopyPraluent)&&dispatch(fetchEditData(id));
    });
  }, []);

  const steps= ["doctor",
    "patient",
    "indication",
    "uploadFiles",
    "actions"].map((e, idx)=>({
    key: t(`newrequest.label.step.${e}`),
    title: window.innerWidth>768||
    page===idx? t(`newrequest.label.step.${e}`):"",
    onClick: () =>{
      if (page>idx) {
        validateStep(page);
      } else {
        validateStep(idx-1);
      }
      setPage(idx);
    },
  }));

  const getContainerClass=(idx)=>classNames({
    "d-block": page===idx,
    "d-none": page!==idx,
  });

  const validateStep=(pageNumber)=>{
    const stepWarningError=[...stepWarning];
    for (let i=0; i<=pageNumber; i++) {
      switch (i) {
        case 0:
          dispatch(onValidate({"doctor": true}));
          break;
        case 1:
          dispatch(onValidate({"patient": true}));
          break;
        case 2:
          dispatch(onValidate({"form": koguType}));
          break;
        default:
          break;
      }

      if (i!==3 && i!==4) {
        stepWarningError[i]=!isValid(formNames[i])(state);
      }

      const studyUpload=documentSettings.localFiles.filter((file)=>file.uploadtype==="study");
      const downloadStudy=documentSettings.downloadedFiles.filter((file)=>file.uploadtype==="study");
      const containsStudyDocument=documentSettings.pubmedStudy?.pmid||
    studyUpload.length>0||downloadStudy.length>0;
      if (i===3) {
        if (!containsStudyDocument &&
          koguType==="med" &&
        formData.reduxFormsSlice?.med_data?.values?.request_type!=="Inlabel") {
          stepWarningError[3]=true;
        } else {
          stepWarningError[3]=false;
        }
      }
    }
    stepError?.includes(true)&&
    setStepError(formNames.reduce((a, e)=>a.concat(!isValid(e)(state)), []));
    setStepWarning(stepWarningError);
    if (stepWarningError?.includes(true)) {
      setFieldsAreComplete(false);
    } else {
      setFieldsAreComplete(true);
    }
  };

  const handleNextStep=(nextStep)=>{
    validateStep(page);
    setPage(page+nextStep);
  };

  return <Card className="bg-white border-0 mb-3" style={{"margin": "0 auto", "width": "98%"}}>
    <Prompt
      message={t("navigate.away.prompt")}
      when={warnLossData}
    />
    <CardContent className="pb-0">
      <Backdrop open={isLoadingNewRequest} />
      <input type="hidden" value="something" />
      <div className="p-stepper">
        <Stepper nonLinear activeStep={page} alternativeLabel>
          {steps.map((e, index) =>
            <Step key={e.key} onClick={e.onClick} >
              <StepLabel {...{
                StepIconComponent: (stepWarning[index] &&!stepError[index])?(AiFillWarning):null,
                StepIconProps: (stepWarning[index] &&!stepError[index])?
                  ({color: "#ffc107",
                    fontSize: "25px",
                    error: "false",
                    completed: "false",
                    active: "false"}):{},
                error: (stepWarning[index] &&!stepError[index])?false:stepError[index],
              }}>
                {e.title}
              </StepLabel>
            </Step>)}
        </Stepper>
      </div>
      <br></br>

      <Container className={getContainerClass(0)}>
        <DoctorDetailsForm isEdit={isEdit} editData={editData} />
      </Container>
      <Container className={getContainerClass(1)}>
        <PatientDetailsForm isEdit={isEdit} editData={editData} />
      </Container>
      <Container className={getContainerClass(2)}>
        <IndicationDetailsForm
          isEdit={isEdit}
          onRequiredDrugEvents={props.onRequiredDrugEvents}
          onRequiredMigelEvents={props.onRequiredMigelEvents}
          editData={editData}
        />
      </Container>
      <Container className={getContainerClass(3)}>
        <UploadForm warning={stepWarning[3]} isEdit={isEdit} id={id} page={page} />
      </Container>
      <Container className={getContainerClass(4)} justifycontent="center">
        <ActionForm
          isEdit={isEdit}
          id={id}
          patientAgreement={props.patientAgreement===1}
          studyExists={documentSettings?.pubmedStudy?.pmid}
          requestStudies={()=>{
            setWarnLossData(false);
            requestStudies();
          }}
          sendToInsurance={(comment)=>{
            setWarnLossData(false);
            sendToInsurance(comment);
          }}
          onSaveAsDraft={()=>{
            setWarnLossData(false);
            onSaveAsDraft();
          }}
          saveAsDraftAndinvitePatient={()=>{
            setWarnLossData(false);
            saveAsDraftAndinvitePatient();
          }}
        />
      </Container>
    </CardContent>
    <CardActions sx={{paddingBottom: "1em"}}>
      <Container >
        <Button color="primary"
          hidden={page===0||
            isCopy}
          size="small"
          variant="contained"
          startIcon={<ChevronLeft />}
          onClick={()=>handleNextStep(-1) }
          className="float-left ml-4"
          data-cy="button_previous">
          {t("button.previous")}
        </Button>
        <Button size="small"
          endIcon={<ChevronRight />}
          variant="contained"
          color="primary"
          hidden={page===4||
            isCopy}
          onClick={()=>handleNextStep(1) }
          className="float-right mr-4"
          type="submit"
          data-cy="button_next">
          {t("button.next")}&nbsp;
        </Button>
        <Button size="small"
          startIcon={<FileCopy />}
          color="primary"
          data-cy="copy_button"
          variant="contained"
          hidden={!(isCopy) && !(isCopyPraluent)}
          onClick={()=>{
            setWarnLossData(false);
            onSaveAsDraft();
          }}
          className="btn-pill float-right mr-4" type="submit">
          {t("myrequests.buttons.copy")}
        </Button>
      </Container>
    </CardActions>
  </Card >;
};
const mapStateToProps=(state) => {
  let pharma_email = "";
  if (state.form.med_data &&
    state.form.med_data.values &&
    state.form.med_data.values.pharma_email) {
    pharma_email = state.form.med_data.values.pharma_email;
  }
  return {
    state,
    koguType: state?.form?.indication_wrapper?.values.kogu_type,
    documentSettings: {
      studiesMarkedForRemoval: state.singleRequest.studiesMarkedForRemoval,
      filesMarkedForRemoval: state.singleRequest.filesMarkedForRemoval,
      localFiles: state.singleRequest.localFiles,
      downloadedFiles: state.singleRequest.downloadedFiles,
      pubmedStudy: state.singleRequest.pubmedStudy,
    },
    patientAgreement: state?.form?.patientContact?.values?.patient_agreement,
    isLoadingNewRequest: state.singleRequest.isLoading||
                          state.loading.save||
                          state.loading.edit||
                          state.loading.sendToInsurance||
                          state.loading.sendStudy||
                          state.loading.invitePatient||
                          state.loading.doctor,
    editData: state.singleRequest.editData,
    pharma_email: pharma_email,
    formData: {
      reduxFormsSlice: state.form,
      caseId: state.singleRequest.caseId,

    },
  };
};
const mapDispatchToProps=(dispatch) => {
  return {
    fetchPatientData,
    saveAs,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewRequests);

import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  useLocation,
} from "react-router-dom";

import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";

import {
  onChangeLynparzaValue,
  selectLynparzaValues,
} from "./LynparzaSlice";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
} from "../../../medicine/medicineSlice";
import {
  setStudyById,
  removeStudy,
} from "pages/Wizard/wizardSlice";
import {
  onChangeTreatmentValue,
} from "../../../Treatment/treatmentPlanSlice";
import {
  onChangeSpecialMedValue,
  SpecialMedAdditionalInformation,
} from "../../specialMedSlice";

const LynparzaForm = (props) => {
  const {t} = useTranslation();
  const dispatch = UseAppDispatch();
  const LynparzaValues = UseAppSelector(selectLynparzaValues);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const handleSpecialMedValueChange = (field:string, value:any)=>dispatch(onChangeSpecialMedValue({field, value}));
  const params=useLocation();
  useEffect(() => {
    const regex = /\d+$/;
    const isEditId = regex.test(params.pathname);
    console.debug("EnhertuForm isEditId", isEditId);
    if (!isEditId) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, []);


  useEffect(() => {
    dispatch(onChangeLynparzaValue({formData: {variant: ""}}));
    dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
    dispatch(removeStudy({}));
    handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);


    if (
      LynparzaValues.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma === true &&
      LynparzaValues.paola1BRCAMorHRDInCombinationWithBevacizumab === true
    ) {
      dispatch(onChangeLynparzaValue({formData: {variant: "PAOLA"}}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.LYNPARZA_PAOLA);
      dispatch(onChangeMedicineValue({field: "kn_id", value: "20852.02"}));
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: true,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
      dispatch(setStudyById({studyId: "37211045"}));
    } else if (
      LynparzaValues.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma === true &&
      LynparzaValues.solo1BRCAm === true
    ) {
      dispatch(onChangeLynparzaValue({formData: {variant: "SOLO"}}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.LYNPARZA_SOLO);

      dispatch(onChangeMedicineValue({field: "kn_id", value: "20852.01"}));
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: true,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
      dispatch(setStudyById({studyId: "36082969"}));
    } else if (
      LynparzaValues.olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom === true
    ) {
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.LYNPARZA_OLYMPIA);
      dispatch(onChangeLynparzaValue({formData: {variant: "OlympiA"}}));
      dispatch(onChangeMedicineValue({field: "kn_id", value: "20852.04"}));
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: true,
            specialist_info: true,
          },
      ));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
      dispatch(setStudyById({studyId: "34081848"}));
    }
  },
  [
    LynparzaValues.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma,
    LynparzaValues.paola1BRCAMorHRDInCombinationWithBevacizumab,
    LynparzaValues.default,
    LynparzaValues.solo1BRCAm,
    LynparzaValues.olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom,
  ]);


  return <>

    <Grid item xs={12}>
      <FormControl>
        <FormLabel>{t("lynparza.options")}</FormLabel>
        <RadioGroup
          onChange={(event, value) => {
            if (value === "default") {
              dispatch(onChangeLynparzaValue({formData: {default: true}}));
              dispatch(onChangeLynparzaValue({formData: {newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma: false}}));
              dispatch(onChangeLynparzaValue({formData: {olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom: false}}));
            } else if (value === "newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma") {
              dispatch(onChangeLynparzaValue({formData: {default: false}}));
              dispatch(onChangeLynparzaValue({formData: {newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma: true}}));
              dispatch(onChangeLynparzaValue({formData: {olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom: false}}));
            } else if (value === "olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom") {
              dispatch(onChangeLynparzaValue({formData: {default: false}}));
              dispatch(onChangeLynparzaValue({formData: {newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma: false}}));
              dispatch(onChangeLynparzaValue({formData: {olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom: true}}));
            }
          }}>
          <FormControlLabel
            value="newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma"
            control={<Radio
              checked={LynparzaValues.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma}
              data-cy="newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma" />}
            label={t("lynparza.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma")}
          />
          <FormControlLabel
            value="olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom"
            control={<Radio
              checked={LynparzaValues.olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom}
              data-cy="olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom" />}
            label={t("lynparza.olympiaGBRCAMHER2FruehesHochrisikoMammakarzinom")}
          />
          <FormControlLabel
            value="default"
            control={<Radio
              checked={LynparzaValues.default}
              data-cy="default" />}
            label={t("lynparza.default")}
          />
        </RadioGroup>
      </FormControl>
      <div
        hidden={
          !(LynparzaValues.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma === true)}>

        <FormLabel>{t("lynparza.highGradeSerousOvarianCarcinomaTreatmentProtocol")}</FormLabel>
        <RadioGroup
          onChange={(event, value) => {
            if (value === "paola1BRCAMorHRDInCombinationWithBevacizumab") {
              dispatch(onChangeLynparzaValue({formData: {paola1BRCAMorHRDInCombinationWithBevacizumab: true}}));
              dispatch(onChangeLynparzaValue({formData: {solo1BRCAm: false}}));
            } else if (value === "solo1BRCAm") {
              dispatch(onChangeLynparzaValue({formData: {paola1BRCAMorHRDInCombinationWithBevacizumab: false}}));
              dispatch(onChangeLynparzaValue({formData: {solo1BRCAm: true}}));
            }
          }}>
          <FormControlLabel
            value="paola1BRCAMorHRDInCombinationWithBevacizumab"
            control={<Radio
              checked={LynparzaValues.paola1BRCAMorHRDInCombinationWithBevacizumab}
              data-cy="paola1BRCAMorHRDInCombinationWithBevacizumab" />}
            label={t("lynparza.paola1BRCAMorHRDInCombinationWithBevacizumab")}
          />
          <FormControlLabel
            value="solo1BRCAm"
            control={<Radio
              checked={LynparzaValues.solo1BRCAm}
              data-cy="solo1BRCAm" />}
            label={t("lynparza.solo1BRCAm")}
          />
        </RadioGroup>
        <div hidden={!(
          LynparzaValues.newlyDiagnosedAdvancedHighGradeSerousOvarianCarcinoma === true &&
        (
          LynparzaValues.paola1BRCAMorHRDInCombinationWithBevacizumab !== true &&
          LynparzaValues.solo1BRCAm !== true
        )
        )
        }>
          <Box sx={{m: 1}}/>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="left"
              gap={1}
            >
              <WarningIcon color={"warning"}></WarningIcon>
              <Typography>{t("lynparza.warning.regime")}</Typography>
            </Stack>
          </Grid>
        </div>

      </div>
      <div
        hidden={
          !(LynparzaValues.default === true)}>

        <Box sx={{m: 1}}/>
        <Typography >{t("lynparza.details.text")}</Typography>
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          InputLabelProps={{shrink: true}}
          data-cy="preTreatment"
          label={t("lynparza.defaultForm.preTreatment")}
          value={LynparzaValues.defaultForm?.preTreatment}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeLynparzaValue(
                {
                  preTreatment: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          multiline
          style={{width: "100%"}}
          label={t("lynparza.defaultForm.patientCondition")}
          InputLabelProps={{shrink: true}}
          value={LynparzaValues.defaultForm?.patientCondition }
          variant="outlined"
          maxRows={4}
          minRows={4}
          onChange = {(event ) =>
            dispatch(onChangeLynparzaValue(
                {
                  patientCondition: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("lynparza.defaultForm.reasonsAlternativeTherapiesFailed")}
          InputLabelProps={{shrink: true}}
          value={LynparzaValues.defaultForm?.reasonsAlternativeTherapiesFailed }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeLynparzaValue(
                {
                  reasonsAlternativeTherapiesFailed: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("lynparza.defaultForm.prognosis")}
          InputLabelProps={{shrink: true}}
          value={LynparzaValues.defaultForm?.prognosis }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeLynparzaValue(
                {
                  prognosis: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

      </div>
    </Grid>
    <Grid item xs={12}>
      {
        LynparzaValues.variant !=="" && LynparzaValues.variant !=="  " && LynparzaValues.default === false ?
          <FormLabel >{t("variante")}:&nbsp;{LynparzaValues.variant}&nbsp;</FormLabel> :
        <></>
      }
    </Grid>

  </>;
};


const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LynparzaForm);



import {VALIDATIONERROR} from "pages/Wizard/interfaces";

export const validateField = (field: string, value: string|Date)=>{
  console.debug("validateField", "field", field);
  switch (field) {
    case "patient_email_addr":
      console.debug("validateField", "patient_email", value);
      if (!Boolean(value)) {
        return VALIDATIONERROR.NONE;
      } else {
        const regEx = new RegExp(/^\S*@?\S*$/); // NOSONAR
        const isValid = regEx.test(value.toString());
        if (isValid) {
          return VALIDATIONERROR.NONE;
        } else {
          return VALIDATIONERROR.EMAIL_FORMAT;
        }
      }
    case "patient_firstname":
    case "patient_lastname":
      return String(value).length>0? VALIDATIONERROR.NONE:VALIDATIONERROR.REQUIRED;
    default:
      return null;
  }
};

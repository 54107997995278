import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface IEnhertuState {
  formData: {
    her2pos: boolean,
    trastuzumabTaxan: boolean,
    trastuzumabEmtansin: boolean,
    her2low: boolean,
    other: boolean,
    defaultForm: {
      preTreatment: string
      patientCondition: string,
      prognosis: string,
      reasonsAlternativeTherapiesFailed: string,
    },
  };
}
const initialState:IEnhertuState = {
  formData: {
    her2pos: false,
    trastuzumabTaxan: false,
    trastuzumabEmtansin: false,
    her2low: false,
    other: false,
    defaultForm: {
      preTreatment: "",
      patientCondition: "",
      prognosis: "",
      reasonsAlternativeTherapiesFailed: "",
    },
  },
};

const EnhertuFormSlice = createSlice({
  name: "Enhertu",
  initialState,
  reducers: {
    onReceiveSpecialMedEnhertuEditData: (state, {payload}: PayloadAction<any>) => {
      console.debug("onReceiveSpecialMedEnhertuEditData", payload);
      state.formData =payload?.formData;
    },
    onClearEnhertuState: (state)=>{
      state.formData=initialState.formData;
    },
    onChangeEnhertuValue: (state, {payload}: PayloadAction<any>) => {
      if (payload.formData?.her2low === true || payload.formData?.her2low === false) {
        state.formData.her2low = payload.formData?.her2low;
      }
      if (payload.formData?.her2pos === true || payload.formData?.her2pos === false) {
        state.formData.her2pos = payload.formData?.her2pos;
      }
      if (payload.formData?.other === true || payload.formData?.other === false) {
        state.formData.other = payload.formData?.other;
      }
      if (payload.formData?.trastuzumabTaxan === true || payload.formData?.trastuzumabTaxan === false) {
        state.formData.trastuzumabTaxan = payload.formData?.trastuzumabTaxan;
      }
      if (payload.formData?.trastuzumabEmtansin === true || payload.formData?.trastuzumabEmtansin === false) {
        state.formData.trastuzumabEmtansin = payload.formData?.trastuzumabEmtansin;
      }

      if (state.formData.defaultForm === undefined) {
        state.formData.defaultForm = {
          preTreatment: "",
          patientCondition: "",
          prognosis: "",
          reasonsAlternativeTherapiesFailed: "",
        };
      }

      if (payload.preTreatment || payload.preTreatment === "") state.formData.defaultForm.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.defaultForm.patientCondition = payload?.patientCondition;
      if (payload.prognosis || payload.prognosis === "") state.formData.defaultForm.prognosis = payload.prognosis;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.defaultForm.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;


      console.debug("formData", {
        her2pos: state.formData?.her2pos,
        trastuzumabTaxan: state.formData?.trastuzumabTaxan,
        trastuzumabEmtansin: state.formData?.trastuzumabEmtansin,
        her2low: state.formData?.her2low,
        other: state.formData?.other,
        preTreatment: state.formData?.defaultForm?.preTreatment,
        patientCondition: state.formData?.defaultForm?.patientCondition,
        prognosis: state.formData?.defaultForm?.prognosis,
        reasonsAlternativeTherapiesFailed: state.formData?.defaultForm?.reasonsAlternativeTherapiesFailed,

      });
    },
  },
});

export const {
  onReceiveSpecialMedEnhertuEditData,
  onClearEnhertuState,
  onChangeEnhertuValue,
} = EnhertuFormSlice.actions;

export default EnhertuFormSlice.reducer;
export const selectEnhertuValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.enhertuForm.formData;


import React, {useState, useEffect, useRef} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  TextField,
  Typography,
  CardActions,
  CardContent,
  Tooltip,
  Card,
  IconButton,
  CardHeader,
} from "@mui/material";
import {Send} from "components/icons";
import {useTranslation} from "react-i18next";
import Overlay from "react-overlay-component";
import PDFViewer from "components/PDFViewer";
import {ArrowBack} from "@mui/icons-material";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import {formatTimeDifference} from "components/formatting.js";
import {
  useDispatch,
  connect,
} from "react-redux";
import {
  setCommunicationMessage,
  showDocumentInsideCommunication,
  fetchCommunication,
  setMessagesToStatusRead,
} from "../../../store/middleware/communicationThunk";


import {setFilesAvailability, setSelectedCommunication} from "../../../store/features/communicationSlice";
import {useParams, useHistory} from "react-router-dom";

const useStyles = makeStyles({
  messageTextRight: {
    "float": "right",
    "width": "100%",
    "margin-right": "20px",
    "position": "relative",
    "display": "inline-block",
    "padding": "10px",
    "background": "#f0f3f5",
    "borderRadius": ".4em",
    "color": "#23282c",
    "fontWeight": "bold",
    "&:before": {
      content: "''",
      position: "absolute",
      right: "0",
      top: "50%",
      width: "0",
      height: "0",
      border: "20px solid transparent",
      borderLeftColor: "#f0f3f5",
      borderRight: "0",
      borderTop: "0",
      marginTop: "-10px",
      marginRight: "-20px",
    },
  },
  messageTextLeft: {
    "margin-left": "20px",
    "width": "100%",
    "text-align": "start",
    "float": "left",
    "position": "relative",
    "display": "inline-block",
    "padding": "10px",
    "background": "#58b6867d",
    "borderRadius": ".4em",
    "color": "#23282c",
    "fontWeight": "bold",
    "&:before": {
      content: "''",
      position: "absolute",
      left: "0",
      top: "50%",
      width: "0",
      height: "0",
      border: "20px solid transparent",
      borderRightColor: "#58b6867d",
      borderLeft: "0",
      borderTop: "0",
      marginTop: "-10px",
      marginLeft: "-20px",
    },
  },
});
const Chat = (
    {selectedConversation,
      communicationList,
      files,
      filesStatus,
    },
) => {
  const {id}=useParams();
  const [fieldValue, setFieldValue] = useState("");
  const [fileName, setFileName]=useState("");
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const history=useHistory();
  const closeOverlay = () => dispatch(setFilesAvailability(false));
  const configs = {
    animate: true,
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
  };

  const handleFieldValue = (value) => {
    setFieldValue(value);
  };

  useEffect(async () => {
    if (id && parseInt(id) > 0 && communicationList?.length === 0) {
      // direct link, so load the communication
      await Promise.all(
          [dispatch(fetchCommunication())],
      );
      dispatch(setMessagesToStatusRead(id));
    } else if (id && parseInt(id) > 0 && communicationList?.length > 0) {
      // all good, we should change if the user changed the comm id
      if (selectedConversation && selectedConversation.conversation_id !== parseInt(id)) {
        communicationList?.forEach(async (communicationItem) => {
          if (communicationItem.conversation_id === parseInt(id)) {
            await dispatch(setSelectedCommunication(communicationItem));
          }
        });
      }
      // we should set the messages to status read:
      dispatch(setMessagesToStatusRead(id));
    } else {
      history.push("/communication");
    }
  }, [id]);

  useEffect(async () => {
    console.debug("[CHAT] communicationList changed", communicationList?.length);
    if (selectedConversation === undefined) {
      await communicationList?.forEach(async (communicationItem) => {
        if (communicationItem.conversation_id === parseInt(id)) {
          await dispatch(setSelectedCommunication(communicationItem));
        }
      });
    }
  }, [communicationList]);

  const handleSentComment = () => {
    dispatch(setCommunicationMessage(fieldValue, selectedConversation.conversation_id)).then(() => {
      setFieldValue("");
      scrollToBottom();
    });
  };
  const showDocuments=(file)=>{
    dispatch(showDocumentInsideCommunication(selectedConversation.reference_id));
    setFileName(file);
  };

  const {t}=useTranslation();
  const classes = useStyles();

  return (selectedConversation) ?
   <div>
     <Grid container>
       <Grid item xs={12}>
         <Card>
           <CardHeader
             action={
               <Tooltip title={t("button.previous")} arrow>
                 <IconButton
                   onClick={() => {
                     history.push("/communication");
                   }}
                   size="large">
                   <ArrowBack color="primary" fontSize="large"/>
                 </IconButton>
               </Tooltip>
             }
             title={
               <Typography component="span" display="inline">{t("communication.patient_name")}:&nbsp;{selectedConversation.patient_name}</Typography>
             }
             subheader={
              (selectedConversation &&
               selectedConversation.participants &&
               Array.isArray(selectedConversation.participants) &&
               selectedConversation.participants.length > 0 &&
               selectedConversation.participants[0].name > 0) ?
              <div>
                <span>
                  <span>{t("communication.participant")}: </span>
                  {
                    Array.isArray(selectedConversation) && selectedConversation?.map((participant, participant_index) => {
                      return (<span key={participant_index+1}>
                        {participant.name};&nbsp;
                      </span>);
                    })
                  }
                </span>
              </div> : <div></div>
             }
           />
           <CardContent>
             <Grid container spacing={0}>
               {selectedConversation.messages?.map((item, index) => (
                 <Grid item xs={12} container justifyContent={
                  // decide if left or right by participant
                  (item.participant_reference_type === "USER")?"flex-end":"flex-start"
                 }
                 key={index}
                 >
                   <Card style={{border: "none", boxShadow: "none"}}>
                     <CardContent className={(
                        item.participant_reference_type === "USER") ?
                        classes.messageTextRight : classes.messageTextLeft}>
                       <div>
                         {item?.message?.includes("fileName:") ? <span>{t("file.added")}</span> : <p style={{"paddingLeft": "20px", "wordWrap": "break-word"}}>{item.action ? t(item.action) : item.message}</p>}
                       </div>
                     </CardContent>
                     <CardHeader style={(item.participant_reference_type === "USER") ? {"padding": "1px"} : {"paddingLeft": "20px"}}
                       subheader={<span>{formatTimeDifference(t, item.created_date)}&nbsp;{t("communication.from")}&nbsp;{item.participant}</span>}
                       action={
                        item?.message?.includes("fileName:") ?
                        <IconButton
                          data-cy='chat_open_pdf'
                          aria-label="settings"
                          onClick={() => showDocuments(item?.message?.split("fileName:")[1])}
                          size="large"

                        >
                          <PictureAsPdf/>
                        </IconButton> : ""
                       }
                     />
                   </Card>
                 </Grid>
               ))}
             </Grid>
             <div ref={messagesEndRef} style={{"display": "none"}}/>
           </CardContent>
           <CardActions>
             <Grid className="d-flex" item xs>
               <TextField
                 onChange={(e) => handleFieldValue(e.target.value)}
                 value={fieldValue}
                 multiline
                 minRows={2}
                 data-cy="answer_input"
                 label={t("type.something")}
                 fullWidth
                 id="answer_input"
               />
               <Tooltip title={t("disclose.details.title")} arrow>
                 <span>
                   <IconButton color="primary" size="large" data-cy="send_button" disabled={fieldValue?.trim().length===0} onClick={handleSentComment}><Send /></IconButton>
                 </span>
               </Tooltip>
             </Grid>
           </CardActions>
         </Card>
       </Grid>
     </Grid>
     <Overlay configs={configs} closeOverlay={closeOverlay} isOpen={filesStatus} className="overlay-pdf">
       {files.length > 0 ? files.map((file, idx) => {
         if (file.fileName===fileName) {
           return (
             <PDFViewer
               key={idx}
               src={file.file}
             />
           );
         }
       }) : ""}
     </Overlay>
   </div> :
  <Grid container justifyContent="center" >

  </Grid>;
};


const mapStateToProps=(state) => {
  return {
    state,
    communicationList: state.communication.communicationList,
    dialogValue: state.communication.chatMessageList,
    selectedConversation: state.communication.selectedConversation,
    isLoadingMessagesOfCommunication: state.loading.messageOfCommunication,
    isLoadingCommunication: state.loading.communication === true,
    files: state.communication.files,
    filesStatus: state.communication.filesStatus,
  };
};

export default connect(mapStateToProps)(Chat);



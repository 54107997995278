import React, {ReactEventHandler} from "react";
import {
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import {countries} from "components/formUtils";
import {useTranslation} from "react-i18next";

type inputPropType={
    value:any,
    onChange:ReactEventHandler,
    name:string,
}

type CountryDropdownProps={
    input:inputPropType,
    touched:boolean,
    language:string,
    label:string,
    name:string,
    required:boolean,
    meta: {error:string,
        visited:boolean}
}

export const CountryDropdown=({
  input,
  touched,
  language,
  label,
  name,
  required,
  meta: {visited,
    error},
}:CountryDropdownProps) =>{
  const {t}=useTranslation();

  return <Autocomplete
    autoHighlight
    autoComplete
    options={countries.filter((e)=>e.lang===language)
    // .map((e)=>e.code)
    }
    getOptionLabel={(option:any) => {
      return option.code?option.code:option;
    }}
    renderOption={(props, option) => (
      <Box component="li" sx={{"& > img": {mr: 2, flexShrink: 0}}} {...props}>
        <img
          loading="lazy"
          width="20"
          // TODO_AnGh: Does Germany need to be D and not DE?
          src={`https://flagcdn.com/w20/${option.code==="D"?"de":option.code.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${option.code==="D"?"de":option.code.toLowerCase()}.png 2x`}
          alt=""
        />
        {option.code} {option.label}
      </Box>
    )}
    isOptionEqualToValue={(option:any, value:any)=>
      option?.code===value||option?.code===value.code||value===""}
    value={input.value}
    onChange={(event, value)=>{
      input.onChange(value.code);
    }}
    // {...input}
    renderInput={(params) => (
      <TextField
        {...params}
        required={required}
        label={label}
        variant="standard"
        error={(touched||visited)&&error? true:false}
        helperText={(touched||visited)&&error? t(error):null}
        inputProps={{
          ...params.inputProps,
          "data-cy": input.name,
          "autoComplete": "new-password", // disable autocomplete and autofill
        }}
      />
    )}
  />;
};

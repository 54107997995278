import React from "react";
import ErrorBoundary from "layout/ErrorBoundary";
import {useTranslation} from "react-i18next";
import {
  Grid,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

export const Statistics = (props) => {
  const {t} = useTranslation();
  return (
    <ErrorBoundary >
      {
        props.statistics?.PersonalStatistics ? (
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} md={6} style={{display: "flex"}}>
              <Card style={{width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" height={"70px"} alignItems='center' display='flex' justifyContent='center'>
                    {t("statistics.Reimbursement")}
                  </Typography>
                  <table className="table table-borderless table-sm ">
                    <tbody>
                      <tr>
                        <td><h1>{props.statistics.PersonalStatistics?.NumberOfReimbursementClaims}</h1></td>
                        <td><h1>{props.statistics.PersonalStatistics?.ReimbursementClaimsSent}</h1></td>
                        <td><h1>{props.statistics.PersonalStatistics?.ReimbursementDraft}</h1></td>
                      </tr>
                      <tr>
                        <td className="text-muted" height={"70px"}>
                          {t("statistics.gesamt")}
                        </td>
                        <td className="text-muted" height={"70px"}>
                          {t("statistics.gesendet")}
                        </td>
                        <td className="text-muted" height={"70px"}>
                          {t("statistics.draft")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    {t("statistics.since")}&nbsp;{props.statistics.GlobalStatistics.StartOfStatistics}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3} style={{display: "flex"}}>
              <Card style={{width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" height={"70px"} alignItems='center' display='flex' justifyContent='center'>
                    {t("statistics.studies")}
                  </Typography>
                  <table className="table table-borderless table-sm ">
                    <tbody>
                      <tr>
                        <td><h1>{props.statistics.PersonalStatistics?.ReimbursementStudiesWaiting}</h1></td>
                        <td><h1>{props.statistics.PersonalStatistics?.ReimbursementStudiesRecieved}</h1></td>
                      </tr>
                      <tr>
                        <td className="text-muted" height={"70px"}>
                          {t("statistics.StudiesStudiesWaiting")}
                        </td>
                        <td className="text-muted" height={"70px"}>
                          {t("statistics.StudiesStudiesRecieved")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    {t("statistics.since")}&nbsp;{props.statistics.GlobalStatistics.StartOfStatistics}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3} style={{display: "flex"}}>
              <Card style={{width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" height={"70px"} alignItems='center' display='flex' justifyContent='center'>
                    {t("statistics.NumberOfDistinctDiagnosis")}
                  </Typography>
                  <table className="table table-borderless table-sm ">
                    <tbody>
                      <tr>
                        <td><h1>{props.statistics.PersonalStatistics?.NumberOfDistinctDiagnosis}</h1></td>
                      </tr>
                      <tr>
                        <td className="text-muted" height={"70px"}>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                  <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    {t("statistics.since")}&nbsp;{props.statistics.GlobalStatistics.StartOfStatistics}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <div />
        )}
    </ErrorBoundary>
  );
};

import React from "react";
import {
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import {delay} from "components/formUtils";
import {useTranslation} from "react-i18next";
export const AutocompleteField=({
  needsDelay,
  input,
  name,
  touched,
  renderOption,
  optionFormatting,
  getOptionLabel,
  label,
  required,
  variant,
  options,
  onSelectObject,
  hidden,
  isLoading,
  autoFocus,
  filterCallback,
  meta: {error,
    visited}}) => {
  const displayOptions = filterCallback?options?.filter((e)=>filterCallback(e, input.value)):options;
  let hidecircular = true;
  if (isLoading && isLoading === true) {
    hidecircular = false;
  }
  const {t}=useTranslation();
  return <div
    hidden={hidden}
  >
    <Autocomplete
      autoHighlight
      freeSolo
      options={displayOptions??[]}
      // Triggering the onSelectObject action only when selecting from the options
      onChange={(event, value, reason, details)=>{
        if (reason=="selectOption"&&onSelectObject) {
          onSelectObject(details.option);
          typeof optionFormatting === "function" ??input.onBlur(optionFormatting(details.option));
        }
      }}
      // How it will be displayed in the field
      getOptionLabel={getOptionLabel??((item)=>item)}
      value={input.value}
      defaultValue={input.defaultValue}
      renderInput={(params) => {
        return (
          <span>
            <CircularProgress hidden={hidecircular} color="inherit" size={20} />
            <TextField
              variant="standard"
              autoFocus={autoFocus}
              {...params}
              {...input}
              onChange={(e)=>needsDelay?delay(()=>input.onChange(e), 500):input.onChange(e)}
              inputProps={{
                ...params.inputProps,
                "autoComplete": "new-password",
                // https://stackoverflow.com/questions/60689757/how-to-disable-chrome-autofill-after-2020
                "data-cy": input.name,
              }}
              error={(touched||visited)&&t(error)? true:false}
              helperText={(touched||visited)&&t(error)? t(error):null}
              required={required}
              label={label}
            />
          </span>
        );
      }}
    />
  </div>;
};

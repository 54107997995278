import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface IbranceState {
  formData: {
    variant: string
    default: boolean,
    aromataseCombi: boolean,
    aromataseCombi_Letrozol: boolean, // aromataseCombi == true
    aromataseCombi_2LOther: boolean, // aromataseCombi == true
    aromataseCombi_2LOtherText: string, // aromataseCombi == true
    fulvestrantCombi: boolean,
    fulvestrantCombi_1l: boolean, // fulvestrantCombi == true
    fulvestrantCombi_2l: boolean, // fulvestrantCombi == true
    defaultForm: {
      preTreatment: string
      patientCondition: string,
      prognosis: string,
      reasonsAlternativeTherapiesFailed: string,
    },
  }
}

const initialState:IbranceState = {
  formData: {
    variant: "",
    default: false,
    aromataseCombi: false,
    aromataseCombi_Letrozol: false, // aromataseCombi == true
    aromataseCombi_2LOther: false, // aromataseCombi == true
    aromataseCombi_2LOtherText: "", // aromataseCombi == true

    fulvestrantCombi: false,
    fulvestrantCombi_1l: false, // fulvestrantCombi == true
    fulvestrantCombi_2l: false, // fulvestrantCombi == true

    defaultForm: {
      preTreatment: "",
      patientCondition: "",
      prognosis: "",
      reasonsAlternativeTherapiesFailed: "",
    },
  },
};
function updatestate(payload: any, state) {
  if (payload.formData?.variant || payload.formData?.variant === "") {
    state.formData.variant = payload.formData?.variant;
  }
  if (payload.formData?.default === true) {
    state.formData.default = true;
    state.formData.aromataseCombi = false;
    state.formData.aromataseCombi_Letrozol = false;
    state.formData.aromataseCombi_2LOther = false;
    state.formData.aromataseCombi_2LOtherText = "";
    state.formData.fulvestrantCombi = false;
    state.formData.fulvestrantCombi_1l = false;
    state.formData.fulvestrantCombi_2l = false;
  }
  if (payload.formData?.aromataseCombi === true) {
    state.formData.aromataseCombi = true;
    state.formData.default = false;
    state.formData.fulvestrantCombi = false;
    state.formData.fulvestrantCombi_1l = false;
    state.formData.fulvestrantCombi_2l = false;
  }
  if (payload.formData?.fulvestrantCombi === true) {
    state.formData.default = false;
    state.formData.fulvestrantCombi = true;
    state.formData.aromataseCombi = false;
    state.formData.aromataseCombi_Letrozol = false;
    state.formData.aromataseCombi_2LOther = false;
    state.formData.aromataseCombi_2LOtherText = "";
  }

  if (payload.formData?.aromataseCombi_Letrozol === true) {
    state.formData.aromataseCombi_Letrozol = true;
    state.formData.aromataseCombi_2LOther = false;
  }
  if (payload.formData?.aromataseCombi_2LOther === true) {
    state.formData.aromataseCombi_Letrozol = false;
    state.formData.aromataseCombi_2LOther = true;
  }

  if (payload.formData?.fulvestrantCombi_1l === true) {
    state.formData.fulvestrantCombi_1l = true;
    state.formData.fulvestrantCombi_2l = false;
  }
  if (payload.formData?.fulvestrantCombi_2l === true) {
    state.formData.fulvestrantCombi_1l = false;
    state.formData.fulvestrantCombi_2l = true;
  }
  if (payload.aromataseCombi_2LOtherText || payload.aromataseCombi_2LOtherText === "") {
    state.formData.aromataseCombi_2LOtherText = payload.aromataseCombi_2LOtherText;
  }
}

const IbranceFormSlice = createSlice({
  name: "Ibrance",
  initialState,
  reducers: {
    onClearIbranceState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedIbranceEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state);

      if (payload.formData?.defaultForm) {
        state.formData.defaultForm.preTreatment =
          payload.formData?.defaultForm?.preTreatment;
        state.formData.defaultForm.patientCondition =
          payload.formData?.defaultForm?.patientCondition;
        state.formData.defaultForm.reasonsAlternativeTherapiesFailed =
          payload.formData?.defaultForm?.reasonsAlternativeTherapiesFailed;
        state.formData.defaultForm.prognosis =
          payload.formData?.defaultForm?.prognosis;
      }
    },
    onChangeIbranceValue: (state, {payload}: PayloadAction<any>) => {
      if (payload.preTreatment || payload.preTreatment === "") state.formData.defaultForm.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.defaultForm.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.defaultForm.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.defaultForm.prognosis = payload.prognosis;
      if (payload.aromataseCombi_2LOtherText || payload.aromataseCombi_2LOtherText === "") state.formData.aromataseCombi_2LOtherText = payload.aromataseCombi_2LOtherText;
      updatestate(payload, state);
    },
  },
});


export const {
  onReceiveSpecialMedIbranceEditData,
  onClearIbranceState,
  onChangeIbranceValue,
} = IbranceFormSlice.actions;

export default IbranceFormSlice.reducer;
export const selectIbranceValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.ibranceForm?.formData;



import React from "react";
import {Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody} from "@mui/material";

import {formatDateDisplay} from "components/formatting";
import {useTranslation} from "react-i18next";
import {saxendaTableColumnsType, saxendaTableRowsType} from "../../../../../../store/rootstate";

type PatientTableProps={
  patientDiary:any,

}


export const SaxendaTable=({patientDiary}:PatientTableProps)=>{
  const {t}=useTranslation();

  const numberOfPatientDataEntries=patientDiary.length-1;
  const saxendaTableColumnNames:Array<saxendaTableColumnsType>=[
    {id: "datum", label: t("Datum"), minWidth: 170},
    {id: "dosis", label: t("Dosis"), minWidth: 170},
    {id: "height", label: t("Height"), minWidth: 170},
    {id: "weight", label: t("Weight"), minWidth: 170},
    {id: "bmi", label: t("BMI"), minWidth: 170},
  ];

  const computeBmi=(weight:string, height:string)=>{
    return (parseInt(weight)/(parseInt(height)*parseInt(height)/10000)).toFixed(1).toString();
  };

  const createData=(datum:string, dosis:string, height:string, weight:string)=> {
    const bmi= computeBmi(weight, height);
    return {datum, dosis, height, weight, bmi};
  };
  const rowExample:Array<saxendaTableRowsType>=patientDiary?.map((row:any)=>
    createData(formatDateDisplay(row.date), row.dose, row.height, row.weight));


  return (
    <Grid item container xs={12} spacing={3} data-cy="saxenda_data">
      <Grid item xs={12} >
        <Typography>{t("saxenda.patient.table.desctiprion")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("saxenda.patient.start.BMI")}: {computeBmi(patientDiary[numberOfPatientDataEntries].weight, patientDiary[numberOfPatientDataEntries].height)}</Typography>
        <Typography>{t("saxenda.patient.current.BMI")}: {computeBmi(patientDiary[0].weight, patientDiary[0].height)}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("saxenda.patient.start.weight")}: {patientDiary[numberOfPatientDataEntries].weight}</Typography>
        <Typography>{t("saxenda.patient.current.weight")}: {patientDiary[0].weight}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("saxenda.patient.start.height")}: {patientDiary[numberOfPatientDataEntries].height}</Typography>
        <Typography>{t("saxenda.patient.current.height")}: {patientDiary[0].height}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{t("saxenda.patient.start.date")}: {formatDateDisplay(patientDiary[numberOfPatientDataEntries].date)}</Typography>
      </Grid>
      <Grid item xs={12}>

        <TableContainer sx={{maxHeight: 300, overflowX: "hidden"}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {saxendaTableColumnNames.map((column) => (
                  <TableCell
                    key={column.id}
                    width='20%'
                    align='center'
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowExample
                  .map((row:any) => {
                    return (
                      <TableRow hover role="checkbox" key={row.BMI}>
                        {saxendaTableColumnNames.map((column:saxendaTableColumnsType) => {
                          const value = row[column.id];
                          return (
                            <TableCell width='20%' key={column.id} align={"center"}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

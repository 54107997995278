import React, {useEffect} from "react";
import {connect} from "react-redux";
import {
  reduxForm,
  change,
  Field,
  formValueSelector,
} from "redux-form";
import {useTranslation} from "react-i18next";
import {
  fieldNames,
  formInitialValues,
  correctDateFormat,
  datepickerLimitations,
  fieldLevelValidator,
} from "components/formUtils";
import {Grid} from "@mui/material";
import {fetchICD10Data} from "store/middleware/masterdataThunk";

import {AutocompleteField} from "components/formFields/AutocompleteField";
import {TextArea} from "components/formFields/TextArea";
import {TextFieldComponent} from "components/formFields/TextFieldComponent";
import {Calendar} from "components/formFields/Calendar";
import {SegmentedButton} from "components/formFields/SegmentedButton";
import {CountryDropdown} from "components/formFields/CountryDropdown";


const ReduxFormComponentIndication=(props) => {
  const {t, i18n}=useTranslation();
  const {validDate, required}=fieldLevelValidator;
  const label_value_current_patient = t("transport.button.address.current.patient");
  const label_value_cother_patient = t("transport.button.address.other.patient");
  const label_value_current_hospital = t("transport.button.address.current.hospital");
  const label_value_cother_hospital = t("transport.button.address.other.hospital");
  const currentLang=i18n.language.toUpperCase();
  const {
    transportMode,
    diagnosisProps,
    diagnosis,
    formValues,
    editData,
    changeValue,
    needsValidation,
  }=props;
  useEffect(()=>{
    fieldNames.transport.forEach((e)=>
      changeValue(e, editData?.transport_data?.[e]??formInitialValues.transport[e]));
  }, [editData]);


  return <Grid container spacing={3} >
    <Grid item xs={12}>
      <Field name="transportMode"
        component={AutocompleteField}
        validate={[required]}
        required={true}
        touched={needsValidation}
        // renderOption={(e) => t(e)}
        getOptionLabel={(e) => t(e)??e}
        options={transportMode.options}
        label={t("transport.transport.mode")}
      />
    </Grid>
    <Grid item xs={12}>
      <Field
        name="type"
        component={SegmentedButton}
        buttons={[
          {key: "transport.button.out", label: t("transport.button.out"), value: "out"},
          {key: "transport.button.in", label: t("transport.button.in"), value: "in"},
          {key: "transport.button.round", label: t("transport.button.round"), value: "round"},
        ]}
      />
    </Grid>
    <Grid container item xs={12} spacing={3} >
      <Grid container item xs={12} lg={6}>
        <Grid item xs={12}>
          <Grid item xs={12} >
            <p className="MuiFormLabel-root py-2">{t("transport.address.patient")}</p>
          </Grid>
          <Field
            name="patient_address_custom"
            component={SegmentedButton}
            buttons={[
              {
                key: "transport.button.patient.current",
                label: label_value_current_patient, value: false,
              },
              {
                key: "transport.button.patient.other",
                label: label_value_cother_patient, value: true,
              },
            ]}
          />
        </Grid>
        {
            formValues.patient_address_custom?
        <Grid container item xs={12} spacing={3} >
          <Grid item xs={12} sm={12}>
            <Field name="patient_custom_street"
              component={AutocompleteField}
              validate={[required]}
              required={formValues.patient_address_custom}
              touched={formValues.patient_address_custom&&needsValidation}
              label={t("patient.label.street")}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Field name="patient_custom_postalcode"
              component={AutocompleteField}
              validate={[required]}
              required={formValues.patient_address_custom}
              touched={formValues.patient_address_custom&&needsValidation}
              label={t("patient.label.zip")}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Field name="patient_custom_city"
              component={AutocompleteField}
              validate={[required]}
              required={formValues.patient_address_custom}
              touched={formValues.patient_address_custom&&needsValidation}
              label={t("patient.details.city")}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Field name="patient_custom_country"
              language={currentLang}
              validate={[required]}
              required={formValues.patient_address_custom}
              touched={formValues.patient_address_custom&&needsValidation}
              component={CountryDropdown}
              label={t("patient.label.country")}
            />
          </Grid>
        </Grid>:
        null
        }
      </Grid>
      <Grid container item xs={12} lg={6} >
        <Grid item xs={12}>
          <Grid item xs={12} >
            <p className="MuiFormLabel-root py-2">{t("transport.address.clinic")}</p>
          </Grid>
          <Field
            name="clinic_address_custom"
            component={SegmentedButton}
            buttons={[
              {key: "transport.button.clinic.current", label: label_value_current_hospital, value: false},
              {key: "transport.button.clinic.other", label: label_value_cother_hospital, value: true},
            ]}
          />
        </Grid>
        {
            formValues.clinic_address_custom?
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Field name="clinic_custom_street"
              component={AutocompleteField}
              validate={[required]}
              required={formValues.clinic_address_custom}
              touched={formValues.clinic_address_custom&&needsValidation}
              label={t("patient.label.street")}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Field name="clinic_custom_postalcode"
              component={AutocompleteField}
              validate={[required]}
              required={formValues.clinic_address_custom}
              touched={formValues.clinic_address_custom&&needsValidation}
              label={t("patient.label.zip")}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Field name="clinic_custom_city"
              component={AutocompleteField}
              validate={[required]}
              required={formValues.clinic_address_custom}
              touched={formValues.clinic_address_custom&&needsValidation}
              label={t("patient.details.city")}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Field name="clinic_custom_country"
              language={currentLang}
              validate={[required]}
              required={formValues.clinic_address_custom}
              touched={formValues.clinic_address_custom&&needsValidation}
              component={CountryDropdown}
              label={t("patient.label.country")}
            />
          </Grid>
        </Grid>:
        null}
      </Grid>
    </Grid>
    <Grid container item xs={12}>
      <Grid container item xs={6} >
        <Grid item xs={12} >
          <p className="MuiFormLabel-root py-2">{t("transport.date.start.label")}</p>
        </Grid>
        <Grid item xs={12} >
          <Field name="start"
            language={currentLang}
            component={Calendar}
            validate={[required, validDate]}
            required={true}
            touched={needsValidation}
            minDate={datepickerLimitations.transport.transport_date.minDate}
            maxDate={datepickerLimitations.transport.transport_date.maxDate}
            normalize={correctDateFormat}
            label={t("transport.date.start")}
          />
        </Grid>
      </Grid>
      <Grid container item xs={6} >
        <Grid item xs={12}>
          <p className="MuiFormLabel-root py-2">{t("transport.number.transports.label")}</p>
        </Grid>
        <Grid item xs={12}>
          <Field name="transports"
            id={"transports"}
            component={TextFieldComponent}
            validate={[required]}
            required={true}
            touched={needsValidation}
            label={t("transport.number.transports")}
            type="number"
            normalize={ (value, previousValue) => value >= 0 ? value : previousValue}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Field name="diagnosis"
        needsDelay
        validate={[required]}
        required={true}
        touched={needsValidation}
        component={AutocompleteField}
        onChange={(e)=>diagnosis.fetchOptions(e, currentLang)}
        options={diagnosisProps.options}
        isLoading={diagnosisProps.isLoading}
        getOptionLabel={(item) => item?.description? `${item.code} ${item.description}`: item}
        label={t("transport.diagnosis")}
      />
    </Grid>
    <Grid item xs={12}>
      <Field name="comments"
        component={TextArea}
        type="text"
        label={t("transport.comments")}
      />
    </Grid>
  </Grid>;
};

const mapStateToProps=(state) => {
  return {
    formValues: formValueSelector("transport_data")(
        state,
        "patient_address_custom",
        "clinic_address_custom",
    ),
    transportMode: {
      options: [
        "transport.mode.sit",
        "transport.mode.lie",
        "transport.mode.wheelchair",
      ]},
    diagnosisProps: {
      isLoading: state.loading.icd10,
      options: state.masterdata?.autocompleteOptions?.icd10,
    },
    editData: state.singleRequest.editData,
    needsValidation: state.singleRequest.needsValidation.form==="transport",
  };
};

const mapDispatchToProps=(dispatch) => ({
  changeValue: (fieldName, value)=>dispatch(change("transport_data", fieldName, value)),
  diagnosis: {
    fetchOptions: (event, currentLang) => dispatch(fetchICD10Data(event.target.value, currentLang)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: "transport_data",
  initialValues: {
    clinic_custom_country: "CH",
    patient_custom_country: "CH",
  },
})(ReduxFormComponentIndication));



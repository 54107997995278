import React from "react";
import {
  FaBookMedical,
  FaFileMedical,
  FaEnvelopeOpen,
  FaHourglassHalf,
  FaHourglassStart,
  FaTimesCircle,
  FaCheckCircle,
  FaUserCheck,
} from "react-icons/fa";

export const allStates =
       [{status: "DRAFT", icon: <FaEnvelopeOpen />, color: "#c8ced3", size: "1em"}, // gray
         {status: "WAITING_FOR_STUDIES", icon: <FaFileMedical/>, color: "#63c2de"}, // info blue
         {status: "STUDIES_RECEIVED", icon: <FaBookMedical />, color: "#63c2de"}, // info blue
         {status: "PENDING_INSURANCE", icon: <FaHourglassStart/>, color: "#63c2de"}, // info blue
         {status: "INSURANCE_DOCUMENT_RECEIVED", icon: <FaBookMedical />, color: "#63c2de"}, // info blue
         {status: "PENDING_INSURANCE_RESEND", icon: <FaHourglassHalf/>, color: "#63c2de"}, // info blue
         {status: "DECLINED", icon: <FaTimesCircle/>, color: "#f86c6b"}, // danger red
         {status: "APPROVED", icon: <FaCheckCircle />, color: "#4dbd74"}, // success green
         {status: "ORDERED", icon: <FaUserCheck />, color: "#4dbd74"}, // success green
       ];

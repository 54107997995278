

export const getreCaptchaToken = async () => {
  try {
    await grecaptchaReadyPromise;
    // if localhost then
    if (window.location.hostname === "localhost") {
      return "localhost";
    } else {
      const token = await window.grecaptcha.execute("6LesoK8iAAAAANYbMgqTsXK3Zkd0T8gq_bPdNfI8", {action: "AnonymUsage"});
      return token;
    }
  } catch (e) {
    console.error(e);
  }
};
const waitForGlobal = function(callback) {
  if (window?.grecaptcha?.ready) {
    console.debug("grecaptcha ready");
    callback();
  } else {
    setTimeout(function() {
      waitForGlobal(callback);
    }, 100);
  }
};
const grecaptchaExistsPromise = new Promise((resolve, reject) => {
  waitForGlobal(function() {
    resolve("exists");
  });
});
const grecaptchaReadyPromise = new Promise(async (resolve, reject) => {
  await grecaptchaExistsPromise;
  window.grecaptcha.ready(async function() {
    resolve("ready");
  });
});



import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {Box, Grid, Divider} from "@mui/material";
import {AutocompleteField} from "components/formFields/AutocompleteField";
import {TextFieldComponent} from "components/formFields/TextFieldComponent";
import {SegmentedButton} from "components/formFields/SegmentedButton";
import {Calendar} from "components/formFields/Calendar";
import {TextArea} from "components/formFields/TextArea";
import {SelectDropdown} from "components/formFields/SelectDropdown";


import {Field, reduxForm, formValueSelector, change} from "redux-form";

import {
  breathingMasterData,
  fieldNames,
  correctDateFormat,
  fieldLevelValidator,
  datepickerLimitations,
  alphabeticalSort,
} from "components/formUtils";
import {formatToTwoDecimal} from "components/formatting";
import {
  fetchBreathingDeviceModesData,
  fetchBreathingDevicesData,
} from "store/middleware/masterdataThunk";

const ReduxFormBreathingDevice=({
  formValues,
  changeValue,
  breathingDevice,
  editData,
  needsValidation,
}) => {
  const {i18n, t}=useTranslation();
  const dispatch=useDispatch();
  const {validHour, required}=fieldLevelValidator;
  const currentLang=i18n.language.toUpperCase();

  // restore edit date if there is any
  useEffect(()=>fieldNames.breathing_device.forEach((e)=>changeValue(e, editData?.[e])), [editData]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchBreathingDeviceModesData()),
      dispatch(fetchBreathingDevicesData()),
    ]);
  }, []);

  useEffect(()=>{
    if (formValues?.request_reason==="change") {
      changeValue("change_reason", "malfunction");
    } else {
      changeValue("change_reason", "");
    }
  }, [formValues?.request_reason]);

  useEffect(() => {
    breathingDevice?.devices&&changeValue("migelPosition", breathingDevice?.devices?.find((e)=>e.name===formValues.model)?.migel_position);
  }, [formValues?.model]);

  useEffect(() => {
    changeValue("patient_bmi",
    (formValues.patient_height>30&&
    formValues.patient_height<250&&
    formValues.patient_weight>10&&
    formValues.patient_weight<1000
    )?
    (formValues.patient_weight/
      (formValues.patient_height*formValues.patient_height/10000)).toFixed(1):
      "");
  }, [formValues.patient_weight, formValues.patient_height]);


  return <Grid container spacing={3} justifyContent="center">
    <Grid container item justifyContent="center" spacing={3}>
      <Grid item xs={12} sm={12} component={Box} alignSelf='flex-start' >
        <Field name="diagnosis"
          component={AutocompleteField}
          options={breathingMasterData.hauptdiagnosis.map((e)=>(t(e))).
              filter((e)=>e!==formValues.zusatzdiagnosis)}
          validate={[required]}
          touched={needsValidation}
          required={true}
          label={t("indication.details.diagnosis.breathingDevice")}
        />
      </Grid>
      <Grid item xs={12} sm={12} component={Box} alignSelf='flex-start' >
        <Field name="zusatzdiagnosis"
          component={AutocompleteField}
          options={breathingMasterData.hauptdiagnosis.map((e)=>(t(e))).
              filter((e)=>e!==formValues.diagnosis)}
          label={t("indication.details.zusatzdiagnose")}
        />
      </Grid>
    </Grid>
    <Grid item container justifyContent='center' spacing={3}>
      <Grid item xs={12} sm={12}>
        <p className="MuiFormLabel-root py-2">{t("indication.details.diagnostik.und.therapie")}</p>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between" >
        <Grid item xs={12} sm={3} >
          <Field name="patient_weight"
            component={TextFieldComponent}
            normalize={formatToTwoDecimal}
            numericProps={{min: 0, max: 750}}
            required={true}
            validate={[required]}
            touched={needsValidation}
            type="number"
            label={t("indication.details.patientWeight")}
          />
        </Grid>
        <Grid item xs={12} sm={3} >
          <Field name="patient_height"
            component={TextFieldComponent}
            numericProps={{min: 0, max: 240}}
            required={true}
            validate={[required]}
            touched={needsValidation}
            type="number"
            label={t("indication.details.patientHeight")}
          />
        </Grid>
        <Grid item xs={12} sm={3} >
          <Field name="patient_bmi"
            disabled
            component={TextFieldComponent}
            required={true}
            validate={[required]}
            touched={needsValidation}
            type="number"
            label={t("indication.details.bmi")}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs={12} sm={3} >
          <Field name="patient_VC"
            component={TextFieldComponent}
            normalize={formatToTwoDecimal}
            numericProps={{min: -10000, max: 10000}}
            required={true}
            validate={[required]}
            touched={needsValidation}
            type="number"
            label={t("indication.details.patientVC")}
          />
        </Grid>
        <Grid item xs={12} sm={3} >
          <Field name="patient_FEV"
            component={TextFieldComponent}
            normalize={formatToTwoDecimal}
            numericProps={{min: -10000, max: 10000}}
            required={true}
            validate={[required]}
            touched={needsValidation}
            type="number"
            label={t("indication.details.patientFEV")}
          />
        </Grid>
        <Grid item xs={12} sm={3} >
          <Field name="patient_AHI"
            component={TextFieldComponent}
            normalize={formatToTwoDecimal}
            numericProps={{min: 0, max: 1000}}
            required={true}
            validate={[required]}
            touched={needsValidation}
            type="number"
            label={t("indication.details.AHI")}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Divider flexItem />
    </Grid>
    <Grid item container justifyContent='center' spacing={3} >
      <Grid item xs={12} sm={12}>
        <p className="MuiFormLabel-root py-2">{t("indication.details.therapie")}</p>
      </Grid>
      <Grid container item xs={12} >
        <Grid container item xs={12} >
          <Grid item xs={12} lg={5} >
            <Grid item xs={12}>
              <p className="MuiFormLabel-root py-2">{t("breathingDevice.angaben.home.ventilation")}</p>
            </Grid>
            <Field
              name="home_ventilation"
              component={SegmentedButton}
              buttons={[
                {key: "breathingDevice.button.not.invasiv", label: t("not.invasiv"), value: true},
                {key: "breathingDevice.button.invasiv", label: t("invasiv"), value: false},
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            <Grid item xs={12} >
              <p className="MuiFormLabel-root py-2">{t("breathingDevice.ventilation.duration")}</p>
            </Grid>
            <Field
              name="home_ventilation_duration"
              component={SegmentedButton}
              buttons={[
                {key: "breathingDevice.button.nights", label: t("nights"), value: "nights"},
                {key: "breathingDevice.button.nights.days", label: t("nights.days"), value: "nights and days"},
                {key: "breathingDevice.button.24h", label: t("24h"), value: "24h"},
              ]}
            />
          </Grid>
          <Grid container item xs={12} sm={6} lg={2} hidden={formValues?.home_ventilation_duration==="24h"}>
            <Grid item xs={12}>
              <p className="MuiFormLabel-root py-2">{t("breathingDevice.treatment_duration")}</p>
            </Grid>
            <Grid item xs={12}>
              <Field name="treatment_duration"
                component={TextFieldComponent}
                validate={[validHour]}
                touched={needsValidation}
                numericProps={{min: 0, max: 24}}
                placeholder={t("breathingDevice.hours")}
                type="number"
                normalize={ (value, previousValue) => value >= 0 ? value : previousValue}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent="space-between" >
        <Grid item xs={12} sm={6} >
          <Field name="modus"
            data-cy="modus"
            options={breathingDevice?.modes?.map((e)=>({label: e.name, value: e.name}))}
            validate={[required]}
            touched={needsValidation}
            required={true}
            component={SelectDropdown}
            label={t("breathingDevice.modus")}
          >
          </Field>
        </Grid>
        <Grid item xs={12} sm={2} >
          <Grid item xs={12} >
            <Field name="start"
              component={Calendar}
              fullWidth={true}
              validate={[required]}
              touched={needsValidation}
              required={true}
              language={currentLang}
              minDate={datepickerLimitations.breathing_device.start.minDate}
              maxDate={datepickerLimitations.breathing_device.start.maxDate}
              label={t("breathingDevice.date.start")}
              normalize={correctDateFormat}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item container justifyContent='center' >
      <Grid container item xs={12} >
        <Grid container item xs={12} >
          <Grid item xs={12} lg={6}>
            <Grid item xs={12}>
              <p className="MuiFormLabel-root py-2">{t("breathingDevice.requestReason")}</p>
            </Grid>
            <Field
              name="request_reason"
              component={SegmentedButton}
              buttons={[
                {key: "breathingDevice.button.first.device", label: t("first.device"), value: "first"},
                {key: "breathingDevice.button.second.device", label: t("second.device"), value: "second"},
                {key: "breathingDevice.button.device.change", label: t("device.change"), value: "change"},
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6} hidden={formValues?.request_reason!=="change"}>
            <Grid item xs={12} >
              <p className="MuiFormLabel-root py-2">{t("breathingDevice.changeReason")}</p>
            </Grid>
            <Field
              name="change_reason"
              component={SegmentedButton}
              buttons={[
                {key: "breathingDevice.button.malfunction", label: t("malfunction"), value: "malfunction"},
                {key: "breathingDevice.button.old.device", label: t("old.device"), value: "old"},
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Divider flexItem />
    </Grid>
    <Grid item container spacing={3} >
      <Grid container item xs={12} >
        <Grid item xs={12}>
          <p className="MuiFormLabel-root py-2">{t("breathingDevice.model")}</p>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12} sm={6} component={Box} >
            <Field name="model"
              component={SelectDropdown}
              validate={[required]}
              touched={needsValidation}
              options={breathingDevice?.devices?.map((e)=>({label: e.name, value: e.name})).sort(alphabeticalSort)}
              required={true}
              label={t("breathingDevice.details.model")}
            />
          </Grid>
          <Grid item xs={12} sm={6} component={Box} >
            <Field name="migelPosition"
              component={TextFieldComponent}
              validate={[required]}
              touched={needsValidation}
              required={true}
              disabled
              label={t("breathingDevice.details.migelPosition")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent='center' spacing={3}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12} lg={5}>
            <Grid item xs={12}>
              <p className="MuiFormLabel-root py-2">{t("breathingDevice.maskType")}</p>
            </Grid>
            <Field
              name="maskType"
              component={SegmentedButton}
              buttons={[
                {key: "breathingDevice.button.mask.nose", label: t("mask.nose"), value: "nose"},
                {key: "breathingDevice.button.mask.nosePad", label: t("mask.nosePad"), value: "nosePad"},
                {key: "breathingDevice.button.mask.fullFace", label: t("mask.fullFace"), value: "fullFace"},
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <Grid item xs={12} >
              <p className="MuiFormLabel-root py-2">{t("breathingDevice.maskSize")}</p>
            </Grid>
            <Field
              name="mask_size"
              component={SegmentedButton}
              buttons={[
                {key: "breathingDevice.button.size.s", label: t("S"), value: "S"},
                {key: "breathingDevice.button.size.m", label: t("M"), value: "M"},
                {key: "breathingDevice.button.size.l", label: t("L"), value: "L"},
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <Grid item xs={12}>
              <p className="MuiFormLabel-root py-2">{t("breathingDevice.humidifier")}</p>
            </Grid>
            <Field
              name="humidifierType"
              component={SegmentedButton}
              buttons={[
                {key: "breathingDevice.button.humidifier.integrated", label: t("integrated"), value: "integrated"},
                {key: "breathingDevice.button.humidifier.separated", label: t("separated"), value: "separated"},
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Grid item xs={12}>
              <p className="MuiFormLabel-root py-2">{t("breathingDevice.humidifier.activated")}</p>
            </Grid>
            <Field
              name="activated"
              component={SegmentedButton}
              buttons={[
                {key: "breathingDevice.button.humidifier.activated", label: t("activated"), value: "activated"},
                {key: "breathingDevice.button.humidifier.not.activated", label: t("not.activated"), value: "notActivated"},
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item container spacing={3}>
      <Grid item container xs={12} >
        <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
            <p className="MuiFormLabel-root py-2">{t("breathingDevice.praxis.spital")}</p>
          </Grid>
          <Field
            name="praxis"
            component={SegmentedButton}
            buttons={[
              {key: "breathingDevice.button.humidifier.present", label: t("present"), value: "present"},
              {key: "breathingDevice.button.humidifier.needed", label: t("needed"), value: "needed"},
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} component={Box} >
          <Field name="supplier"
            component={AutocompleteField}
            validate={[required]}
            touched={needsValidation}
            required={true}
            readOnly={true}
            label={t("breathingDevice.details.supplier")}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} sm={12} className="mt-3">
      <Field name="remarks_diagnosis"
        minRows={3}
        component={TextArea}
        type="text"
        label={t("indication.details.remarks.diagnosis")}
      />
    </Grid>
  </Grid>;
};


const mapStateToProps=(state) => {
  return {
    editData: state.singleRequest.editData.breathing_device_data,
    needsValidation: state.singleRequest.needsValidation.form==="breathing_device",
    breathingDevice: state.masterdata.breathingDevice,
    formValues: formValueSelector("breathing_device_data")(
        state,
        "patient_weight",
        "patient_height",
        "home_ventilation_duration",
        "request_reason",
        "model",
        "diagnosis",
        "zusatzdiagnosis",
    ),

  };
};
const mapDispatchToProps=(dispatch) => ({
  changeValue: (fieldName, value)=>dispatch(change("breathing_device_data", fieldName, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: false,
  form: "breathing_device_data",
  initialValues: {
    home_ventilation: true,
    home_ventilation_duration: "nights",
    request_reason: "first",
    maskType: "nose",
    mask_size: "M",
    humidifierType: "integrated",
    activated: "activated",
    praxis: "present",
    supplier: "SOS Oxygène SA",
  },
})(ReduxFormBreathingDevice));



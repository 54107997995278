import React, {ReactEventHandler} from "react";
import {
  TextField,
  Autocomplete,
} from "@mui/material";
import {useTranslation} from "react-i18next";

type inputPropType={
    value:any,
    onChange:ReactEventHandler,
    name:string,
}
type DropdownGenderProps={
    input:inputPropType,
    label:string,
    type:string,
    required:boolean,
    genderOptions:Array<string>,
    meta: {error:string,
        visited:boolean},
    touched:boolean
}

export const DropdownGender=({
  input, label, type, required, genderOptions, meta: {error, visited}, touched,
}:DropdownGenderProps) => {
  const {t}=useTranslation();

  const {value, onChange, ...otherInput}=input;
  const displayValue=genderOptions[value]? genderOptions[value]:"";
  return <Autocomplete
    autoHighlight
    freeSolo
    options={genderOptions}
    onSelect={onChange}
    value={displayValue}
    renderInput={(params) => <TextField
      variant="standard"

      {...params}
      {...otherInput}
      type={type}
      required={required}
      error={(touched||visited)&&error? true:false}
      helperText={(touched||visited)&&error? t(error):null}
      label={label} />} />;
};

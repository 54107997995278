import {createSlice} from "@reduxjs/toolkit";

const CommunicationReducer = createSlice({
  name: "CommunicationReducer",
  initialState: {
    searchQueryCommunication: "",
    communicationList: [],
    files: [],
    filesStatus: false,
    isLoadingCommunication: false,
    chatMessageList: null,
    isLoadingMessagesOfCommunication: false,
    unreadMessages: 0,
  },
  reducers: {
    receiveCommunicationHistory(state, action) {
      return {
        ...state,
        communicationList: action.payload || [],
        isError: false,
      };
    },
    communicationQueryChange(state, action) {
      return {
        ...state,
        searchQueryCommunication: action.payload,
        isLoadingCommunication: false,
        isError: false,
      };
    },
    setFilesAvailability(state, action) {
      return {
        ...state,
        filesStatus: action.payload,
      };
    },
    setFileStudies(state, action) {
      return {
        ...state,
        files: action.payload,
      };
    },
    errorServiceException(state) {
      return {
        ...state,
        isLoadingCommunication: false,
        isError: true,
      };
    },
    receiveCommunicationChat(state, action) {
      return {
        ...state,
        chatMessageList: action.payload || null,
        isError: false,
      };
    },
    setSelectedCommunication(state, action) {
      return {
        ...state,
        selectedConversation: action.payload || null,
        isError: false,
      };
    },
    setUnreadMessages(state, action) {
      return {
        ...state,
        unreadMessages: action.payload,
      };
    },
  },
});

export const {
  setUnreadMessages,
  communicationQueryChange,
  receiveCommunicationHistory,
  setSnackbarStatus,
  setFileStudies,
  setFilesAvailability,
  receiveCommunication,
  receiveCommunicationChat,
  setSelectedCommunication,
} = CommunicationReducer.actions;

export default CommunicationReducer.reducer;

import {
  get,
  post,
  postFiles,
  postScreenshot,
  parseFiles,
} from "store/api";
import {
  onReceivedEditData,
  cleanData,
  onReceivedPatientData,
  onReceivedScreenshotData,
  errorServiceException,
  setDownloadFiles,
  showBanner,
  uploadLocalFilesAction,
  addMetaDataTouploadStepperFiles,
  onAddStudy,
  removeLocalFilesAction,
  markStudiesForRemoval,
  onReceivePatientDiary,
  cleanDocumentState,
} from "../features/newRequestSlice";
import {
  fetchAdditionalDrugInfo,
} from "../middleware/masterdataThunk";
import {displayToastError, displayToastSuccess} from "../features/errorSlice";
import {fieldNames} from "components/formUtils";
import {allKoguTypes, getKoguCodesFromType} from "../../components/koguTypes";
import i18n from "./../../i18n";
import {startLoading, endLoading} from "store/features/loadingSlice";
import {trackEvent, trackError} from "AppInsights";
import {checkIsBrukinsa, checkIsPraluent} from "pages/Wizard/forms/med/AdditionalInformation/isSpecialMed";
import {onReceivedPubMedData} from "store/features/masterdataSlice";


const onError=(error, code)=>(dispatch)=>{
  error.status !== 400&&trackError(error);
  dispatch(displayToastError((i18n.t(code))));
};

export const fetchPatientData = () => (dispatch) => {
  dispatch(startLoading("patient"));
  trackEvent("requests/patientdata");
  get("requests/patientdata")
      .then((json) => {
        dispatch(onReceivedPatientData(json));
      }).catch((error) => {
        trackError(error);
        dispatch(displayToastError(i18n.t("error.patient.could.not.be.loaded")));
      }).finally(() => dispatch(endLoading("patient")));
};

export const requestStudiesReq = (id) => (dispatch) => {
  dispatch(startLoading("studies"));
  trackEvent("studies/createStudieEmail");
  console.debug("requestStudiesReq", id);
  return post("studies/createStudieEmail", {"request_id": id})
      .then((json) => {
        dispatch(showBanner(true));
        dispatch(displayToastSuccess(i18n.t("success.studies")));
        return true;
      })
      .catch((error) => {
        trackError(error);
        dispatch(displayToastError(i18n.t("error.studies")));
        return false;
      }).finally(() => dispatch(endLoading("studies")));
};

export const sendScreenshotForOcr = (img) => (dispatch) => {
  dispatch(startLoading("screenshot"));
  trackEvent("ocr/ipdos");
  return postScreenshot("ocr/ipdos", img)
      .then((json) => {
        dispatch(onReceivedScreenshotData(json));
      },
      ).catch((error) => {
        trackError(error);
        dispatch(displayToastError("ERROR_SCREENSHOT_NOT_SENT"));
      }).finally(() => dispatch(endLoading("screenshot")));
};
export const fetchCleanRequest = (data) => (dispatch) => {
  try {
    dispatch(startLoading("newrequest"));
    dispatch(cleanData(data));
  } catch {
    dispatch(displayToastError(i18n.t("error.request.unknown")));
  } finally {
    dispatch(endLoading("newrequest"));
  }
  return;
};

export const getAvailableAttachments = (id, JWTToken) => async (dispatch)=>
  Promise.all([
    dispatch(cleanDocumentState()),
    dispatch(getAvailableDocuments(id, JWTToken)),
    dispatch(getAvailableStudies(id, JWTToken)),
  ]);

export const getAvailableDocuments = (id, JWTToken) => async (dispatch)=>
  get(`documents/availableDocuments/${JWTToken?"jwt/":""}${id}/`, null, JWTToken)
      .then(async (res) =>
        Promise.all(await res.reduce((promises, file) =>
          promises.concat( get(`documents/download/${JWTToken?"jwt/":""}${id}/${encodeURIComponent(file.name)}/${file.fromExternalSource}`, "blob", JWTToken)
              .then((blob) => ({
                fileURL: window.URL.createObjectURL(blob),
                name: decodeURIComponent(file.name),
                size: file.contentLength,
                fromExternalSource: file.fromExternalSource,
                remarks: file.remarks,
                uploadtype: file.uploadtype,
              }))), []))
            .then(async (filesFromResponse) =>
              dispatch(setDownloadFiles({files: Object.values(filesFromResponse)}))),
      ).catch((error) => {
        trackError(error);
        dispatch(displayToastError(i18n.t("error.documents.could.not.be.loaded")));
      });


export const getAvailableStudies=(id, JWTToken)=>async (dispatch)=>
  get(`studies/${JWTToken?"jwt/":""}${id}/`, null, JWTToken)
      .then(async (res) => {
        dispatch(onAddStudy(res[0]));
        // Hacky way to keep only 1 study. For development purposes.
        const singleStudyId = res[0]?.study_id;
        res.forEach((e, idx, array)=>{
          singleStudyId!==e.study_id&&dispatch(markStudiesForRemoval(e.study_id));
        });
      })
      .catch((error) => {
        console.error(error);
        trackError(error);
        dispatch(displayToastError(i18n.t("error.studies.could.not.be.loaded")));
      });

export const getPatientDiary=(id)=>async (dispatch)=>
  get(`patient/${id}`)
      .then(async (res)=>{
        if (res !== undefined) {
          dispatch(onReceivePatientDiary({patientDiary: res}));
        }
      })
      .catch((error) => {
        console.error(error);
        trackError(error);
        dispatch(displayToastError(i18n.t("error.patientDiary.could.not.load")));
      });


export const fetchEditData = (id) => async (dispatch) => {
  trackEvent("requests/" + id);
  dispatch(startLoading("edit"));
  try {
    await Promise.all([
      dispatch(cleanData()),
      get("requests/" + id)
          .then(async (json) => {
            dispatch(onReceivedEditData(json[0]));
            if (json[0] && json[0].med_data && json[0].med_data.required_drug) {
              await dispatch(
                  fetchAdditionalDrugInfo(
                      json[0].med_data.required_drug,
                      json[0].diagnosis,
                      json[0].correspondence_language));
            }
          }).catch((error) => {
            console.error(error);
            dispatch(onReceivedEditData({}));
            trackError(error);
            dispatch(displayToastError(i18n.t("error.edit.request")));
          }),
      await dispatch(getAvailableAttachments(id)),
    ]);
  } catch (ex) {
    console.error("LOADING EDIT DATA", ex);
    dispatch(endLoading("edit"));
  }
  return dispatch(endLoading("edit"));
};


const getRequestBody = ({
  caseId,
  reduxFormsSlice,
}, status, requestId, lang, fieldsAreComplete) => {
  const koguType = reduxFormsSlice.indication_wrapper?.values.kogu_type;
  return {
    // Add doctor values from the redux-form
    ...reduxFormsSlice.doctorContact?.values,
    // Add patient values from the redux-form
    ...reduxFormsSlice.patientContact?.values,
    ...reduxFormsSlice.indication_wrapper?.values,
    // Add medform values from the redux-form
    // Needs to be changed when we do the db changes
    ...(koguType === "med" ? reduxFormsSlice.med_data?.values : {}),
    // Add fields outside of indication data
    ...fieldNames.general.reduce((a, field) => ({
      ...a,
      [field]: reduxFormsSlice[koguType + "_data"]?.values?.[field],
    }), {}),

    // physician_data: reduxFormsSlice.doctorContact?.values,
    // patient_data: reduxFormsSlice.patientContact?.values,
    // add indication data (med_data,reha_data, etc.) to pass validation
    ...allKoguTypes
        .map((e)=>e.value)
        .reduce((a, e)=>Object.assign(a, {[`${e}_data`]: {}}), {}),
    // fill the actual indication data
    ...{[koguType+"_data"]: koguType==="med"?
    {...reduxFormsSlice[`${koguType}_data`]?.values,
    } ?? {}:
      reduxFormsSlice[`${koguType}_data`]?.values ?? {}},
    // TODO_AnGh: dirty fix. Find a cleaner solution
    request_type: koguType === "med" ?
            reduxFormsSlice.med_data?.values.request_type :
            getKoguCodesFromType(reduxFormsSlice.indication_wrapper?.values.kogu_type),
    correspondence_language: lang,
    case_id: caseId,
    request_id: requestId,
    request_status: status,
    fields_are_complete: fieldsAreComplete,
  };
};

export const sendRequestToInsurance = (
    isEdit, id, form, comment, currentLang, documentSettings, fieldsAreComplete) =>
  (dispatch) => {
    dispatch(startLoading("sendToInsurance"));
    return dispatch(saveAs(isEdit, id, form, "PENDING_INSURANCE", currentLang, fieldsAreComplete))
        .then((response) => {
          const requestId=isEdit?response[0].request_id:response[0].newRequestID;
          return dispatch(processFilesAndPubmed(requestId, documentSettings))
              .then(
                  ()=> post(`requests/${requestId}/sendToInsurance`,
                      {isEdit, comment}),
              );
        }).catch((error) =>{
          dispatch(onError(error, "SEND_REQUEST_TO_INSURANCE_UNSUCCESSFUL"));
          throw error;
        })
        .finally(() => dispatch(endLoading("sendToInsurance")));
  };

export const saveAsDraft=(isEdit, id, form, status, lang, documentSettings, fieldsAreComplete) =>
  (dispatch) => {
    dispatch(startLoading("save"));
    return dispatch(saveAs(isEdit, id, form, status, lang, fieldsAreComplete))
        .then((response ) => {
          const requestId=isEdit?response[0].request_id:response[0].newRequestID;
          return dispatch(processFilesAndPubmed(requestId, documentSettings));
        }).catch((error) =>dispatch(onError(error, i18n.t("error.save.incomplete"))))
        .finally(() => dispatch(endLoading("save")));
  };
export const saveAs = (isEdit, requestId, form, status, lang, fieldsAreComplete) => async (dispatch) => {
  const url = `requests/${isEdit ? "edit/" : "new/"}`;
  trackEvent(url);
  dispatch(startLoading("save"));
  if (checkIsPraluent(form?.reduxFormsSlice.med_data?.values?.diagnosis,
      form?.reduxFormsSlice.med_data?.values?.required_drug)) {
    form.reduxFormsSlice.med_data.values.special_med_type="Praluent";
  }
  if (checkIsBrukinsa( form?.reduxFormsSlice.med_data?.values?.required_drug)) {
    form.reduxFormsSlice.med_data.values.special_med_type="Brukinsa";
  }
  const requestBody = getRequestBody(form, status, requestId, lang, fieldsAreComplete );

  return post(url, requestBody)
      .then((res) => {
        if (!res.error) {
          dispatch(showBanner(true));
        }
        return res;
      })
      .catch((error) => {
        dispatch(errorServiceException("requests/edit/" + error));
        dispatch(displayToastError((i18n.t("error.save.incomplete"))));
      }).finally(() => dispatch(endLoading("save")));
};

export const removeMarkedPubmedStudy = (requestId, studyId, JWTToken) =>
  async (dispatch) => {
    const url = `studies/${JWTToken?"jwt/":""}remove/${requestId}/${studyId}`;

    trackEvent(url);
    return post(url, {}, JWTToken)
        .then((json) => json)
        .catch((error) => dispatch(onError(error, "error.attach.study")));
  };

export const processFilesAndPubmed=(requestId, documentSettings, JWTToken)=>
  async (dispatch)=>{
    const {
      localFiles,
      downloadedFiles,
      pubmedStudy,
      filesMarkedForRemoval,
      studiesMarkedForRemoval,
    } = documentSettings;

    await Promise.all([
      filesMarkedForRemoval &&
      filesMarkedForRemoval.map((e) =>
        dispatch(removeMarkedFiles(requestId, e, JWTToken))),
      studiesMarkedForRemoval &&
      studiesMarkedForRemoval.map((e) =>
        dispatch(removeMarkedPubmedStudy(requestId, e, JWTToken))),
      localFiles.length >0 &&
        dispatch(addFile(requestId, localFiles, JWTToken)),
      downloadedFiles &&
       downloadedFiles.length > 0 &&
         dispatch(updateFiles(requestId, downloadedFiles)),
      pubmedStudy?.pmid &&
        dispatch(addPubmedStudy(requestId, pubmedStudy, JWTToken)),
    ]);
  };

export const addPubmedStudy = (id, studyData, JWTToken) => async (dispatch) => {
  const url =`studies/${JWTToken?"jwt/":""}new/${id}`;
  trackEvent(url);
  return post(url, {
    doi: studyData?.articleids?.find((e)=>e.idtype==="doi")?.value,
    title: studyData?.title,
    authors: Array.isArray(studyData?.authors)?studyData?.authors?.reduce((a, e)=>a+`${e.name}, `, ""):studyData?.authors,
    document_type: studyData.document_type??studyData?.pubtype.join(", "),
    pmid: studyData?.pmid,
  }, JWTToken)
      .then((json) => json)
      .catch((error) =>dispatch(onError(error, "error.attach.study")),
      );
};
export const addFile = (id, files, JWTToken) => async (dispatch) => {
  const formData = new FormData();
  files.map((i, idx) => {
    formData.append("file", i);
    if (i.remarks) {
      formData.append(idx + " remarks", i.remarks);
    }
    if (i.uploadtype) {
      formData.append(idx + " uploadtype", i.uploadtype);
    }
  });
  trackEvent("documents/upload/" + id);
  return postFiles("documents/upload/" + id, formData)
      .then((json) => console.log(json))
      .catch((error) => dispatch(onError(error, "error.upload.failed")),
      );
};


export const requestStudiesForNewReq = (
    isEdit, requestId, form, currentLang, documentSettings, fieldsAreComplete) =>
  (dispatch) => {
    dispatch(startLoading("sendStudy"));
    return dispatch(saveAs(isEdit, requestId, form, "WAITING_FOR_STUDIES", currentLang, fieldsAreComplete))
        .then((response) => {
          const requestIdFromBackend= isEdit ?
          response[0].request_id:response[0].newRequestID;
          return dispatch(processFilesAndPubmed(requestIdFromBackend, documentSettings))
              .then(()=> dispatch(requestStudiesReq(requestIdFromBackend, currentLang)));
        }).catch((error) => {
          dispatch(onError(error, i18n.t("error.studies.could.not.sent.mail")));
          throw error;
        })
        .finally(() => dispatch(endLoading("sendStudy")));
  };

export const updateFiles = (id, files) => async (dispatch) => {
  const fileUpdates = files.map((file, idx) => {
    return {remarks: file.remarks??"", uploadtype: file.uploadtype??"", name: file.name??""};
  });
  trackEvent("documents/update/" + id);
  return post("documents/update/" + id, fileUpdates)
      .then((json) => console.log(json))
      .catch((error) => onError(error, "error.upload.failed"),
      );
};

export const uploadLocalFiles = (files, type) => async (dispatch) => {
  try {
    dispatch(uploadLocalFilesAction({files, type}));
  } catch (e) {
    console.error(e);
  }
};

export const uploadAndParseLocalFiles = (files, type) => async (dispatch) => {
  console.debug("uploadLocalFiles", files, type);
  try {
    if (type !== "REMOVE") dispatch(parsePDFDocument(files));
    dispatch(uploadLocalFilesAction({files, type}));
  } catch (e) {
    console.error(e);
  }
};

export const removeFilesFromStoreAndDeleteIfExists =() => async (dispatch) => {
  console.debug("REMOVING FILES FROM STEPPER");
  dispatch(removeLocalFilesAction());
};

export const removeMarkedFiles = (id, file, JWTToken) => (dispatch) => {
  dispatch(startLoading("removeFiles"));

  trackEvent(`documents/delete/${id}/${encodeURIComponent(file.name)}/
    ${file.fromExternalSource}`);
  /* eslint-disable-next-line  */
  post(`documents/delete/${id}/${encodeURIComponent(file.name)}/${file.fromExternalSource}`)
      .then((res) => {
        if (res.success === true) {
          const type = "REMOVE";
          dispatch(setDownloadFiles({file, type}));
        } else {
          console.error(res.StatusText);
        }
      }).catch((error) => {
        trackError(error);
        dispatch(displayToastError(i18n.t("error.documents.could.not.be.removed")));
      }).finally(() => dispatch(endLoading("removeFiles")));
};

export const parsePDFDocument = (files) => async (dispatch) => {
  trackEvent("pubmed/getmetadata");
  dispatch(startLoading("documentparsing"));
  console.debug("parsePDFDocument", files);
  try {
    const formData = new FormData();
    files.map((i) => formData.append("file", i));
    const result = await parseFiles("pubmed/getmetadata/", formData).catch((ex) => {
      dispatch(endLoading("documentparsing"));
    });
    console.debug("parsePDFDocument", result);
    if (result?.response?.fileInformationList &&
      result.response.fileInformationList.length > 0) {
      console.debug("parsePDFDocument", result.response.fileInformationList);
      dispatch(addMetaDataTouploadStepperFiles(result.response?.fileInformationList));
      const pmcid = result.response?.fileInformationList[0]?.pmcid;
      const pmid = result.response?.fileInformationList[0]?.pmid;

      if (pmcid || pmid) {
        const json = await get(`pubMed/${encodeURIComponent(pmcid || pmid)}`);
        if (json.success === true) {
          await dispatch(onReceivedPubMedData(json.searchresult));
          if (json.searchresult.length > 0) {
            await dispatch(onAddStudy(json.searchresult[0]));
            dispatch(onReceivedPubMedData([]));
          }
        }
      }
    }
  } catch (exception) {
    console.error(exception);
    dispatch(displayToastError((i18n.t("error.documents.could.not.be.parsed"))));
  } finally {
    dispatch(endLoading("documentparsing"));
  }
};

export const invitePatient = (requestid, lang) => async (dispatch) => {
  trackEvent(`patient/invitepatient/${requestid}`);
  try {
    dispatch(startLoading("invitePatient"));
    await get(`patient/invitepatient/${requestid}`);
  } catch (error) {
    console.error(error);
    trackError(String(error));
    dispatch(displayToastError("INVITE_PATIENT"));
  } finally {
    dispatch(endLoading("invitePatient"));
  }
};

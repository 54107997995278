import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import {useTranslation} from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeletionDialog(props) {
  const {t} = useTranslation();

  return <div>
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {props.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick = {()=> props.handleClose()}
          data-cy={"delete_ok"}
          color = "primary">
          {t("reminder.dialog.OK")}
        </Button>
        <Button
          onClick = {()=> props.handleCancel()}
          data-cy={"delete_cancel"}
          color = "primary">
          {t("reminder.dialog.CANCEL")}

        </Button>
      </DialogActions>
    </Dialog>
  </div>;
}

/* eslint-disable no-unused-vars */
// false positive for ts enum
export interface IFormSlice {
    getData:Function;
    onChangeValue:Function;
    [key: string]: any;
}


export enum VALIDATIONERROR {
    EMAIL_FORMAT="error.email.format",
    REQUIRED="error.required",
    NONE=""
}


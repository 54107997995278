import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";
export interface ITreatmentPlanState {
  formData:{
    limitatio_approval:boolean;
    specialist_details:boolean;
    specialist_info:boolean;
  };
}
const initialState:ITreatmentPlanState = {
  formData: {
    limitatio_approval: false,
    specialist_details: false,
    specialist_info: false,
  },
};

const treatmentPlanSlice = createSlice({
  name: "treatmentPlan",
  initialState,
  reducers: {
    onReceiveTreatmentPlanEditData: (state, {payload}:PayloadAction<any>)=>{
      state.formData ={...state.formData, ...payload};
    },
    onChangeTreatmentValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      state.formData[payload.field] = payload.value;
    },
    onClearTreatmentState: (state)=>{
      state.formData=initialState.formData;
    },
  },
});

export const {
  onChangeTreatmentValue,
  onReceiveTreatmentPlanEditData,
  onClearTreatmentState,
} = treatmentPlanSlice.actions;

export default treatmentPlanSlice.reducer;
export const selectTreatmentValues = (state:RootState) => state.wizard.medicine.treatmentPlanForm.formData;

// CLL
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {onChangeImbruvicaValue, selectImbruvicaValues} from "./ImbruvicaSlice";
import {onChangeKVVLegalState, onChangeMedicineValue} from "../../../medicine/medicineSlice";
import {onChangeTreatmentValue} from "../../../Treatment/treatmentPlanSlice";
import {onChangeSpecialMedValue} from "../../specialMedSlice";
import {removeStudy, setStudyById} from "pages/Wizard/wizardSlice";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";


const imbruvicaForm = (props) => {
  const {t}=useTranslation();
  const dispatch = UseAppDispatch();
  const imbruvicaValues=UseAppSelector(selectImbruvicaValues);
  const handleTreatmentValue = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));

  useEffect(() => {
    switch (imbruvicaValues.formCLL.variant) {
      case 0:
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        dispatch(removeStudy({}));
        break;
      case 1:
        handleTreatmentValue("limitatio_approval", true);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: true,
              specialist_info: true,
            },
        ));
        dispatch(onChangeMedicineValue({field: "kn_id", value: "21011.03"}));
        dispatch(removeStudy({}));
        break;
      case 2:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: true,
            },
        ));
        dispatch(removeStudy({}));
        break;
      case 3:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: true,
            },
        ));
        dispatch(onChangeSpecialMedValue({"field": "special_med_additional_information", "value": 3}));
        dispatch(setStudyById({studyId: "PMC9198904"}));
        break;
      case 4:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: true,
            },
        ));
        dispatch(onChangeSpecialMedValue({"field": "special_med_additional_information", "value": 4}));
        dispatch(setStudyById({studyId: "PMC6908306"}));
        break;
      case 5:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        dispatch(removeStudy({}));
        break;
      case 6:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        dispatch(removeStudy({}));
        break;
      case 7:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: true,
            },
        ));
        dispatch(removeStudy({}));
        break;
      case 8:
        handleTreatmentValue("limitatio_approval", true);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: true,
              specialist_info: true,
            },
        ));
        dispatch(removeStudy({}));
        dispatch(onChangeMedicineValue({field: "kn_id", value: "21011.01"}));
        break;
      case 9:
        handleTreatmentValue("limitatio_approval", true);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: true,
              specialist_info: true,
            },
        ));
        dispatch(removeStudy({}));
        dispatch(onChangeMedicineValue({field: "kn_id", value: "21011.02"}));
        break;
      case 10:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        dispatch(removeStudy({}));
        break;
      case 11:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        dispatch(removeStudy({}));
        break;
      case 12:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        dispatch(removeStudy({}));
        break;
      case 13:
        handleTreatmentValue("limitatio_approval", true);
        handleTreatmentValue("specialist_info", true);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: true,
              specialist_info: true,
            },
        ));
        dispatch(removeStudy({}));
        dispatch(onChangeMedicineValue({field: "kn_id", value: "21011.04"}));
        break;
      case 14:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        dispatch(removeStudy({}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        break;
      case 15:
        dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
        handleTreatmentValue("limitatio_approval", false);
        handleTreatmentValue("specialist_info", false);
        dispatch(onChangeKVVLegalState(
            {
              limitatio_approval: false,
              specialist_info: false,
            },
        ));
        dispatch(removeStudy({}));
        break;
    }
  }, [imbruvicaValues.formCLL.variant]);


  return <>
    <Grid item xs={12}>
      {
        // Anzeigen wenn Imbruvica & CLL ausgewählt ist
      }
      <FormControl fullWidth>
        <FormControlLabel
          hidden={false}
          value="notYetCLLTherapy"
          control=
            {
              <Checkbox

                checked={imbruvicaValues.formCLL.notYetCLLTherapy}
                data-cy="notYetCLLTherapy"
                onChange={(event, checked) =>
                  dispatch(onChangeImbruvicaValue({
                    formCLL: {notYetCLLTherapy: checked},
                  }))
                }
              />
            }
          label={t("imbruvica.notYetCLLTherapy")} />
      </FormControl>
      {
        // Anzeigen wenn imbruvica.1LCLLTherapy = true
      }
      {imbruvicaValues.formCLL.notYetCLLTherapy === true &&
        <FormControl fullWidth>
          <FormControlLabel
            hidden={false}
            value="mutatedTP53Or17p"
            data-cy="mutatedTP53Or17p"
            control=
              {
                <Checkbox

                  checked={imbruvicaValues.formCLL.mutatedTP53Or17p}
                  data-cy="mutatedTP53Or17p"
                  onChange={(event, checked) =>
                    dispatch(onChangeImbruvicaValue({formCLL: {mutatedTP53Or17p: checked}}))
                  }
                />
              }
            label={t("imbruvica.mutatedTP53Or17p")} />
        </FormControl>
      }
      {
        // Anzeigen wenn imbruvica.1LCLLTherapy = true
        // AND imbruvica.mutatedTP530R17p = false
      }
      {
        (
          imbruvicaValues.formCLL.notYetCLLTherapy === true &&
          imbruvicaValues.formCLL.mutatedTP53Or17p === false
        ) &&
        <FormControl fullWidth>
          <FormControlLabel
            value="mutatedIGHVStatus"
            data-cy="mutatedIGHVStatus"
            control=
              {
                <Checkbox

                  checked={imbruvicaValues.formCLL.mutatedIGHVStatus}
                  data-cy="mutatedIGHVStatus"
                  onChange={(event, checked) =>
                    dispatch(onChangeImbruvicaValue({formCLL: {mutatedIGHVStatus: checked}}))
                  }
                />
              }
            label={t("imbruvica.mutatedIGHVStatus")} />
        </FormControl>
      }
      {
        <FormControl fullWidth>
          <FormControlLabel
            value="monotherapie"
            data-cy="monotherapie"
            control=
              {
                <Checkbox

                  checked={imbruvicaValues.formCLL.monotherapie}
                  data-cy="monotherapie"
                  onChange={(event, checked) =>
                    dispatch(onChangeImbruvicaValue({formCLL: {monotherapie: checked}}))
                  }
                />
              }
            label={t("imbruvica.monotherapie")} />
        </FormControl>
      }
      {
        (
          (
            imbruvicaValues.formCLL.monotherapie === true &&
            imbruvicaValues.formCLL.notYetCLLTherapy === true
          )
        ) &&
        <FormControl
          fullWidth>
          <FormControlLabel
            hidden={false}
            value="younger65"
            data-cy="younger65"
            control=
              {
                <Checkbox

                  checked={imbruvicaValues.formCLL.younger65}
                  data-cy="younger65"
                  onChange={(event, checked) =>
                    dispatch(onChangeImbruvicaValue({formCLL: {younger65: checked}}))
                  }
                />
              }
            label={t("imbruvica.younger65")} />
        </FormControl>
      }

      {
        imbruvicaValues.formCLL.monotherapie === false &&
        <FormControl fullWidth>
          <FormControlLabel
            value="combiRituximab"
            data-cy="combiRituximab"
            control=
              {
                <Checkbox

                  checked={imbruvicaValues.formCLL.combiRituximab}
                  data-cy="combiRituximab"
                  onChange={(event, checked) =>
                    dispatch(onChangeImbruvicaValue({formCLL: {combiRituximab: checked}}))
                  }
                />
              }
            label={t("imbruvica.combiRituximab")} />
        </FormControl>
      }
      {
        (
          imbruvicaValues.formCLL.monotherapie === false &&
          imbruvicaValues.formCLL.combiRituximab === true &&
          imbruvicaValues.formCLL.notYetCLLTherapy === true
        ) &&
        <FormControl
          hidden={
            imbruvicaValues.formCLL.younger65 === true
          }
          fullWidth>
          <FormControlLabel
            value="younger70"
            data-cy="younger70"
            control=
              {
                <Checkbox

                  checked={imbruvicaValues.formCLL.younger70}
                  data-cy="younger70"
                  onChange={(event, checked) =>
                    dispatch(onChangeImbruvicaValue({formCLL: {younger70: checked}}))
                  }
                />
              }
            label={t("imbruvica.younger70")} />
        </FormControl>
      }
      {
        (
          (imbruvicaValues.formCLL.notYetCLLTherapy === true &&
            imbruvicaValues.formCLL.monotherapie === true
          ) ||
          (
            imbruvicaValues.formCLL.notYetCLLTherapy === true &&
            imbruvicaValues.formCLL.monotherapie === false &&
            imbruvicaValues.formCLL.combiRituximab === true
          )
        ) &&
        <FormControl fullWidth>
          <FormControlLabel
            hidden={false}
            value="noFludarabin"
            data-cy="noFludarabin"
            control=
              {
                <Checkbox

                  checked={imbruvicaValues.formCLL.noFludarabin}
                  data-cy="noFludarabin"
                  onChange={(event, checked) =>
                    dispatch(onChangeImbruvicaValue({formCLL: {noFludarabin: checked}}))
                  }
                />
              }
            label={t("imbruvica.noFludarabin")} />
        </FormControl>
      }

    </Grid>
    <Grid item xs={12}>
      <Stack
        direction="row"
        alignItems="left"
        gap={1}
        hidden={
          imbruvicaValues.formCLL.variant !== 15 &&
          imbruvicaValues.formCLL.variant !== 14 &&
          imbruvicaValues.formCLL.variant !== 13 &&
          imbruvicaValues.formCLL.variant !== 2 &&
          imbruvicaValues.formCLL.variant !== 3 &&
          imbruvicaValues.formCLL.variant !== 4 &&
          imbruvicaValues.formCLL.variant !== 6 &&
          imbruvicaValues.formCLL.variant !== 7 &&
          imbruvicaValues.formCLL.variant !== 10 &&
          imbruvicaValues.formCLL.variant !== 5 &&
          imbruvicaValues.formCLL.variant !== 11 &&
          imbruvicaValues.formCLL.variant !== 0 &&
          imbruvicaValues.formCLL.variant !== 12
        }
      >
        <WarningIcon color={"primary"}
        />
        <Typography hidden={imbruvicaValues.formCLL.variant !== 15}>{t("imbruvica.text.15")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 14}>{t("imbruvica.text.14")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 13}>{t("imbruvica.text.13")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 2}>{t("imbruvica.text.2")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 3}> {t("imbruvica.text.3")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 4}>{t("imbruvica.text.4")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 6}>{t("imbruvica.text.6")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 5}>{t("imbruvica.text.5")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 7}>{t("imbruvica.text.7")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 10}>{t("imbruvica.text.10")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 11}>{t("imbruvica.text.11")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 12}>{t("imbruvica.text.12")}</Typography>
        <Typography hidden={imbruvicaValues.formCLL.variant !== 0}>{t("imbruvica.text.0")}</Typography>
      </Stack>
    </Grid>
    <Box sx={{p: 1}} />
    <Grid item xs={8} >
      <TextField
        fullWidth
        InputLabelProps={{shrink: true}}
        data-cy="imbruvica.cll.textfield"
        label={t("imbruvica.cll.textfield")}
        value={imbruvicaValues.formCLL?.textfield}
        multiline
        maxRows={4}
        minRows={4}
        variant="outlined"
        onChange = {(event ) =>
          dispatch(onChangeImbruvicaValue({
            formCLL: {textfield: (event.target as HTMLInputElement).value},
          }),
          )
        }
      />
    </Grid>


    <Grid item xs={12}>
      {
        (imbruvicaValues?.formCLL?.variant !== 0)?
          <FormLabel >{t("imbruvica.variant")}&nbsp;{imbruvicaValues.formCLL.variant}&nbsp;</FormLabel> :
          <></>
      }
    </Grid>
    <Box sx={{p: 1}}/>


  </>;
};

const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(imbruvicaForm);



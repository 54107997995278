import React, {Suspense, useState, useEffect} from "react";

import {Route, Switch, useLocation, useHistory} from "react-router-dom";
import {connect, useDispatch} from "react-redux";
import {Box, Container} from "@mui/material";
import {routes} from "../config/routes";
import {useTranslation} from "react-i18next";
import {uploadLocalFilesAction} from "../store/features/newRequestSlice";
import Backdrop from "../components/Backdrop";
import SnackbarComponent from "./Snackbar";
import "../i18n";
import ErrorBoundary from "layout/ErrorBoundary";
import Header from "./Header";
import {useIsAuthenticated, AuthenticatedTemplate} from "@azure/msal-react";
import {fetchStatusChangesRequests} from "store/middleware/requestListThunk";
import {getUnreadMessages} from "store/middleware/communicationThunk";
import {fetchUserInformation} from "store/middleware/userThunk.tsx";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const NotFound = () => {
  const {t}=useTranslation();
  const handleClick = () => {
    history.push("/");
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.box}>
        <Typography variant="h4" gutterBottom>
          {t("404")}
        </Typography>
        <Typography variant="body1">
          {t("404LongText")}
        </Typography>
        <Link href="/" onClick={handleClick}>
          {t("Home")}
        </Link>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  box: {
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
};


const loading=()=> <Backdrop open={true} />;

const LoadApplicationWideData = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (isAuthenticated === true) {
      dispatch(fetchUserInformation());
      dispatch(fetchStatusChangesRequests());
      dispatch(getUnreadMessages());
    }
  }, [location]);
  return "";
};


const Layout=(props) => {
  const history = useHistory();
  const [openDrawer, setOpenDrawer]=useState(false);
  const {i18n}=useTranslation();
  const passingProps={...props};
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    console.debug("window.location", window.location.toString());
    if (window.location.toString().endsWith("/") &&
      (isAuthenticated === false) &&
      !window.location.toString().endsWith("ai/")
    ) {
      history.push("medicheck");
    }
    if (location.pathname === "/" && (isAuthenticated === true)) {
      // history.push("home");
    }
  }, []);
  delete passingProps.dispatch;
  return (
    <div>
      <Box onClick={() => null} sx={{"padding": "0px", "display": "flex", "flexDirection": "column", "minHeight": "100vh"}} >
        <AuthenticatedTemplate>
          <LoadApplicationWideData id="LoadApplicationWideDataComponent" />
        </AuthenticatedTemplate>
        <Header openDrawer={() => setOpenDrawer(!openDrawer)} />
        <Box sx={{"display": "flex", "flexDirection": "column", "flexGrow": "1"}}>
          <SnackbarComponent/>
          <Box sx={{"flex": "1 1", "minWidth": "0", "display": "flex", "flexDirection": "column"}}>
            <Suspense fallback={loading()}>
              <ErrorBoundary>

                <Switch>
                  {routes(i18n).map((route, idx) =>
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => (
                          route.fullScreen?
                          (<route.component {...props}/>):
                            (<Container sx={{marginTop: "1px", padding: 0}}>
                              <route.component {...props}/>
                            </Container>)
                      )}
                    />,
                  )}
                  <Route component={NotFound} />
                </Switch>
              </ErrorBoundary>
            </Suspense>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const mapStateToProps=(state) => ({
  userinformation: state.userinformation,
  verified: state.userinformation.user?.groups?.includes("verified"),
});

const mapDispatchToProps=(dispatch) => ({
  uploadLocalFiles: (files, type) =>dispatch(uploadLocalFilesAction({files, type})),
});
export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface IPraluentState {
  formData:{
    ersteverordnung:boolean;
    pcsk:boolean;
    ldlCWert_before:number;
    praluent_measurement_date_before:Date
    ldlc_26:boolean,
    ldlc_50:boolean,
    ldlc_45:boolean,
    diabetes:boolean,
    increased_lipoprotein:boolean,
    hypertension:boolean
    treatment_diet:boolean,
    treatment_statins:boolean,
    treatment_ezetimibe:boolean,
    statin1:string,
    myalgia_statin1:boolean,
    rise_ck:boolean,
    severe_hepatopathy:boolean
    statin2:string,
    myalgia_statin2:boolean,
    blood_preasure:boolean,
    hba1c:boolean
    nicotine_abstinence:boolean,
    ldlCWert_during:number,
    praluent_measurement_date_during:Date
    ldlc_reduction:boolean,
    value_reached:boolean,
  };
}
const initialState:IPraluentState = {
  formData: {
    ersteverordnung: false,
    pcsk: false,
    ldlCWert_before: 0,
    praluent_measurement_date_before: new Date(),
    ldlc_26: false,
    ldlc_50: false,
    ldlc_45: false,
    diabetes: false,
    increased_lipoprotein: false,
    hypertension: false,
    treatment_diet: false,
    treatment_statins: false,
    treatment_ezetimibe: false,
    statin1: "",
    myalgia_statin1: false,
    rise_ck: false,
    severe_hepatopathy: false,
    statin2: "",
    myalgia_statin2: false,
    blood_preasure: false,
    hba1c: false,
    nicotine_abstinence: false,
    ldlCWert_during: 0,
    praluent_measurement_date_during: new Date(),
    ldlc_reduction: false,
    value_reached: false,
  },
};

const praluentFormSlice = createSlice({
  name: "praluent",
  initialState,
  reducers: {
    onReceiveSpecialMedPraluentEditData: (state, {payload}:PayloadAction<any>)=>{
      state.formData ={...state.formData, ...payload};
    },
    onChangePraluentValue: (state, {payload}:PayloadAction<{field:string;value:any;}>)=>{
      state.formData[payload.field] = payload.value;
    },
    onClearPraluentState: (state)=>{
      state.formData=initialState.formData;
    },
  },
});

export const {
  onChangePraluentValue,
  onReceiveSpecialMedPraluentEditData,
  onClearPraluentState,
} = praluentFormSlice.actions;

export default praluentFormSlice.reducer;

export const selectPraluentValues = (state: RootState) =>
  state.wizard.medicine.additionalInformation.praluentForm.formData;

export const selectActivePraluentValues=(state:RootState)=>{
  if (state.wizard.medicine.additionalInformation.praluentForm.formData.ersteverordnung) {
    return {
      ersteverordnung: state.wizard.medicine.additionalInformation.praluentForm.formData.ersteverordnung,
      ldlCWert_before: state.wizard.medicine.additionalInformation.praluentForm.formData.ldlCWert_before,
      praluent_measurement_date_before:
        state.wizard.medicine.additionalInformation.praluentForm.formData.praluent_measurement_date_before,
      ldlc_26: state.wizard.medicine.additionalInformation.praluentForm.formData.ldlc_26,
      ldlc_50: state.wizard.medicine.additionalInformation.praluentForm.formData.ldlc_50,
      ldlc_45: state.wizard.medicine.additionalInformation.praluentForm.formData,
      diabetes: state.wizard.medicine.additionalInformation.praluentForm.formData.diabetes,
      increased_lipoprotein: state.wizard.medicine.additionalInformation.praluentForm.formData.increased_lipoprotein,
      hypertension: state.wizard.medicine.additionalInformation.praluentForm.formData.hypertension,
      treatment_diet: state.wizard.medicine.additionalInformation.praluentForm.formData.treatment_diet,
      treatment_statins: state.wizard.medicine.additionalInformation.praluentForm.formData.treatment_statins,
      treatment_ezetimibe: state.wizard.medicine.additionalInformation.praluentForm.formData.treatment_ezetimibe,
      statin1: state.wizard.medicine.additionalInformation.praluentForm.formData.statin1,
      myalgia_statin1: state.wizard.medicine.additionalInformation.praluentForm.formData.myalgia_statin1,
      rise_ck: state.wizard.medicine.additionalInformation.praluentForm.formData.rise_ck,
      severe_hepatopathy: state.wizard.medicine.additionalInformation.praluentForm.formData.severe_hepatopathy,
      statin2: state.wizard.medicine.additionalInformation.praluentForm.formData.statin2,
      myalgia_statin2: state.wizard.medicine.additionalInformation.praluentForm.formData.myalgia_statin2,
      blood_preasure: state.wizard.medicine.additionalInformation.praluentForm.formData.blood_preasure,
      hba1c: state.wizard.medicine.additionalInformation.praluentForm.formData.hba1c,
      nicotine_abstinence: state.wizard.medicine.additionalInformation.praluentForm.formData.nicotine_abstinence,
    };
  } else if (state.wizard.medicine.additionalInformation.praluentForm.formData.pcsk) {
    return {
      pcsk: state.wizard.medicine.additionalInformation.praluentForm.formData.pcsk,
      ldlCWert_during: state.wizard.medicine.additionalInformation.praluentForm.formData.ldlCWert_during,
      praluent_measurement_date_during:
      state.wizard.medicine.additionalInformation.praluentForm.formData.praluent_measurement_date_during,
      ldlc_reduction: state.wizard.medicine.additionalInformation.praluentForm.formData.ldlc_reduction,
      value_reached: state.wizard.medicine.additionalInformation.praluentForm.formData.value_reached,
    };
  }
  return {};
};

import React from "react";
import {
  FaCapsules,
  FaAmbulance,
  FaCrutch,
  MdSpa,
  FaStethoscope,
  FaSyncAlt,
} from "components/icons";

// Those are technical values, please translate later with i18n. Not here.
export const allKoguTypes=
[
  {value: "med", codes: ["71aKVV", "71bKVV", "71cKVV", "Inlabel"], icon: <FaCapsules />, showOnHomePage: true},
  {value: "migel", codes: ["MiGel"], icon: <FaCrutch />, showOnHomePage: true},
  {value: "ambstat", codes: ["3cKLV"], icon: < FaSyncAlt />, showOnHomePage: true},
  {value: "reha", codes: ["Reha"], icon: <MdSpa />, showOnHomePage: true},
  {value: "transport", codes: ["Transport"], icon: <FaAmbulance />, showOnHomePage: true},
  {value: "breathing_device", codes: ["BreathingD"], icon: <FaStethoscope />, showOnHomePage: true},
];

export const getKoguTypeFromCode=(code) => allKoguTypes.find((e)=> e.codes.indexOf(code?.replace(" ", ""))>-1)?.value;
export const getKoguCodesFromType=(type) => {
  const koguTypeObject=allKoguTypes.find((e)=>e.value===type);
  return Array.isArray(koguTypeObject?.codes)?koguTypeObject.codes[0]:"";
};

import React from "react";
import {OverviewRow} from "./OverviewRow";
import i18n from "i18n";
import {formatDateGerman} from "components/formatting";

export const additionalInfomationRows=(specialMed:string, additionalInformation:any)=>{
  if (specialMed==="Brukinsa") {
    return <>
      <OverviewRow rowTitle={i18n.t("brukinsa.wanlenstrom")} rowContent={additionalInformation?.brukinsa?.wanlenstrom?i18n.t("indication.yes"):i18n.t("indication.no")}/>
      <OverviewRow rowTitle={i18n.t("brukinsa.rituximab")} rowContent={additionalInformation?.brukinsa?.rituximab?i18n.t("indication.yes"):i18n.t("indication.no")}/>
      <OverviewRow rowTitle={i18n.t("brukinsa.rituximabReason")} rowContent={additionalInformation?.brukinsa?.rituximabReason||"-"}/>
      <OverviewRow rowTitle={i18n.t("brukinsa.hasTreatment")} rowContent={additionalInformation.brukinsa?.hasTreatment ? i18n.t("indication.yes") : i18n.t("indication.no")} />
      {additionalInformation?.brukinsa?.treatmentStart?<OverviewRow rowTitle={i18n.t("brukinsa.treatmentStart")} rowContent={formatDateGerman(additionalInformation?.brukinsa?.treatmentStart)}/>:<div/>}
      {additionalInformation?.brukinsa?.treatmentEnd?<OverviewRow rowTitle={i18n.t("brukinsa.treatmentEnd")} rowContent={formatDateGerman(additionalInformation?.brukinsa?.treatmentEnd)}/>:<div/>}
    </>;
  } else if (specialMed==="BrukinsaCll") {
    return <>
      {!additionalInformation?.brukinsaCll?.previously_treated?<OverviewRow rowTitle={i18n.t("indication.details.brukinsa_untreated")} rowContent={i18n.t("indication.yes")}/>:<></>}
      {additionalInformation?.brukinsaCll?.previously_treated?<OverviewRow rowTitle={i18n.t("indication.details.brukinsa_previously_treated")} rowContent={i18n.t("indication.yes")}/>:<></>}
    </>;
  } else if (specialMed==="Praluent") {
    if (additionalInformation?.praluent?.ersteverordnung) {
      return <>
        <OverviewRow rowTitle={i18n.t("ldl.c.wert.before")} rowContent={additionalInformation?.praluent?.ldlCWert_before||"-"}/>
        <OverviewRow rowTitle={i18n.t("praluent.measurement.date")} rowContent={formatDateGerman(additionalInformation?.praluent?.praluent_measurement_date_before)||"-"}/>
        <OverviewRow rowTitle={i18n.t("praluent.ldlc26")} rowContent={additionalInformation?.praluent?.ldlc_26?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.ldlc50")} rowContent={additionalInformation?.praluent?.ldlc_50?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.ldlc45")} rowContent={additionalInformation?.praluent?.ldlc_45?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.diabetes")} rowContent={additionalInformation?.praluent?.diabetes?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.increased.lipoprotein")} rowContent={additionalInformation?.praluent?.increased_lipoprotein?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.hypertension")} rowContent={additionalInformation?.praluent?.hypertension?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.treatment.diet")} rowContent={additionalInformation?.praluent?.treatment_diet?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.treatment.statins")} rowContent={additionalInformation?.praluent?.treatment_statins?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.treatment.ezetimibe")} rowContent={additionalInformation?.praluent?.treatment_ezetimibe?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.statin1")} rowContent={additionalInformation?.praluent?.statin1||"-"}/>
        <OverviewRow rowTitle={i18n.t("praluent.myalgia")} rowContent={additionalInformation?.praluent?.myalgia_statin1?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.rise.ck")} rowContent={additionalInformation?.praluent?.rise_ck?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.severe.hepatopathy")} rowContent={additionalInformation?.praluent?.severe_hepatopathy?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.statin2")} rowContent={additionalInformation?.praluent?.statin2||"-"}/>
        <OverviewRow rowTitle={i18n.t("praluent.myalgia")} rowContent={additionalInformation?.praluent?.myalgia_statin2?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.blood.preasure")} rowContent={additionalInformation?.praluent?.blood_preasure?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.hba1c")} rowContent={additionalInformation?.praluent?.hba1c?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.nicotine.abstinence")} rowContent={additionalInformation?.praluent?.nicotine_abstinence?i18n.t("indication.yes"):i18n.t("indication.no")}/>
      </>;
    } else if (additionalInformation?.praluent?.pcsk) {
      return <>
        <OverviewRow rowTitle={i18n.t("ldl.c.wert.during")} rowContent={additionalInformation?.praluent?.ldlCWert_during||"-"}/>
        <OverviewRow rowTitle={i18n.t("praluent.measurement.date")} rowContent={formatDateGerman(additionalInformation?.praluent?.praluent_measurement_date_during)||"-"}/>
        <OverviewRow rowTitle={i18n.t("praluent.ldlc.reduction")} rowContent={additionalInformation?.praluent?.ldlc_reduction?i18n.t("indication.yes"):i18n.t("indication.no")}/>
        <OverviewRow rowTitle={i18n.t("praluent.value.reached")} rowContent={additionalInformation?.praluent?.value_reached?i18n.t("indication.yes"):i18n.t("indication.no")}/>

      </>;
    }
  } else if (specialMed === "Quviviq") {
    return <>
      {additionalInformation?.quviviq?.justification.thereIsNoAlternativeDrug ?
        <OverviewRow rowContent={i18n.t("indication.details.quiviq_thereIsNoAlternativeDrug")} /> : <div />}
      {additionalInformation?.quviviq?.justification.patientRespondedToTherapy ?
        <OverviewRow rowContent={i18n.t("indication.details.quiviq_patientRespondedToTherapy")} /> : <div />}
      {additionalInformation?.quviviq?.justification.furtherJustification ?
        <OverviewRow rowContent={additionalInformation?.quviviq?.justification.furtherJustification}/>:<div/>}


      {additionalInformation?.quviviq?.clinicalcondition.decreasedPerformance ?
        <OverviewRow rowContent={i18n.t("indication.details.quiviq.decreasedPerformance")} /> : <div />}
      {additionalInformation?.quviviq?.clinicalcondition.decreasedCognitiveFunctions ?
        <OverviewRow rowContent={i18n.t("indication.details.quiviq.decreasedCognitiveFunction")} /> : <div />}
      {additionalInformation?.quviviq?.clinicalcondition.previousFalls ?
        <OverviewRow rowContent={i18n.t("indication.details.quiviq.previousFalls")} /> : <div />}
      {additionalInformation?.quviviq?.clinicalcondition.increasedRiskOfCardiovascularDisease ?
        <OverviewRow rowContent={i18n.t("indication.details.quiviq.increasedRiskOfCardiovascularDisease")} /> : <div />}
      {additionalInformation?.quviviq?.clinicalcondition.furtherJustification ?
        <OverviewRow rowContent={additionalInformation?.quviviq?.clinicalcondition.furtherJustification} /> : <div />}

      {additionalInformation?.quviviq?.achieveIfSubscribed.improvingCognitiveFunctions ?
        <OverviewRow rowContent={i18n.t("indication.details.quiviq.improvingCognitiveFunctions")} /> : <div />}
      {additionalInformation?.quviviq?.achieveIfSubscribed.reductionOfRiskOfFalls ?
        <OverviewRow rowContent={i18n.t("indication.details.quiviq.reductionOfRiskOfFalls")} /> : <div />}
      {additionalInformation?.quviviq?.achieveIfSubscribed.reductionOfRiskOfCardiovascularDisease ?
        <OverviewRow rowContent={i18n.t("indication.details.quiviq.reductionOfRiskOfCardiovascularDisease")} /> : <div />}
      {additionalInformation?.quviviq?.achieveIfSubscribed.reductionOfSideEffectsOfNarcoticsConsume ?
        <OverviewRow rowContent={i18n.t("indication.details.quiviq.reductionOfSideEffectsOfNarcoticsConsume")} /> : <div />}
      {additionalInformation?.quviviq?.achieveIfSubscribed.furtherJustification ?
        <OverviewRow rowContent={additionalInformation?.quviviq?.achieveIfSubscribed.furtherJustification} /> : <div />}
    </>;
  } else {
    return <>
      <OverviewRow rowTitle={i18n.t("indication.details.pretreatment")} rowContent={additionalInformation?.defaultMedicine?.pretreatment||"-"}/>
      <OverviewRow rowTitle={i18n.t("indication.details.patient.condition")} rowContent={additionalInformation?.defaultMedicine?.patient_condition||"-"}/>
      <OverviewRow rowTitle={i18n.t("indication.details.disease.prognosis")} rowContent={additionalInformation?.defaultMedicine?.disease_prognosis||"-"}/>
      <OverviewRow rowTitle={i18n.t("indication.details.therapy.alternatives")} rowContent={additionalInformation?.defaultMedicine?.therapy_alternatives||"-"}/>
    </>;
  }
};

import {postWizardOverview} from "store/api";
import {displayToastError} from "store/features/errorSlice";
import i18n from "i18n";

const createDownloadLink=(blob) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "filename.pdf";
  document.body.appendChild(a);
  a.click();
  a.remove();
};


// change to header
export const handleDownloadPdf = (lang, request) =>(dispatch)=> {
  return postWizardOverview("anonym/insurance", request)
      .then((blob)=>{
        createDownloadLink(blob);
      })
      .catch((error) => {
        console.error(error);
        dispatch(displayToastError(i18n.t("pdf.print.error")));
      });
};


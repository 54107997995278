import {ApplicationInsights, SeverityLevel} from "@microsoft/applicationinsights-web";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import store from "./store/store";

const isAppInsightsActive = process.env.REACT_APP_SCOPE_APPINSIGHTS_ACTIVE === "true";

const appInsightsConst = () => {
  if (isAppInsightsActive === true) {
    console.debug("[ApplicationInsights] ApplicationInsights will be active");
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights(
        {
          config: {
            instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
            extensions: [reactPlugin],
            enableAutoRouteTracking: false,
            enableCorsCorrelation: false,
            enableRequestHeaderTracking: false,
            enableResponseHeaderTracking: false,
            disableXhr: false,
            disableTelemetry: false,
          },
        },
    );
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    return appInsights;
  } else {
    console.debug("[ApplicationInsights] ApplicationInsights will be inactive");
  }
};


export const setAuthenticatedUserContext = (username ) => {
  if (appInsightsConst()) {
    appInsightsConst().setAuthenticatedUserContext(username);
  }
};

export const trackError=(reportedError )=>{
  if (appInsightsConst()) {
    appInsightsConst().trackException({error: new Error(reportedError), severityLevel: SeverityLevel.Error});
    appInsightsConst().trackEvent({
      name: "EXCEPTION",
      properties: {
        // @ts-expect-error: id is not id
        userid: store.userinformation?.user?.id,
        // @ts-expect-error: id is not givenName
        givenName: store.userinformation?.user?.givenName,
        // @ts-expect-error: id is not surname
        surname: store.userinformation?.user?.surname,
      },
    });
  }
};

export const trackEvent=(event)=>{
  if (appInsightsConst()) {
    appInsightsConst().trackEvent({
      name: "PS-EVENT",
      properties: {
        event: event,
        // @ts-expect-error: id is not id
        userid: store.userinformation?.user?.id,
        // @ts-expect-error: id is not givenName
        givenName: store.userinformation?.user?.givenName,
        // @ts-expect-error: id is not surname
        surname: store.userinformation?.user?.surname,
      },
    });
  }
};

export const trackDependency = (url, responseCode) => {
  if (appInsightsConst()) {
    appInsightsConst().trackDependencyData({
      id: "API",
      name: url,
      target: url,
      responseCode: 0,
    });
  }
};

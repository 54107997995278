import {
  get,
  post,
} from "store/api";

import {
  receiveSubstitutionDoctors,
  receiveSubstitutions,
} from "../features/substitutionSlice";
import {
  displayToastSuccess,
  displayToastError,
} from "store/features/errorSlice";
import {startLoading, endLoading} from "store/features/loadingSlice";
import {trackEvent, trackError} from "AppInsights";


export const fetchSubstitutionDoctors=()=>(dispatch)=> {
  trackEvent("substitution/getAllMySubstitutionDoctors/");
  dispatch(startLoading("substitution"));
  get("substitution/getAllMySubstitutionDoctors/")
      .then((json) => dispatch(receiveSubstitutionDoctors(json)),
      ).catch((error) => {
        trackError(error);
        dispatch(displayToastError("SUBSTITUTION_DOCTORS_ERROR"));
      })
      .finally(()=> dispatch(endLoading("substitution")));
};

export const fetchSubstitutions=()=>(dispatch)=> {
  trackEvent("substitution/getAllMySubstitutions/");
  dispatch(startLoading("substitution"));
  get("substitution/getAllMySubstitutions/")
      .then((json) => dispatch(receiveSubstitutions(json)),
      ).catch((error) => {
        trackError(error);
        dispatch(displayToastError("SUBSTITUTIONS_ERROR"));
      })
      .finally(()=> dispatch(endLoading("substitution")));
};

export const addDoctorToSubstitutions=(doctorToAdd, lang)=>(dispatch)=> {
  trackEvent("substitution/create");
  dispatch(startLoading("substitution"));
  return post("substitution/create", {"substiution_doctor_email": doctorToAdd})
      .then((json) =>json.success?dispatch(displayToastSuccess("ADD_DOCTOR_SUCCESSFUL")):dispatch(displayToastError("ADD_DOCTOR_UNSUCCESSFUL")))
      .catch((error) => {
        if (error.status !== 400) {
          trackError(error);
        }
        dispatch(displayToastError("ADD_DOCTOR_UNSUCCESSFUL"));
      })
      .finally(()=> dispatch(endLoading("substitution")));
};

export const removeDoctorFromSubstitutions=(doctorToRemove, doctors, lang)=>(dispatch)=> {
  trackEvent("substitution/delete");
  dispatch(startLoading("substitution"));
  return post("substitution/delete", {"substiution_doctor_email": doctorToRemove, "direction": doctors ? 1 : 0})
      .then((json) =>
        dispatch(displayToastSuccess("DOCTOR_DELETED_SUCCESSFULLY")),

      ).catch((error) => {
        console.error(error);
        trackError(error);
        dispatch(displayToastError("DOCTOR_DELETED_UNSUCCESSFULLY"));
      })
      .finally(()=> dispatch(endLoading("substitution")));
};

import React from "react";
import {Checkbox, FormControlLabel, FormHelperText} from "@mui/material";
import {
  RadioButtonUnchecked,
  RadioButtonChecked,
} from "components/icons";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

export const CheckboxComponent=({input,
  label,
  touched,
  required,
  radio,
  key,
  disabled,
  meta: {error,
    visited}}) => {
  const theme = useTheme();
  const {t}=useTranslation();

  return <div>
    <FormControlLabel
      control={
      radio?(
      <Checkbox
        icon={<RadioButtonUnchecked />}
        checkedIcon={<RadioButtonChecked />}
        name={input.name}
        checked={input.value? true:false}
        onChange={input.onChange}
        disabled={disabled}
        color="primary"
        required={required}
        inputProps={{
          "key": {key},
          "data-cy": input.name,
        }}
      />
        ): <Checkbox
          style={ {color: error&&(touched||visited)?theme.palette.alert.main:"inherit"}}
          name={input.name}
          checked={input.value? true:false}
          onChange={input.onChange}
          color="primary"
          disabled={disabled}
          required={required}
          inputProps={{
            "key": {key},
            "data-cy": input.name,
          }}
        />}
      label={`${label}`}
    />
    <FormHelperText error>
      {error&&(touched||visited)? t(error):null}
    </FormHelperText>
  </div>;
};

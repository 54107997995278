import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const InsuranceAcceptDialog = ({
  open,
  handleClose,
  handleCancel,
  title,
  description,
  requested,
}) => {
  const {t}=useTranslation();
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>
        <Typography>{t("Requested")}: {requested}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t("insurance.dialog.action.cancel")}
        </Button>
        <Button onClick={handleClose} color="primary" data-cy={"sendToInsuranceConfirm"}>
          {t("insurance.dialog.action.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InsuranceAcceptDialog;


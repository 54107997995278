import React from "react";
import ErrorBoundary from "layout/ErrorBoundary";
import {DataGrid, deDE, frFR, GridToolbar} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";

export const AdminStatistics = (props) => {
  const {t, i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const statistics_domain_cols=[
    {
      field: "request_kw",
      headerName: "KW",
    },
    {
      field: "maildomain",
      headerName: "Maildomain",
      width: 200,
    },
    {
      field: "com_doc_insurance",
      headerName: "Insurance Mails",
      width: 200,
    },
    {
      field: "com_doc_pharma",
      headerName: "Pharma Mails",
      width: 200,
    },
    {
      field: "diagnoses",
      headerName: "Diagnoses",
    },
    {
      field: "requests",
      headerName: "Requests",
    },
    {
      field: "studies",
      headerName: "Studies",
    },
    {
      field: "type",
      headerName: "Type",
    },
  ];

  const statistics_comms_cols = [
    {
      field: "request_kw",
      headerName: "kw",
    },
    {
      field: "maildomain",
      headerName: "Maildomain",
      width: 200,
    },
    {
      field: "partner",
      headerName: "Partner",
      width: 400,
    },
    {
      field: "avg_time_div_in_minutes",
      headerName: "Time to answer AVG",
      width: 200,
    },
    {
      field: "max_time_div_in_minutes",
      headerName: "Time to answer MAX",
      width: 200,
    },
    {
      field: "count_conversations",
      headerName: "Conversations",
      width: 200,
    },
    {
      field: "count_messages",
      headerName: "Messages",
    },
    {
      field: "count_declined",
      headerName: "Declined",
    },
    {
      field: "count_approved",
      headerName: "Approved",
    },
  ];


  const statistics_pharma_cols = [
    {
      field: "request_kw",
      headerName: "kw",
    },
    {
      field: "PRTNO",
      headerName: "PHARMA PRTNO",

    },
    {
      field: "NAMS",
      headerName: "PHARMA",

    },
    {
      field: "ARTICLE_PRDNO",
      headerName: "ARTICLE_PRDNO",
    },
    {
      field: "REQUEST_TYPE",
      headerName: "REQUEST_TYPE",
    },
    {
      field: "ANONYM",
      headerName: "ANONYM",
      with: 200,
    },
    {
      field: "DRUG",
      headerName: "DRUG",
      width: 300,
    },
    {
      field: "DIAGNOSIS",
      headerName: "DIAGNOSIS",
      width: 400,
    },

  ];

  return (
    <ErrorBoundary >
      {
              (props.statistics_domains &&
                props.statistics_domains instanceof Array &&
                props.statistics_domains.length > 0) ? (
                <Grid item container xs={12} style={{display: "flex"}}>
                  <Box sx={{m: 2}}>
                    <Typography variant="h4" component="h4" align="left" sx={{color: "white.main"}}>
                      {t("home.header.statisticsdetails")}
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                <div />
              )
      }
      {
              (props.statistics_domains &&
                props.statistics_domains instanceof Array &&
                props.statistics_domains.length > 0) ? (
                  <Grid item xs={12}>
                    <Card>
                      <CardContent style={{minWidth: 100}}>
                        <DataGrid
                          style={{minWidth: 100}}
                          localeText={
                            currentLang==="DE"?deDE.components.MuiDataGrid.defaultProps.localeText:frFR.components.MuiDataGrid.defaultProps.localeText
                          }
                          components={{
                            Toolbar: GridToolbar,
                          }}
                          rows={props.statistics_domains}
                          columns={statistics_domain_cols}
                          autoHeight
                          pageSize={25}
                          rowsPerPageOptions={[25]}
                          disableVirtualization
                        />
                      </CardContent>
                    </Card>
                  </Grid>
              ) : (
                <div />
              )
      }
      <Box sx={{m: 2}} />
      {
              props.statistics_comms && props.statistics_comms instanceof Array && props.statistics_comms.length > 0 ? (
                  <Grid item xs={12}>
                    <Card>
                      <CardContent style={{minWidth: 100}}>
                        <DataGrid
                          style={{minWidth: 100}}
                          localeText={
                            currentLang==="DE"?deDE.components.MuiDataGrid.defaultProps.localeText:frFR.components.MuiDataGrid.defaultProps.localeText
                          }
                          components={{
                            Toolbar: GridToolbar,
                          }}
                          rows={props.statistics_comms}
                          columns={statistics_comms_cols}
                          autoHeight
                          pageSize={25}
                          disableVirtualization
                          rowsPerPageOptions={[25]}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
              ) : (
                <div />
              )
      }
      <Box sx={{m: 2}} />
      {
          props.statistics_pharma &&
          props.statistics_pharma instanceof Array &&
          props.statistics_pharma.length > 0 ? (
                  <Grid item xs={12}>
                    <Card>
                      <CardContent style={{minWidth: 100}}>
                        <DataGrid
                          style={{minWidth: 100}}
                          localeText={
                            currentLang==="DE"?deDE.components.MuiDataGrid.defaultProps.localeText:frFR.components.MuiDataGrid.defaultProps.localeText
                          }
                          components={{
                            Toolbar: GridToolbar,
                          }}
                          rows={props.statistics_pharma}
                          columns={statistics_pharma_cols}
                          autoHeight
                          pageSize={100}
                          disableVirtualization
                          rowsPerPageOptions={[100]}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
              ) : (
                <div />
              )
      }
    </ErrorBoundary>
  );
};

import {Paper, Drawer, Box, Button, Grid, IconButton} from "@mui/material";
import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import MedicineInfo from "../forms/med/AdditionalInformation/MedicineInfo";

export const WizardRightSidebar = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const isLargeScreen = useMediaQuery( "(min-width:2000px)");
  useEffect(() => {
    setIsOpen(isLargeScreen);
  }, [isLargeScreen]);

  return (
    <Box display={{xs: "block", md: "none"}}>
      <Button
        sx={{
          position: "fixed",
          top: "4em",
          width: "2em",
          padding: "0px",
          backgroundColor: "#e8f4ec",
          color: "#2f353a",
          right: 0,
        }}
        variant="contained"
        onClick={()=>setIsOpen(true)}>
        <FileOpenIcon/>
      </Button>
      <Drawer
        anchor={"right"}
        variant="persistent"
        open={isOpen}
        sx={{maxWidth: "50%"}}
        onClose={()=>setIsOpen(false)}
      >
        <Paper sx={{padding: "1em", backgroundColor: "#e8f4ec", minHeight: "100%", overflowX: "scroll"}} >
          <Grid container spacing={2}>
            <Grid item container justifyContent={"space-between"} alignItems="center">
              <IconButton onClick={()=>setIsOpen(false)} >
                <CloseIcon/>
              </IconButton>
            </Grid>
            <MedicineInfo></MedicineInfo>
          </Grid>

        </Paper>
      </Drawer>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WizardRightSidebar);

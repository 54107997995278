import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/rootstate";

export interface SomatulineState {
  formData: {
    variant: string
    default: boolean,
    tumor_control_gep_net_midgut_pancreas_unknown: boolean,
    grade_of_differentiation_g1: boolean,
    grade_of_differentiation_g2: boolean,
    ki67_index_up_to_10_percent: boolean,
    defaultForm: {
      preTreatment: string
      patientCondition: string,
      prognosis: string,
      reasonsAlternativeTherapiesFailed: string,
    },
  }
}

const initialState:SomatulineState = {
  formData: {
    variant: "",
    default: false,
    tumor_control_gep_net_midgut_pancreas_unknown: false,
    grade_of_differentiation_g1: false,
    grade_of_differentiation_g2: false,
    ki67_index_up_to_10_percent: false,
    defaultForm: {
      preTreatment: "",
      patientCondition: "",
      prognosis: "",
      reasonsAlternativeTherapiesFailed: "",
    },
  },
};

const SomatulineFormSlice = createSlice({
  name: "Somatuline",
  initialState,
  reducers: {
    onClearSomatulineState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedSomatulineEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state);
      if (payload.formData?.defaultForm) {
        state.formData.defaultForm.preTreatment =
          payload.formData?.defaultForm?.preTreatment;
        state.formData.defaultForm.patientCondition =
          payload.formData?.defaultForm?.patientCondition;
        state.formData.defaultForm.reasonsAlternativeTherapiesFailed =
          payload.formData?.defaultForm?.reasonsAlternativeTherapiesFailed;
        state.formData.defaultForm.prognosis =
          payload.formData?.defaultForm?.prognosis;
      }
    },
    onChangeSomatulineValue: (state, {payload}: PayloadAction<any>) => {
      if (payload.preTreatment || payload.preTreatment === "") state.formData.defaultForm.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.defaultForm.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.defaultForm.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.defaultForm.prognosis = payload.prognosis;
      updatestate(payload, state);
    },
  },
});


export const {
  onReceiveSpecialMedSomatulineEditData,
  onClearSomatulineState,
  onChangeSomatulineValue,
} = SomatulineFormSlice.actions;

export default SomatulineFormSlice.reducer;
export const selectSomatulineValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.somatulineForm?.formData;


function updatestate(payload: any, state) {
  if (payload.formData?.variant || payload.formData?.variant === "") {
    state.formData.variant = payload.formData?.variant;
  }
  if (payload.formData?.default === true) {
    state.formData.default = true;
    state.formData.tumor_control_gep_net_midgut_pancreas_unknown = false;
    state.formData.grade_of_differentiation_g1 = false;
    state.formData.grade_of_differentiation_g2 = false;
    state.formData.ki67_index_up_to_10_percent = false;
    state.formData.variant = "";
  }
  if (payload.formData?.default === false) {
    state.formData.default = false;
  }
  if (payload.formData?.tumor_control_gep_net_midgut_pancreas_unknown === true) {
    state.formData.tumor_control_gep_net_midgut_pancreas_unknown = true;
    state.formData.default = false;
  }
  if (payload.formData?.grade_of_differentiation_g1 === true) {
    state.formData.grade_of_differentiation_g1 = true;
    state.formData.grade_of_differentiation_g2 = false;
    state.formData.ki67_index_up_to_10_percent = false;
  }
  if (payload.formData?.grade_of_differentiation_g2 === true) {
    state.formData.grade_of_differentiation_g1 = false;
    state.formData.grade_of_differentiation_g2 = true;
    state.formData.ki67_index_up_to_10_percent = true;
  }
  if (payload.formData?.ki67_index_up_to_10_percent === true) {
    state.formData.ki67_index_up_to_10_percent = true;
  }
  if (payload.formData?.ki67_index_up_to_10_percent === false) {
    state.formData.ki67_index_up_to_10_percent = false;
  }
}

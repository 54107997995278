import React, {useEffect} from "react";
import {connect} from "react-redux";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputBaseComponentProps} from "@mui/material";
import {useTranslation} from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import {onChangeDiagnosisValue, selectDiagnosisValues} from "./diagnosisSlice";
import {selectMedicineValues} from "../medicine/medicineSlice";
import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {
  fetchAdditionalDrugInfo,
  fetchCompediumData,
  fetchICD10Data} from "store/middleware/masterdataThunk";
import {delay} from "components/formUtils";
import {onReceivedAdditionalDrugInformation, onReceivedCompendiumData, selectDiagnostics} from "store/features/masterdataSlice";
import {selectLoadingState} from "store/features/loadingSlice";
import {TextArea} from "pages/Wizard/components/TextAreaComponent";
import {useIsInViewport} from "pages/Wizard/containers/utils";
import {onViewChange, isFormVisible, getPreselectedStudy} from "pages/Wizard/wizardSlice";


export const DiagnosisForm = (props) => {
  const {t, i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();

  const diagnostics=UseAppSelector(selectDiagnostics);
  const isVisibleValues = UseAppSelector(isFormVisible);
  const medicineValues=UseAppSelector(selectMedicineValues);
  const loading=UseAppSelector(selectLoadingState);
  const diagnosisValues= UseAppSelector(selectDiagnosisValues);
  const dispatch = UseAppDispatch();
  const handleValueChange = (field:string, value:any)=>dispatch(onChangeDiagnosisValue({field, value}));
  const isVisible=useIsInViewport(props.refProps);

  useEffect(()=>{
    isVisibleValues.diagnosis!==isVisible&&dispatch(onViewChange({field: "diagnosis", value: isVisible}));
  }, [isVisible]);

  return (
    <Grid ref={props.refProps} container direction="column" spacing={3} id="diagnosis">
      <Grid item container direction="row" justifyContent="space-between" spacing={2}>
        <Grid item style={{"borderLeft": "7px solid #63c2de", "paddingTop": 0}}>
          <Typography variant="h5" >
            {t(props.label)}
          </Typography>
        </Grid>
        <Grid item xs={12} data-cy="diagnosis">
          <Autocomplete
            fullWidth
            autoHighlight={true}
            options={diagnostics??[]}
            value={diagnosisValues.diagnosis}
            freeSolo
            getOptionLabel={
              (option: any) =>
                option?.description ?
                `${option.code} ${option.description}` :
                option // thats the freetext part
            }
            onInputChange={(event, newInputValue) => {
              if (event?.type === "change" && newInputValue) {
                handleValueChange("diagnosis", newInputValue);
                if ( newInputValue.length >= 2) {
                  delay(()=> {
                    Promise.all([
                      dispatch(fetchICD10Data(newInputValue)),
                      dispatch(fetchAdditionalDrugInfo(
                          medicineValues.required_drug, newInputValue)),
                    ]);
                  }, 1000);
                }
              }
            }}
            onChange={(e, v, r, selecteddiagnosis: any) => {
              console.log("selecteddiagnosis", selecteddiagnosis);
              if (r==="clear") {
                handleValueChange("diagnosis", "");
                dispatch(onReceivedAdditionalDrugInformation({}));
                dispatch(onReceivedCompendiumData({}));
              }
              if (selecteddiagnosis) {
                if (selecteddiagnosis.option === undefined) {
                // nothing to do here
                  dispatch(onReceivedAdditionalDrugInformation({}));
                  dispatch(onReceivedCompendiumData({}));
                } else {
                  handleValueChange("diagnosis", selecteddiagnosis.option.description?(`${selecteddiagnosis.option.code} ${selecteddiagnosis.option.description}`):selecteddiagnosis.option);
                  dispatch(fetchCompediumData(selecteddiagnosis.option.description));
                }
                if (medicineValues.required_drug) {
                  dispatch(fetchAdditionalDrugInfo(
                      medicineValues.required_drug, selecteddiagnosis.option));
                  dispatch(getPreselectedStudy({}));
                }
              }
            }}
            renderInput={(params) => <TextField
              {...params}
              label={t("indication.details.diagnosis")}
              placeholder={t("indication.details.diagnosis")}
              InputProps={{...params.InputProps,
                readOnly: loading?.icd10,
                endAdornment: (
                  <React.Fragment>
                    {(loading?.icd10) ? <CircularProgress
                      color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                startAdornment: ( <InputAdornment position="start"> <SearchIcon />
                </InputAdornment> )}}
            />}
          />
        </Grid>
        <Grid item xs={12} data-cy="diagnosis_comments">
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="diagnosis_comments">{t("diagnosis.comments")} </InputLabel>

            <OutlinedInput
              label={t("diagnosis.comments")}
              value={diagnosisValues.remarks_diagnosis || ""}
              inputProps={{minRows: 3}}
              placeholder={t("diagnosis.comments")}
              style={{width: "100%"}}
              onChange={(event)=>handleValueChange("remarks_diagnosis", event.target.value)}
              id="diagnosis_comments"
              inputComponent={TextArea as React.ElementType<InputBaseComponentProps>}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="urgent"
                checked={diagnosisValues.urgent}
                onChange={(event)=>handleValueChange("urgent", event.target.checked)}/>
            }
            label={t("indication.details.urgent")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisForm);

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Chip,
  Stack,
  Box,
} from "@mui/material";

import {
  connect,
  useDispatch,
} from "react-redux";

import {
  deleteReminder,
} from "store/middleware/requestListThunk";

import {LocalizationProvider, DatePicker} from "@mui/x-date-pickers/";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {formatDate} from "components/formatting";
import {useTranslation} from "react-i18next";
import {de, fr} from "date-fns/esm/locale";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReminderDialog(props) {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const currentLang=i18n.language.toUpperCase();

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [dateValue, setDateValue] = React.useState(today);
  const [error, setError]=React.useState(false);

  const dialog = <div>
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{props.title}&nbsp;{props.requestid}</DialogTitle>
      <DialogContent>
        <Box sx={{m: 1}}>
          <DialogContentText id="alert-dialog-slide-description">
            {props.description}
          </DialogContentText>

          <LocalizationProvider dateAdapter={AdapterDateFns} locale={currentLang==="DE"?de:currentLang==="FR"?fr:de}>
            <DatePicker
              views={["year", "month", "day"]}
              renderInput={(params) =><TextField helperText={error?t("cannot.set.date.in.past"):""} {...params} error={error} variant="standard" />}
              onBlur={(e) => e.preventDefault()}
              onChange={(e) => {
                const dateToCompare=new Date();
                dateToCompare.setHours(0, 0, 0, 0);
              e instanceof Date?setError(e<dateToCompare):setError(false);
              setDateValue(formatDate(e));
              }}
              value={dateValue}
              minDate={new Date(today)}
              required
              inputFormat="dd/MM/yyyy"
              data-cy={"reminderDatePicker"}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{m: 1}}>
          <Stack direction="row" spacing={1}>
            {
              props.reminders?.map((row) =>
                <Chip
                  key={row.ID}
                  label={row.REMINDER_DATE}
                  onDelete = {()=> {
                    dispatch(deleteReminder(row.ID, row.REQUEST_ID));
                    props.handleCancel();
                  }
                  }
                  color="primary"
                />,
              )
            }
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick = {()=> props.handleClose(dateValue)}
          color = "primary"
          data-cy='reminderConfirm_ok'>
          {t("reminder.dialog.OK")}
        </Button>
        <Button
          onClick = {()=> props.handleCancel(dateValue)}
          color = "primary"
          data-cy='reminderConfirm_cancel'>
          {t("reminder.dialog.CANCEL")}
        </Button>
      </DialogActions>
    </Dialog>
  </div>;
  return dialog;
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
};
export default (connect(mapStateToProps, mapDispatchToProps)(ReminderDialog));

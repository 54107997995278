/* eslint-disable no-tabs */
import React from "react";
import {
  Backdrop,
} from "@mui/material";
import mobilelogo from "assets/img/brand/logo_mobile_compress.png";

export default function SimpleBackdrop(props) {
  let openme = false;
  if (props.open === true) {
    openme = true;
  }
  return (
    <div>
      <Backdrop
        open={openme}
        sx={
          {
            "zIndex": "1",
            "color": "#63c2de",
          }
        }
      >
        <img
          src={mobilelogo} className="rotatelogo"
        ></img>


      </Backdrop>
    </div>
  );
}

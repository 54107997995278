import React from "react";
import {enUS, de, fr} from "date-fns/esm/locale";
import {LocalizationProvider, DesktopDatePicker} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {TextField} from "@mui/material";
import i18n from "i18n";
import {useTranslation} from "react-i18next";

export const Calendar=({
  input,
  id,
  maxDate,
  minDate,
  label,
  touched,
  language,
  fullWidth,
  views,
  required,
  inputFormat,
  meta: {error,
    visited},
}) => {
  const {t}=useTranslation();
  if (input.value === undefined || input.value === "") {
    input.value = null;
  }

  let hasError = false;
  let errorMessage=error;


  if (error&&(touched||visited) ) {
    hasError = true;
  } else {
    hasError = false;
  }

  if (input.value instanceof Date) {
    if (!error&&(input.value>maxDate ||input.value<minDate)&&(touched||visited)) {
      hasError=true;
      errorMessage=i18n.t("date.out.of.range");
    } else {
      errorMessage=t(error);
    }
  }

  return <LocalizationProvider dateAdapter={AdapterDateFns} locale={language==="DE"?de:language==="FR"?fr:enUS} >
    <DesktopDatePicker
      {...input}
      onChange={(event)=>event&&input.onChange(event)}
      openTo="year"
      inputProps={{"data-cy": input.name, "onFocus": input.onFocus}}
      views={views??["year", "month", "day"]}
      inputFormat={inputFormat??"dd/MM/yyyy"}
      minDate={minDate?new Date(minDate):null}
      maxDate={maxDate?new Date(maxDate):null}
      id={id}
      fullWidth={fullWidth}
      value={input.value}
      renderInput={(params) =>
        <TextField
          helperText={hasError?errorMessage:""}
          fullWidth={fullWidth}
          {...params}
          error={hasError}
          required={required}
          variant="standard"
        />}
      label={label}
    >
    </DesktopDatePicker>
  </LocalizationProvider>;
};



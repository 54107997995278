import React, {useEffect} from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import TextField from "@mui/material/TextField";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  useLocation,
} from "react-router-dom";
import {
  Grid,
  Box,
  FormControlLabel,
  Typography,
} from "@mui/material";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
} from "../../../medicine/medicineSlice";

import {UseAppDispatch, UseAppSelector} from "store/hooks";
import {onChangeSpecialMedValue, selectSpecialAdditionalInformation, SpecialMedAdditionalInformation} from "../../specialMedSlice";
import {
  getPreselectedStudy,
} from "pages/Wizard/wizardSlice";
import {
  onChangeTreatmentValue,
} from "../../../Treatment/treatmentPlanSlice";
import {
  onChangeEnhertuValue,
  selectEnhertuValues,
} from "./EnhertuSlice";
const EnhertuForm = (props) => {
  const EnhertuValues = UseAppSelector(selectEnhertuValues);
  const {t}=useTranslation();
  const special_med_additional_information=UseAppSelector(selectSpecialAdditionalInformation);
  const dispatch = UseAppDispatch();
  const params=useLocation();
  const handleMedValueChange = (field:string, value:any)=>dispatch(onChangeSpecialMedValue({field, value}));
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  useEffect(() => {
    const regex = /\d+$/;
    const isEditId = regex.test(params.pathname);
    console.debug("EnhertuForm isEditId", isEditId);
    if (isEditId) {
      if (special_med_additional_information === 2) {
        setHerToPos();
      }
      if (special_med_additional_information === 1) {
        setHerToLow();
      }
      if (special_med_additional_information === 0) {
        setHerToOther();
      }
    }
  }, []);
  useEffect(()=>{
    dispatch(getPreselectedStudy({}));
    console.debug("EnhertuForm special_med_additional_information", special_med_additional_information);
  }, [special_med_additional_information]);


  const setHerToPos = () => {
    handleMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.ENHERTU_HER_POS);
    dispatch(onChangeEnhertuValue({formData: {her2pos: true}}));
    dispatch(onChangeEnhertuValue({formData: {her2low: false}}));
    dispatch(onChangeEnhertuValue({formData: {other: false}}));
    dispatch(onChangeEnhertuValue({patientCondition: ""}));
    dispatch(onChangeEnhertuValue({preTreatment: ""}));
    dispatch(onChangeEnhertuValue({prognosis: ""}));
    dispatch(onChangeEnhertuValue({reasonsAlternativeTherapiesFailed: ""}));
  };
  const setHerToLow = () => {
    handleMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.ENHERTU_HER_LOW);
    dispatch(onChangeEnhertuValue({formData: {her2low: true}}));
    dispatch(onChangeEnhertuValue({formData: {her2pos: false}}));
    dispatch(onChangeEnhertuValue({formData: {other: false}}));
    dispatch(onChangeEnhertuValue({formData: {trastuzumabEmtansin: false}}));
    dispatch(onChangeEnhertuValue({formData: {trastuzumabTaxan: false}}));
    dispatch(onChangeMedicineValue({field: "kn_id", value: "21338.03"}));
    dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
    handleValueChangeKVV("limitatio_approval", true);
    handleValueChangeKVV("specialist_info", true);
    dispatch(onChangeEnhertuValue({patientCondition: ""}));
    dispatch(onChangeEnhertuValue({preTreatment: ""}));
    dispatch(onChangeEnhertuValue({prognosis: ""}));
    dispatch(onChangeEnhertuValue({reasonsAlternativeTherapiesFailed: ""}));
  };
  const setHerToOther = () => {
    handleMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);
    dispatch(onChangeEnhertuValue({formData: {her2low: false}}));
    dispatch(onChangeEnhertuValue({formData: {her2pos: false}}));
    dispatch(onChangeEnhertuValue({formData: {other: true}}));
    dispatch(onChangeEnhertuValue({formData: {trastuzumabEmtansin: false}}));
    dispatch(onChangeEnhertuValue({formData: {trastuzumabTaxan: false}}));
    dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
    dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: false}));
    handleValueChangeKVV("limitatio_approval", false);
    handleValueChangeKVV("specialist_info", false);
  };

  return <>
    {
      console.debug("EnhertuForm loaded")


    }
    <Grid item xs={12}>
      <FormControl>
        <FormLabel>{t("enhertu.label.her2status")}</FormLabel>
        <RadioGroup
          aria-labelledby="trastuzumab-radio-buttons-group-her2"
          name="trastuzumab-radio-buttons-group-her2"
          onChange={(event, value) => {
            if (value === "her2pos") {
              setHerToPos();
            } else if (value === "her2low") {
              setHerToLow();
            } else if (value === "other") {
              setHerToOther();
            }
          }}>
          <FormControlLabel
            value="her2pos"
            control={<Radio checked={EnhertuValues.her2pos} data-cy="chk_enhertu" />}
            label={t("enhertu.details.pos")}
          />
          <FormControlLabel
            value="her2low"
            control={<Radio checked={EnhertuValues.her2low } data-cy="chk_enhertu_low" />}
            label={t("enhertu.details.low")}
          />
          <FormControlLabel
            value="other"
            control={<Radio checked={EnhertuValues.other } data-cy="chk_enhertu_none" />}
            label={t("enhertu.details.other")}
          />
        </RadioGroup>
      </FormControl>

      <FormControl
        hidden={!(EnhertuValues.her2pos )}
      >
        <FormLabel>{t("enhertu.label.regime")}</FormLabel>
        <RadioGroup
          aria-labelledby="trastuzumab-radio-buttons-group-form-type"
          name="trastuzumab-radio-buttons-group-form-type"
          onChange={(event, value) => {
            if (value === "trastuzumabTaxan") {
              dispatch(onChangeEnhertuValue({formData: {trastuzumabTaxan: true}}));
              dispatch(onChangeEnhertuValue({formData: {trastuzumabEmtansin: false}}));
              dispatch(onChangeMedicineValue({field: "kn_id", value: "21338.01"}));
              dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
              handleValueChangeKVV("limitatio_approval", true);
              handleValueChangeKVV("specialist_info", true);
            } else if (value === "trastuzumabEmtansin") {
              dispatch(onChangeEnhertuValue({formData: {trastuzumabEmtansin: true}}));
              dispatch(onChangeEnhertuValue({formData: {trastuzumabTaxan: false}}));
              dispatch(onChangeMedicineValue({field: "kn_id", value: "21338.02"}));
              dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
              handleValueChangeKVV("limitatio_approval", true);
              handleValueChangeKVV("specialist_info", true);
            }
          }}>
          <FormControlLabel
            value="trastuzumabTaxan"
            data-cy="chk_trastuzumabTaxan"
            control={<Radio checked={EnhertuValues.trastuzumabTaxan } />}
            label={t("enhertu.details.trastuzumabTaxan")}
          />
          <FormControlLabel
            value="trastuzumabEmtansin"
            data-cy="chk_trastuzumabEmtansin"
            control={<Radio checked={EnhertuValues.trastuzumabEmtansin } />}
            label={t("enhertu.details.trastuzumabEmtansin")}
          />
        </RadioGroup>
      </FormControl>

      <div hidden={!(
        EnhertuValues.her2pos &&
        (
          EnhertuValues.trastuzumabEmtansin === false &&
          EnhertuValues.trastuzumabTaxan === false
        )
      )

      }>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="left"
            gap={1}
          >
            <WarningIcon color={"warning"}></WarningIcon>
            <Typography>{t("enhertu.warning.regime")}</Typography>
          </Stack>
        </Grid>
      </div>


      <div hidden={!(EnhertuValues.other)}>
        <Box sx={{m: 1}} />
        <Typography >{t("enhertu.details.text")}</Typography>
        <Box sx={{m: 1}} />
        <TextField
          style={{width: "100%"}}
          InputLabelProps={{shrink: true}}
          data-cy="preTreatment"
          label={t("enhertu.defaultForm.preTreatment")}
          value={EnhertuValues.defaultForm?.preTreatment}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeEnhertuValue(
                {
                  preTreatment: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          multiline
          style={{width: "100%"}}
          label={t("enhertu.defaultForm.patientCondition")}
          InputLabelProps={{shrink: true}}
          value={EnhertuValues.defaultForm?.patientCondition }
          variant="outlined"
          maxRows={4}
          minRows={4}
          onChange = {(event ) =>
            dispatch(onChangeEnhertuValue(
                {
                  patientCondition: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          multiline
          maxRows={4}
          minRows={4}
          label={t("enhertu.defaultForm.prognosis")}
          InputLabelProps={{shrink: true}}
          value={EnhertuValues.defaultForm?.prognosis }
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeEnhertuValue(
                {
                  prognosis: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          label={t("enhertu.defaultForm.reasonsAlternativeTherapiesFailed")}
          InputLabelProps={{shrink: true}}
          value={EnhertuValues.defaultForm?.reasonsAlternativeTherapiesFailed }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeEnhertuValue(
                {
                  reasonsAlternativeTherapiesFailed: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
      </div>


    </Grid>
  </>;
};

const mapStateToProps=(state) => {
  return {

  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EnhertuForm);


